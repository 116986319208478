val regionNaselje = Region(
    "6_naselje",

    Location("0600_sredisce", null, "Središče naselja", { state ->
        """<p>Pa smo le prišli v Bobrnik. Vas ni čisto ob reki, gotovo pa ti
        bo lahko kdo pomagal priti do nje.</p>

        <p>Z Alenko se razgledata naokrog in okolica ni posebej vabljiva. Med nizkimi
        hišami se pasejo kokoši, vse je precej umazano in blatno.</p>""" +
        if (FlagIsSet("vprasal_gospo_0605").verify(state))
        "<p>Alenka pridno klepeta z domačinkami in občutek imaš, da pozna že čisto vse v vasi.</p>"
        else
        """<p>Alenka se odloči, da bo ostala kar tu in klepetala z domačinkami.
        ${ if (FlagNotSet("gave_chocolate").verify(state)) 
            " Pravi le, da bi se ji prileglo kaj za pod zob. Mogoče kaj sladkega." else "" }
        </p>"""},

        "JZ" to "0601_blatna_pot",
        "V" to "0612_trgovina_s_cokolado",
        "S" to "0623_naselje_malih_koc",

        commands = Commands(
            Command("Daj Alenki čokolado",
                    showOn=HasItem("čokolada"),
                    actions=Actions(
                        Message("Z Alenko pojesta čokolado. Res se je prilegla."),
                        SetFlag("gave_chocolate"),
                        DropItem("čokolada")
                    )
            ),

            Command("Posvetuj se z Alenko",
                showOn=FlagIsSet("vprasal_gospo_0604"),
                condition=FlagIsSet(
                    "gave_chocolate",
                    "Alenka pravi, da bi se ji prileglo kaj za pod zob. Mogoče kaj sladkega."),
                actions=Actions(
                    SetFlag("seen_gossiping_network"),
                    Message("""
                <img src="assets/0600_gossip-network.png" style="float: right"/>
    
                <p>Alenka ti pove tole: "<em>Kdo bi te peljal ob reki, ne vem. Vem
                pa, da te dni nekega dekleta ni v vasi. Takole: tale vas je eno samo
                veliko gnezdo opravljivk. Naskicirala sem si sliko: tule vidiš, kdo
                poroča komu vse, kar izve.</p>
    
                <p><em>Štefka, recimo, vse izčveka Miri, Petri in Alenki. Tadeja o
                vseh govoricah obvešča Barbaro in Miro.</em><p>
    
                <p><em>Cilka je prinesla govorico, da se je Sara skregala s
                Petrom. Ugotovila sem, da Helena in Petra to vesta, do Štefke in
                Jane, pa ta govorica ni prišla. Vidiš, po tem sklepam, da je eno od
                deklet odsotno, zato se govorice ne širijo, kot bi se morale.
                </em></p>
    
                <p><em>Če hočeš, lahko torej ugotoviš, koga ni v vasi - če ti to
                morda kaj pomaga.</em>"</p>""")
                )
            )
        )
    ),

    Location("0601_blatna_pot", null, "Blatna pot",
        "Kakšno čudno naselje: dve ozki ulici, okrog njih pa samo blato.",

        "JZ" to "0603_gozd",
        "J" to "0602_travnik",
        "SV" to "0600_sredisce"
    ),

    Location("0602_travnik", null, "Travnik", """
        Tule je že malo lepše: iz blatne poti si prišel do širnih
        travnikov. Na jugu je mala njivica, na kateri je videti bobre. Stopi do njih,
        morda ti lahko pomagajo.""",

        "S" to "0601_blatna_pot",
        "JZ" to "0603_gozd",
        "J" to "0604_njiva",
        "JV" to "0605_gozd"
    ),

    Location("0603_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "V" to "0604_njiva",
        "SV" to "0602_travnik",
        "S" to "0601_blatna_pot",
        "JV" to "0606_gozd",
        "JZ" to "0603_gozd",
        "Z" to "0603_gozd",
        "SZ" to "0603_gozd"
    ),

    Location("0604_njiva", null, "Njiva", """
        Na njivi je stara gospa. Z malo motiko okopava gredico
        paradižnika. Obnaša se, kot da te ne vidi in ne sliši. Morda je pa res malo
        naglušna. Vseeno jo lahko poskusiš vprašati za nasvet.""",

        "S" to "0602_travnik",
        "Z" to "0603_gozd",
        "V" to "0605_gozd",
        "J" to "0606_gozd",

        commands = Commands(
            Command("Vprašaj za nasvet",
                actions=Actions(
                    Message("""
                        &mdash; <em>Gospa, k reki bi moral. Kako pridem do nje?</em><br/>
                        Gospa te ne sliši, zato ponoviš glasneje.<br/>
                        &mdash; <em>K reki bi moral. Mi lahko pomagate?</em>
                        <p>Gospa se zdrzne in začne razlagati o nekem gospodu, ki včasih pelje
                        koga do reke. Njegovega imena žal nisi razumel, saj gospa govori
                        preveč nerazločno. Ujel pa si, da gre za strica neke bobrovke, ki
                        pa je te dni ni v vasi.</p>
                        <p>Gospo ponovno vprašaš, kako je ime bobru, ki te lahko pelje do reke,
                        vendar gospa preslabo sliši in misli, da jo sprašuješ, kako kaj kaj
                        raste zelje. Navdušeno ti pove vse o njem, ti pa se vljudno
                        zahvališ in odideš.</p>
                        """),
                     SetFlag("vprasal_gospo_0604"),
                     MoveTo("0602_travnik")
                )
            )
        )
    ),

    Location("0605_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "SZ" to "0602_travnik",
        "Z" to "0604_njiva",
        "JZ" to "0606_gozd",
        "J" to "0607_gozd"
    ),

    Location("0606_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "SZ" to "0603_gozd",
        "S" to "0604_njiva",
        "SV" to "0605_gozd"
    ),

    Location("0607_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "J" to "0606_gozd",
        "S" to "0605_gozd",
        "SZ" to "0604_njiva",
        "V" to "0608_gozd"
    ),

    Location("0608_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "S" to "0605_gozd",
        "J" to "0609_gozd",
        "V" to "0605_gozd",
        "Z" to "0607_gozd"
    ),

    Location("0609_gozd", null, "Gozd", { state ->
        "<p>Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!</p>" +
                if (FlagIsSet("took_stick").verify(state)) "" else
        "<p>Na tleh leži sočna leskova palica. Prava bobrovska poslastica!</p>"},

        "Z" to "0606_gozd",

        commands = Commands(
            Command("Vzemi palico",
                showOn=FlagNotSet("took_stick"),
                actions=Actions(
                    GetItem("leskova palica"),
                    SetFlag("took_stick"),
                    Message("Kako okusna palica!")
                )
            )
        )
    ),

    Location("0610_pogorisce", null, "Pogorišče", { state ->
        if (HasSolved("T12_FR_06_trgovanje").verify(state))
        "<p>Na nizki vzpetini stoji lepa hišica, pred katero sedi hvaležni bobrček Vili.</p>"
        else
        """<p>Na nizki vzpetini je bila nekoč mala hišica, od katere je
        ostalo le žalostno pogorišče.</p>
        <p>Bobrček Vili je izgubil vse, kar ima, razen male
        rdeče zaponke in cekina. Okrog njega se je nabralo veliko bobrov, ki so
        pripravljeni zamenjevati različne stvari - a kaj, ko mu ne bo nihče dal
        hiše za zaponko!</p>"""},

        "S" to "0611_klanec",

        commands = Commands(
            StartTask("Pomagaj Viliju", "T12_FR_06_trgovanje",
                onSuccessActions = Actions(
                    GetItem("cekin"),
                    Message("Hvaležni Vili ti da cekin.")
                ),
                onFailureActions = Actions(
                    Message("Potrudi se no še malo, saj bo šlo!")
                )
            )
        )
    ),

    Location("0611_klanec", null, "Klanec", { state ->
        "<p>Položen klanec vodi na vzpetino nad vasjo."
        if (HasSolved("T12_FR_06_trgovanje").verify(state)) ""
        else " Od nekod se sliši tiho hlipanje."},

        "J" to "0610_pogorisce",
        "S" to "0612_trgovina_s_cokolado"
    ),

    Location("0612_trgovina_s_cokolado", null, "Trgovina s čokolado",
        "Pred teboj je nekaj imenitnega: trgovina s čokolado!",

        "J" to "0611_klanec",
        "V" to "0613_ulica",
        "Z" to "0600_sredisce",
        "Vstopi" to "0625_v_trgovini"
    ),

    Location("0613_ulica", null, "Ulica",
        "Ozka ulica vodi ven iz vasi. Ali pa vanjo - odvisno od tega, v katero stran greš.",

        "V" to "0614_blatna_ulica",
        "JV" to "0616_gostilna",
        "S" to "0618_tolmun",
        "Z" to "0612_trgovina_s_cokolado"
    ),

    Location("0614_blatna_ulica", null, "Blatna ulica",
        "Ah, kakšna vas. Čim stopiš malo ven iz nje, si že v blatu.",

        "Z" to "0613_ulica",
        "S" to "0618_tolmun",
        "V" to "0616_gostilna",
        "JV" to "0615_blatna_ulica"
    ),

    Location("0615_blatna_ulica", null, "Blatna ulica",
        "Ah, kakšna vas. Čim stopiš malo ven iz nje, si že v blatu.",

        "SZ" to "0614_blatna_ulica",
        "S" to "0616_gostilna"
    ),

    Location("0616_gostilna", null, "Gostilna", """
        Stojiš pred sumljivo vaško gostilno, v katero hodijo sami
        sumljivi tipi. Vzdušje je neprijetno, raje odidi.""",

        "S" to "0617_gozd",
        "J" to "0615_blatna_ulica",
        "Z" to "0618_tolmun",

        commands = Commands(
            Command("Vstopi",
                action=Message("Za potikanje po gostilnah si pa še premlad, poba!")
            )
        )
    ),

    Location("0617_gozd", null, "Gozd",
        "Se še nisi naveličal gozdov? Le brž ven, sicer se boš še izgubil!",

        "J" to "0616_gostilna",
        "Z" to "0618_tolmun"
    ),

    Location("0618_tolmun", null, "Tolmun", """
        Nedaleč za cesto je majhen tolmun. Potoček, ki teče skozenj,
        se hitro izgublja med skalami.""",

        "JV" to "0616_gostilna",
        "V" to "0617_gozd"
    ),

    Location("0619_soba", null, "Soba", """
        V sobi brli petrolejka. Za mizo sedi zaraščen siv bober, ki
        škreblja po papirju, vendar ga takoj, ko te zagleda, potisne v predal.""",

        "Ven" to "0620_koca",

        commands = Commands(
            Command("Daj bobru leskovo palico",
                showOn=HasItem("leskova palica"),
                actions=Actions(
                    DropItem("leskova palica"),
                    SetFlag("dal leskovo palico")
                )
            ),

            Command("Prosi za nasvet",
                condition=FlagIsSet("dal leskovo palico",
                    "Bober ti je pripravljen pomagati, če mu daš kaj sočnega za pod zob."),
                actions = Actions(
                    SetFlag("started_tx_xx_02"),
                    ShowTask("TXX_XX_02_rodbinsko_drevo", Actions(), Actions())
                )
            )
         )
    ),

    Location("0620_koca", null, "Koča", """
        Kot vse v tej vasi, je tudi koča, pred katero si se ustavil,
        videti borna. Stara bruna bi bila že davno potrebna zamenjave, pa tudi streha
        najbrž ne bo več zdržala naslednje zime. Vendar se nimaš časa ukvarjati s tem,
        saj je pred tabo druga naloga.""",

        "Z" to "0623_naselje_malih_koc",
        "Vstopi" to "0619_soba"
    ),

    Location("0621_soba", null, "Soba", """
        Na staro mizo je naslonjen polomljen stol. Vse je zaprašeno.
        Tu že dolgo ni nihče živel.""",

        "Ven" to "0622_koca"
    ),

    Location("0622_koca", null, "Koča", """
        Koča na vasi je v res slabem stanju. Vsak čas se bo podrla.
        Ne rini vanjo, če nisi prepričan, da imaš kaj iskati v njej.""",

        "JZ" to "0623_naselje_malih_koc",
        "Vstopi" to "0621_soba"
    ),

    Location("0623_naselje_malih_koc", null, "Naselje malih koč", """
        Na robu vasi je preživlja svoje zadnje dneve par malih koč. Vse
        je videti žalostno, le na severu se nekaj dogaja.""",

        "J" to "0600_sredisce",
        "V" to "0620_koca",
        "SV" to "0622_koca",
        "S" to "0624_gradbisce"
    ),

    Location("0624_gradbisce", null, "Gradbišče", """
        Vendarle nekaj življenja tudi v tej vasi! Pred tabo se je
        odprlo živahno gradbišče. Bobri s čeladami hitijo sem ter tja, glodajo hlode,
        kopljejo gradbene jame, postavljajo odre, prve hiše pa že pokrivajo s strehami.
        Morda je med temi bobri kateri, ki te bo peljal do reke?""",

        "J" to "0623_naselje_malih_koc",

        commands = Commands(
            StartTask("Izberi vodiča", "TXX_XX_03_izberi_vodica",
                /* These conditions still allow the player to guess without solving the
                   the tasks about gossiping and finding the uncle. Yet, it forces her
                   to solve all other tasks (and to at least see that there is something
                   to solve for choosing the guide, too ;) */
                conditions=Conditions(
                    FlagIsSet("started_tx_xx_02",
                              "Če boš izbiral vodiča na slepo, ne bo dobro."),
                    FlagIsSet("seen_gossiping_network",
                              "Če boš izbiral vodiča na slepo, ne bo dobro.")
                ),
                onSuccessActions = Actions(
                    Message("Možakar te je pripravljen peljati, če ga premagaš v preprosti igri."),
                    SetFlag("picked_guide", 1),
                    SetFlag("won_game", 0)
                ),
                onFailureActions = Actions(
                    Message("Možakar te je pripravljen peljati, če ga premagaš v preprosti igri."),
                    SetFlag("picked_guide", 6),
                    SetFlag("won_game", 0))
            ),

            StartTask("Igraj igro", "T10_XX_17_strategija",
                showOn=FlagIsSet("picked_guide"),
                onSuccessActions = Actions(
                     Message("Uspelo ti je. Zdaj pa le za njim!"),
                     SetFlag("won_game", 1)),
                onFailureActions = Actions(
                     Message("Še malo se boš moral izuriti v igri. Na srečo res ni videti težka"),
                     SetFlag("won_game", 0))),

            Command("Sledi možakarju",
                showOn=And(FlagIsSet("won_game"), FlagEquals("picked_guide", 1)),
                actions = Actions(
                    SetFlag("won_game", 0),
                    SetFlag("picked_guide", 0),
                    MoveTo("0700_jez"),
                    Message("""Z vodičem počakata še Alenko, natu pa vaju vodi 
                               skozi nepregleden gozd (sama res ne bi našla poti 
                               skozenj!) do reke.""")
                )
            ),

            Command("Sledi možakarju",
                showOn=And(FlagIsSet("won_game"), Not(FlagEquals("picked_guide", 1))),
                actions = Actions(
                    SetFlag("won_game", 0),
                    SetFlag("picked_guide", 0),
                    MoveTo("0626_v_gostilni"),
                    Message("""Ah, glej ga, stara mrha ni šla proti reki temveč 
                               naravnost v gostilno. Si gotovo izbral pravega vodiča?""")
                )
            )
        )
    ),

    Location("0625_v_trgovini", null, "Trgovina s čokolao", { state ->
        "Kakšne imenitne čokolade imajo v tej trgovini." +
        if (HasSolved("T09_XX_04_kvadratne_cokolade").verify(state))
            " A ti si jo že kupil in, kolikor vem, nimaš denarja za še eno. Drži?"
        else
            "Alenka bi je bila gotovo vesela. Ji jo boš kupil?"},

        "Ven" to "0612_trgovina_s_cokolado",

        commands = Commands(
            StartTask("Kupi čokolado", "T09_XX_04_kvadratne_cokolade",
                conditions = Conditions(HasItem("cekin", "Misliš, da je čokolada zastonj?")),
                onSuccessActions = Actions(
                    GetItem("čokolada"),
                    DropItem("cekin"),
                    Message("Zdaj pa le hitro k Alenki!")
                ),
                onFailureActions = Actions(
                    Message("Iz teh koščkov se ne da sestaviti čokolade.")
                )
            )
        )
    ),

    Location("0626_v_gostilni", null, "Gostilna", """
        <p>Tip, ki si si ga izbral, te ni peljal do reke, temveč je
        šel pit v najbližjo gostilno. Najbrž si izbral napačnega.</p>
        <p>V tej smrdljivi luknji nimaš kaj iskati. Le hitro nazaj v vas.</p>""",

        "Ven" to "0616_gostilna"
    ),

    hasMap = false
)

