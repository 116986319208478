import kotlinx.html.js.onClickFunction
import react.*
import react.dom.svg

val directions = "Z V J S SV SZ JV JZ".split(' ').toSet()

fun RBuilder.compass(commands: List<Command>, enqueueCommand: ((Command) -> Unit)) {
    if (!commands.any { it.description in directions })
        return

    svg(classes="compass") {
        attrs {
            setProp("width", 180)
            setProp("height", 180)
        }

        circle(cx = 84.018, cy = 84.333, r = 81.2, classes = "outercirc")

        commands.forEach { command ->
            when (command.description) {
                "S" -> {
                    circle(cx = 84.018, cy = 25.333, r = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 79.6089 30.835)", classes = "text") { +"S" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 84.018, y2 = 52.102, classes = "line")
                    polygon(points = "89.426,57.013 84.003,52.753 78.418,57.013 83.924,44", classes = "st4")
                    polygon(points = "88.385,55.663 84.003,45.458 84.003,52.102", classes = "st3")
                    polygon(points = "79.622,55.663 84.005,45.458 84.005,52.102", classes = "st5")
                }

                "JZ" -> {
                    ellipse(cx = 42.298, cy = 126.052, rx = 16.873, ry = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 34.2236 131.835)", classes = "text") { +"JZ" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 61.227, y2 = 107.123, classes = "line")
                    polygon(points = "60.875,99.827 61.699,106.673 68.66,107.61 55.564,112.919", classes = "st4")
                    polygon(points = "60.658,101.517 56.54,111.832 61.238,107.134", classes = "st3")
                    polygon(points = "66.854,107.714 56.538,111.831 61.237,107.132", classes = "st5")
                }

                "JV" -> {
                    ellipse(cx = 125.737, cy = 126.053, rx = 16.873, ry = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 117.9673 131.835)", classes = "text") { +"JV" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 106.808, y2 = 107.124, classes = "line")
                    polygon(points = "99.512,107.476 106.358,106.653 107.295,99.692 112.604,112.787", classes = "st4")
                    polygon(points = "101.203,107.693 111.517,111.812 106.819,107.113", classes = "st3")
                    polygon(points = "107.399,101.497 111.516,111.813 106.817,107.115", classes = "st5")
                }

                "SV" -> {
                    ellipse(cx = 125.738, cy = 42.613, rx = 16.873, ry = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 116.2622 48.335)", classes = "text") { +"SV" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 106.808, y2 = 61.543, classes = "line")
                    polygon(points = "107.16,68.839 106.337,61.993 99.376,61.056 112.471,55.747", classes = "st4")
                    polygon(points = "107.378,67.149 111.497,56.834 106.798,61.533", classes = "st3")
                    polygon(points = "101.181,60.952 111.498,56.835 106.799,61.534", classes = "st5")
                }

                "Z" -> {
                    circle(cx = 25.018, cy = 84.333, r = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 19.4155 90.335)", classes = "text") { +"Z" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 51.787, y2 = 84.333, classes = "line")
                    polygon(points = "56.698,78.925 52.438,84.348 56.698,89.933 43.685,84.427", classes = "st4")
                    polygon(points = "55.348,79.966 45.143,84.348 51.787,84.348", classes = "st3")
                    polygon(points = "55.348,88.729 45.143,84.346 51.787,84.346", classes = "st5")
                }

                "J" -> {
                    circle(cx = 84.019, cy = 143.333, r = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    line(x1 = 84.018, y1 = 84.333, x2 = 84.018, y2 = 116.564, classes = "line")
                    text(transform = "matrix(1 0 0 1 81.7358 148.3345)", classes = "text") { +"J" }
                    polygon(points = "78.61,111.653 84.033,115.913 89.618,111.653 84.112,124.667", classes = "st4")
                    polygon(points = "79.651,113.003 84.033,123.208 84.033,116.564", classes = "st3")
                    polygon(points = "88.415,113.003 84.031,123.208 84.031,116.564", classes = "st5")
                }

                "V" -> {
                    circle(cx = 143.018, cy = 84.333, r = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 136.9517 90.335)", classes = "text") { +"V" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 116.25, y2 = 84.333, classes = "line")
                    polygon(points = "111.338,89.741 115.598,84.318 111.338,78.733 124.352,84.239", classes = "st4")
                    polygon(points = "112.688,88.7 122.894,84.318 116.25,84.318", classes = "st3")
                    polygon(points = "112.688,79.937 122.894,84.32 116.25,84.32", classes = "st5")
                }

                "SZ" -> {
                    ellipse(cx = 42.298, cy = 42.614, rx = 16.872, ry = 14.544, classes = "buttoncirc") {
                        attrs { onClickFunction = { enqueueCommand(command) } }
                    }
                    text(transform = "matrix(1 0 0 1 32.5195 48.335)", classes = "text") { +"SZ" }
                    line(x1 = 84.018, y1 = 84.333, x2 = 61.228, y2 = 61.542, classes = "line")
                    polygon(points = "68.524,61.19 61.678,62.013 60.741,68.974 55.432,55.879", classes = "st4")
                    polygon(points = "66.833,60.973 56.519,56.854 61.217,61.553", classes = "st3")
                    polygon(points = "60.637,67.169 56.521,56.853 61.219,61.551", classes = "st5")
                }
            }

            circle(cx = 84.018, cy = 84.333, r = 5.047, classes = "center-circle")
        }
    }
}
