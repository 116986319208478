val T13_SI_06_pretakanje = Task("T13_SI_06_pretakanje", "Pretakanje vode",

description = """<script src="/static/ext/jquery.svg/jquery.svganim.js"></script>
<style>
    #buttons a {
        margin: 8px;
    }
</style>

<p>Bober ob potoku ima dva vrča in enega ti z veseljem posodi, čim popije
natančno 4 decilitre vode. Problem: ne zna jih nameriti, saj gre v večji vrč
8 decilitrov in v manjšega 5. Pomagaj mu, pa dobiš kar želiš.</p>

<div id="jugs" class="center-block" style="width: 458px; height: 249px"></div>

<div id="buttons" class="center-block"  style="margin-top:5px;">
    <table style="width: 100%">
        <tr>
            <td style="text-align: right">
                <button id="rtoj5" class="btn btn-default">iz reke v mali vrč</button>&nbsp;
            </td>
            <td style="text-align: left">
                <button id="rtoj8" class="btn btn-default">iz reke v veliki vrč</button><br/>
            </td>
        </tr>
        <tr>
            <td style="text-align: right">
                <button id="j5toj8" class="btn btn-default">iz malega vrča v velikega</button>&nbsp;
            </td>
            <td style="text-align: left">
                <button id="j8toj5" class="btn btn-default">iz velikega vrča v malega</button>&nbsp;
            </td>
        </tr>
        <tr>
            <td style="text-align: right">
                <button id="j5tor" class="btn btn-default">iz malega vrča v reko</button>&nbsp;
            </td>
            <td style="text-align: left">
                <button id="j8tor" class="btn btn-default">iz velikega vrča v reko</button><br/>
            </td>
        </tr>
    </table>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var moves = '';
	var jug5 = 0;
    var jug8 = 0;
    var jug5_y = [193, 178, 166, 150, 131, 105];
    var jug8_y = [193, 182, 171, 161, 149, 135, 123, 105, 88];

    var handlers = {
        rtoj5 : function() { moves += '05 '; animate_jugs(5); },
        rtoj8: function()  { moves += '08 '; animate_jugs(undefined, 8); },
        j5tor: function()  { moves += '50 '; animate_jugs(0); },
        j8tor: function()  { moves += '80 '; animate_jugs(undefined, 0); },
        j5toj8: function() { moves += '58 ';
            if (jug5 < 8 - jug8) { animate_jugs(0, jug8 + jug5); }
            else { animate_jugs(jug5 - (8 - jug8), 8); }
        },
        j8toj5: function() { moves += '85 ';
            if (jug8 < 5 - jug5) { animate_jugs(jug5 + jug8, 0); }
            else { animate_jugs(5, jug8 - (5 - jug5)); }
        }

    }
	function puzzleLoad() {
        $('#buttons button').each(function() {
            $(this).click(handlers[$(this).attr('id')]);
        });

        $("#jugs").svg({
        loadURL: 'tasks/T13_SI_06_pretakanje/pretakanje.svg'});
	}


    function animate_jug(jug, now, final, ys) {
        if ((final === undefined) || (final == now)) {
            return null;
        }
        var y = ys[final];
        var diff = Math.abs(final - now);
        jug.animate({svgY: y, svgHeight: ys[0] - y}, 300 * diff);
        return diff;
    }

    function animate_jugs(j5, j8) {
        $('#buttons button').attr('disabled', 'disabled');
        var d1 = animate_jug($('#water-5'), jug5, j5, jug5_y);
        var d2 = animate_jug($('#water-8'), jug8, j8, jug8_y);
        setTimeout(function() {
            jug5 = j5 !== undefined ? j5 : jug5;
            jug8 = j8 !== undefined ? j8 : jug8;
            animation_done();
        }, 300 * (1 + (d1 || d2 || 1)));
    }

    function animation_done() {
        $('#content-5').text(jug5);
        $('#content-8').text(jug8);
        if ((jug5 == 4) || (jug8 == 4)) {
            setTimeout(puzzleCheckSolution, 2000);
        }
        else {
            $('#buttons button').removeAttr('disabled');
        }
    }

	function puzzleCheckSolution() {
		bQ.checkSolution(moves);
	}

	bQ.register(puzzleLoad);
}};
"""),

checkSolution = chk@{ solution ->
    val jugs = mutableMapOf(5 to 0, 8 to 0)
    solution.trim().split(' ').forEach {
        val n = it.toInt()
        val src = n / 10
        val dst = n % 10
        if (src == 0) jugs[dst] = dst
        else if (dst == 0) jugs[src] = 0
        else if (jugs[src]!! + jugs[dst]!! < dst) {
            jugs[dst] = jugs[dst]!! + jugs[src]!!
            jugs[src] = 0
        } else {
            jugs[src] = jugs[src]!! - (dst - jugs[dst]!!)
            jugs[dst] = dst
        }
    }
    4 in jugs.values
},

    explanation="""
    <div style="text-align: center">
        <img src="tasks/T13_SI_06_pretakanje/explanation-circle.png"/>
    </div>

    <p>Vsak par številk predstavlja eno od možnih "stanj"; prvo število pove
    količino vode v eni posodi, druga količino vode v drugi. Dve stanji sta
    povezani, če lahko v enem pretakanju pridemo iz enega stanja v drugega. Kakšno
    pretakanje je potrebno za to, lahko uganemo: če, recimo, hočemo iz (5, 3) v
    (0, 3), moramo zliti vodo iz večje posode v jezero. Ves notranji krog
    predstavlja stanje (5, 0) in ves zunanji krog stanje (0, 8).</p>
    
    <p>Začnemo v stanju (0, 0), priti moramo v (4, 8), (4, 0), (0, 4) ali (5, 4).
    Hitreje bo šlo po levi strani. Napolnimo manjšo posodo, pretočimo v večjo,
    napolnimo manjšo, pretočimo v večjo, izpraznimo večjo...</p>
    
    <p>Naloga je podobna navidez povsem drugačni nalogi, ki si jo že rešil: Ujemi
    barvo. Če si še nisi pogledal razlage, si jo le poglej in razmisli, po čem sta
    si tako podobni.</p>
    """
)
