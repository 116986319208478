class HiddenExit(direction: String, source: Int, destination: Int, close: Boolean = true)
    : Command(direction,
              showOn=FlagIsSet("open_${source}"),
              actions=Actions(
                  SetFlag("open_${source}", if (close) 0 else 1),
                  MoveTo("${destination.toString().padStart(4, '0')}_labirint")
              )
    ) {}


val regionLabirint_Matematikov = Region(
    "4_labirint_matematikov",

    Location("0400_labirint", null, "Labirint", """
        Saj ne more biti res! Odkril si - labirint matematikov!</p>
        
        Prastara zgodba, ki so jo že praprababice pripovedovale prababicam, govori,
        da so nekoč pohajali po svetu čudni bobri, ki so jim rekli matematiki. Bili so
        pametni in ukvarjali so se z vsemi mogočimi nenavadnimi vprašanji. O njih
        so hoteli razpravljati z drugimi bobri, ti - posebej takšni, ki sami niso bili
        posebej brihtni - pa so jih odganjali. Matematiki so se, tako pravi zgodba,
        nekega lepega dne naveličali in si zgradili domovanje pod globokim hribom.
        
        Skoraj nihče jih ni več videl in prav nihče, ki je odšel v njihove sobane, ki
        menda spominjajo na nekakšen labirint, se ni vrnil od njih. Tako so bobri na vse
        skupaj pozabili - in v te zgodbe pravzaprav niti ne verjamejo več.
        
        Tole je videti kot soba pod hribom, in kar je za njo, je videti kot labirint.
        Si upaš naprej? Ali pa jo boš raje podurhal nazaj ven, da se ne izgubiš še bolj?""",

        "Ven" to "0312_konec_soteske",
        "Naprej" to "0401_labirint"
    ),

    Location("0401_labirint", null, "Labirint", """
        <p>Stojiš v veliki sobi z ogromnim napisom <b>Radovedne čaka slava!</b>
        <p>Na steni si našel listek.</p>
        <div class="center-block"
        style="width: 400px; padding: 15px; background-color: white;
        border: 3px solid brown; font-style: italic;
        margin-bottom: 30px; margin-top:30px;">
        <p>Dobrodošel v labirintu matematikov. Gostom dovoljen le
        omejen dostop. Pametnim odpiramo dodatna vrata.</p>
        <p>Vsem obiskovalcem toplo
        priporočeno sprotno risanje zemljevida labirinta. Lahko na karirast papir.
        </p>""",

        "Z" to "0400_labirint",
        "V" to "0402_labirint",
        "S" to "0411_labirint"
    ),

    Location("0402_labirint", null, "Labirint", """
        <p>Begaš po labirintu matematikov. Pred tabo stoji gospod Collatz z nenavadno
        tezo, ki ji nihče ni kos.</p>""",

        "Z" to "0401_labirint",

        commands = Commands(
             StartTask("Govori z gospodom", "T07_XX_1A_zaporedje_stevil",
                 onSuccessActions = Actions(
                     SetFlag("open_402"),
                     Message("""Gospod Collatz ti pokaže dva skrivna izhoda iz svoje
                                sobe. "Pa še kaj se oglasite!" doda.""")),
                 onFailureActions = Actions(
                     Message(""""Ne bo res, zmotili ste se, kolega" reče gospod Collatz. "
                             '"Razmislite, preštejte še enkrat!"""")
                 )
             ),
            HiddenExit("V", 402, 403, close=false),
            HiddenExit("S", 402, 412, close=false)
        )
    ),

    Location("0403_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0402_labirint",
        "V" to "0404_labirint"),

    Location("0404_labirint", null, "Labirint", """
        Pred tabo je bober v belji halji, ki komaj čaka koga, ki bi znal rešiti
        zanimivo nalogo, ki si jo je izmislil.""",

        "Z" to "0403_labirint",

        commands = Commands(
            StartTask("Reši nalogo", "T07_XX_25_laznivi_jazbeci",
                showOn=Always(),
                onSuccessActions = Actions(
                    SetFlag("open_404"),
                    Message("\"Bravo!\" ti čestita. \"Za nagrado te spustim skozi skrivna vrata\"")),
                onFailureActions = Actions(
                    Message("\"Ne, ne, to ne more biti,\" odgovori. \"Razmisli, preštej še enkrat!\"")
                 )
             ),
             HiddenExit("S", 404, 414, close=true)
        )
    ),

    Location("0405_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0415_labirint"
    ),

    Location("0410_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0411_labirint"
),

    Location("0411_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0421_labirint",
        "J" to "0401_labirint",
        "V" to "0412_labirint",
        "Z" to "0410_labirint"
),

    Location("0412_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0402_labirint",
        "Z" to "0411_labirint"),

    Location("0413_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0423_labirint",
        "V" to "0414_labirint"),

    Location("0414_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0424_labirint",
        "J" to "0404_labirint",
        "Z" to "0413_labirint"),

    Location("0415_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0425_labirint",
        "J" to "0405_labirint"),

    Location("0420_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0430_labirint"),

    Location("0421_labirint", null, "Labirint",
        "Begaš po labirintu matematikov. Romantičen matematik sanja o prelepem jezeru z lokvanji.",
        "J" to "0411_labirint",

        commands = Commands(
            StartTask("Poslušaj o lokvanjih", "TXX_XX_01_lokvanji",
                onSuccessActions = Actions(
                    SetFlag("open_421"),
                    Message(""""Tako je, tako je, mladi mož. Pa še nekaj zanimivega ti '
                                povem: iz moje sobe vodijo tudi vrata na vzhod. Le skoznja!"""")
),
                onFailureActions = Actions(
                    Message("\"Hm, ne bo držalo. Le razmisli,\" te popravi zasanjani mož.")
                )
            ),
            HiddenExit("V", 421, 422, close=false)
        )
    ),

    Location("0422_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0432_labirint",
        "Z" to "0421_labirint"),

    Location("0423_labirint", null, "Labirint", { state ->
        if ("open_423" !in state.flags)
            "\"Izmislil sem si število med 0 in 255. Ugibaj!\" te izzove zaraščeni matematik."
        else if (FlagIsSet("open_423").verify(state))
            "Begaš po labirintu matematikov."
        else
            "\"O, bova spet ugibala?\" te izzove zaraščeni matematik."
        },

        "J" to "0413_labirint",

        commands = Commands(
            StartTask("Ugibaj število", "T07_XX_X1_ugibanje_stevila",
                showOn=Always(),
                onSuccessActions = Actions(
                    SetFlag("open_423"),
                    Message("Tule je moj skrivni izhod.")),
                onFailureActions = Actions(
                    Message("Osem poskusov je mimo. Kako čas leti, ko se zabavaš! Poskusiva ponovno?")
                )
            ),
            HiddenExit("S", 423, 433, close=true)
        )
    ),

    Location("0424_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0414_labirint"),

    Location("0425_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0435_labirint",
        "J" to "0415_labirint"),

    Location("0430_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0420_labirint",
        "V" to "0431_labirint"),

    Location("0431_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0432_labirint",
        "Z" to "0430_labirint",
        "S" to "0441_labirint"),

    Location("0432_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0431_labirint",
        "J" to "0422_labirint"),

    Location("0433_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0443_labirint",
        "J" to "0423_labirint",
        "V" to "0434_labirint"),

    Location("0434_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0444_labirint",
        "V" to "0435_labirint",
        "Z" to "0433_labirint"),

    Location("0435_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0445_labirint",
        "J" to "0425_labirint",
        "Z" to "0434_labirint"),

    Location("0440_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0450_labirint",
        "V" to "0441_labirint"),

    Location("0441_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0440_labirint",
        "J" to "0431_labirint"),

    Location("0442_labirint", null, "Labirint", { state ->
            "Begaš po labirintu matematikov." +
            if (FlagIsSet("open_442").verify(state)) "" else 
            " Pred tabo je čudak s kupom praznih škatel riža, porabljenih paštet, ovitkov salame..."},

        "V" to "0443_labirint",
        commands = Commands(
            StartTask("Govori z gospodom", "T13_SE_06_kontrolna_vsota",
                showOn=FlagNotSet("open_442"),
                onSuccessActions = Actions(
                    SetFlag("open_442"),
                    Message("Čudak ti odpre vrata severna vrata svoje sobe")
),
                onFailureActions = Actions(
                    Message("\"Narobe!\" reče. \"Poskusi še enkrat!\"")
                )
            ),
            HiddenExit("S", 442, 452, close=false)
        )
    ),

    Location("0443_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0433_labirint",
        "Z" to "0442_labirint"),

    Location("0444_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0454_labirint",
        "J" to "0434_labirint"),

    Location("0445_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0455_labirint",
        "J" to "0435_labirint"),

    Location("0450_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0440_labirint"),

    Location("0451_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0461_labirint",
        "V" to "0452_labirint"),

    Location("0452_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0451_labirint",
        "V" to "0453_labirint",
        "J" to "0442_labirint"),

    Location("0453_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0452_labirint"),

    Location("0454_labirint", null, "Labirint", """
        Begaš po labirintu matematikov. Pred tabo je zgaran matematik,
        v kotu njegove sobe pa kupi pisanih plošč.""",

        "J" to "0444_labirint",

        commands = Commands(
            StartTask("Govori z matematikom", "T13_SE_10_barvne_plosce",
                showOn=FlagNotSet("open_454"),
                onSuccessActions = Actions(
                    SetFlag("open_454"),
                    Message("\"Tako je! Zdaj pa kar na sever. Če se ti zdi to pametno...\"")
),
                onFailureActions = Actions(
                    Message("\"Hm, ne ravno. Nariši prvih nekaj plošč in opazuj vzorec!\"")
                )
            ),
            HiddenExit("S", 454, 464, close=false)
        )
    ),

    Location("0455_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0465_labirint",
        "J" to "0445_labirint"),

    Location("0460_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0461_labirint"),

    Location("0461_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0471_labirint",
        "V" to "0462_labirint",
        "Z" to "0460_labirint",
        "J" to "0451_labirint"),

    Location("0462_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0463_labirint",
        "Z" to "0461_labirint"),

    Location("0463_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0464_labirint",
        "Z" to "0462_labirint",
        "S" to "0473_labirint"),

    Location("0464_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "S" to "0474_labirint",
        "J" to "0454_labirint",
        "Z" to "0463_labirint"),

    Location("0465_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "J" to "0455_labirint"),

    Location("0470_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0471_labirint"),

    Location("0471_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0470_labirint",
        "V" to "0472_labirint",
        "J" to "0461_labirint"),

    Location("0472_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "Z" to "0471_labirint"),

    Location("0473_labirint", null, "Labirint", "Begaš po labirintu matematikov.",
        "V" to "0474_labirint",
        "J" to "0463_labirint"),

    Location("0474_labirint", null, "Labirint",
        "Bližaš se koncu. Mož pred tabo je namreč finančni matematik.",

        "Z" to "0473_labirint",
        "J" to "0464_labirint",

        commands = Commands(
            StartTask("Govori s finančni(m matemati)kom", "T07_XX_28_ponarejeni_kovanec",
                onSuccessActions = Actions(
                    SetFlag("open_474"),
                    Message("\"Tako, tako, tudi meni se je tako zdelo,\" prikima. \"Bi šel proti vzhodu?\"")
                ),
                onFailureActions = Actions(
                    Message(""""Bojim se, da vas bodo ogoljufali, mladi mož,"
                                reče finančnik in si učeno popravi očala."""")
                    )
            ),
            HiddenExit("V", 474, 475, close=false)
        )
    ),

    Location("0475_labirint", null, "Labirint", "Na vzhodu se nekaj svetlika.",
        "V" to "0500_jasa",
        "Z" to "0474_labirint"
    ),

    hasMap = false
)
