val T09_XX_22_ujemi_barvo = Task("T09_XX_22_ujemi_barvo", "Ujemi barvo",

description = """
    <p>Na vratih je skrivnostna ukazna plošča s štirimi barvnimi tipkami in
    nenavadnim zaslonom. Najbrž bo potrebno vtipkati nekakšno geslo.</p>

	<div id="buttons" class="center-block" style="width:491px;height:316px"></div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var steps = 0;
    var blocked = false;
    var positions = {"free": 0, "red": 1, "yellow": 2, "blue": 3, "green": 4};
    var edges = [[0, 1, 1, 0, 1],
                 [1, 0, 1, 0, 0],
                 [1, 1, 0, 1, 1],
                 [0, 0, 1, 0, 1],
                 [1, 0, 1, 1, 0]];
    var solution = "";

    function puzzleLoad() {
		$("#buttons").svg({
			loadURL: 'tasks/T09_XX_22_ujemi_barvo/control-board.svg',
			onLoad: buttonsInit
		});
	}


	function buttonsInit() {
        $("#slider").hide();
        _.each(["red", "green", "blue", "yellow"], function(e, i, l) {
            $("#"+e).click(function() { puzzleAdd(e); });
        });
	}

	function puzzleAdd(color) {
        if (blocked) return;
        var o = $("#seq"+steps);
        o.css("fill", color);
        o.attr("data-col", color);
        steps++;
        animateToken(color);
	}

    function animateToken(color) {
        solution += color + " ";
        var obj = $("#dot" + color);
        var free_pos = positions["free"];
        var free_hole = $("#h" + free_pos);
        if (edges[positions[color]][free_pos] == 0) {
            blocked = true;
            blinkBlink(obj);
        }
        else {
            positions["free"] = positions[color];
            positions[color] = free_pos;
            $(obj).animate(
                {svgCx: free_hole.attr("cx"), svgCy: free_hole.attr("cy")},
               {"step": function(now, tw) { $(this).attr(tw.prop, now); },
                 "duration": 600,
                 "complete": checkDone}
            );
        }
    }
    
    function checkDone() {
        if (steps == 7) {
            setTimeout(() => bQ.checkSolution(solution), 1000)
        }
    }

    function blinkBlink(obj) {
        $(obj).animate({svgOpacity: 0}, 300).delay(200)
              .animate({svgOpacity: 1}, 300).delay(200)
              .animate({svgOpacity: 0}, 300).delay(200)
              .animate({svgOpacity: 1}, 300).delay(200)
              .animate({svgOpacity: 0}, 300).delay(200)
              .animate({svgOpacity: 1}, 300, "linear",
                       function() {bQ.checkSolution("")})
    }
	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{  solution ->
    val edges = listOf("01101", "10100", "11011", "00101", "10110").map { it.map (Char::toInt) }
    val positions = mutableMapOf("free" to 0, "red" to 1, "yellow" to 2, "blue" to 3, "green" to 4)
    solution.trim().split(' ').forEach { color ->
        val src = positions[color]
        val dst = positions["free"]
        if (dst == null || src == null || edges[src][dst] == 0) return@chk false
        positions["free"] = src
        positions[color] = dst
    }
    positions == mapOf("red" to 0, "green" to 1, "yellow" to 2, "free" to 3, "blue" to 4)
},

explanation="""
    <p>Kako se sistematično lotiti takšne naloge? Narišemo začetno postavitev.
    Na voljo so nam tri različne poteze (premaknemo lahko rdečo, zeleno ali
    rumeno figuro). Narišemo postavitve, ki jih dobimo pri vsaki od njih in
    jih s črtami povežemo z začetno. Iz vsake od njih lahko pridemo do drugih
    postavitev... Vse lepo pridno rišemo in povezujemo. Ko pridemo do želene
    postavitve, v kateri je vsaka figura na svoji barvi, pogledamo zaporedje
    potez, ki vodi do nje.</p>

    <div style="width: 100%; text-align: center">
        <img src="tasks/T09_XX_22_ujemi_barvo/explanation.png" style="margin-left: 20px;">
    </div>
    
    <p>Takšni sliki rečemo <em>graf stanj</em>. Naloga zahteva, da poiščeš pot v
    njem, ki vodi od začetne do končne postavitve v sedmih korakih. Poleg zaporedja,
    s katerim si rešil nalogo. Obstajajo natančno tri druga. Jih lahko poiščeš?</p>"""
)
