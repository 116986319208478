val T10_DE_13_zalivanje_roz = Task("T10_DE_13_zalivanje_roz", "Zalivanje rož",

description = """
<p>Nastavi ventile tako, da bo voda tekla samo na rože in ne bo odtekala nikjer
drugje.</p>

<div id="puzzleCont" class="center-block" style="border:5px solid white;border-radius: 10px;">
	<div id="puzzleLevelLayer01" style="position:absolute;background-color:white;"></div>
	<div id="puzzleLevelLayer02" style="position:absolute;"></div>
	<div id="puzzleLevelLayer03" style="position:absolute;"></div>
</div>

<div style="padding:5px;text-align:center;">
	<button id="puzzleBtn01" class="btn btn-default">Spusti vodo</a>
	<button id="puzzleBtn02" disabled="disabled" class="btn btn-default">Ustavi vodo</a>
	&nbsp;&nbsp;&nbsp;
	<button id="puzzleBtn03" class="btn btn-default"><b>&#10004; Preveri rešitev</b></a>
</div>
""",

runTask = eval("""{ run: (bQ) => {

	var puzzleLevel = [];
	var faucetAnimationOffset = 0;
	var waterAnimationStopped = true;
	var puzzleWidth = 0;
	var puzzleHeight = 0;

	var puzzle_spriteAnimationEnabled = true;

	function puzzleLoad() {

		// TODO: Dynamically get url to game level, which should reside in game folder, not static folder
		loadLevel("tasks/T10_DE_13_zalivanje_roz/T10_DE_13_zalivanje_roz.txt",23,19);

	}

	function loadLevel(url,width,height) {

		$.get(url,{},function(levelData){

			puzzleWidth  = width;
			puzzleHeight = height;

			initLevel(levelData);

			$("#puzzleBtn01").click(function(){
				waterAnimationStopped = false;
				$("#puzzleBtn01").attr("disabled","disabled");
				$("#puzzleBtn02").removeAttr("disabled");
				animateWaterFromSources();
			});

			$("#puzzleBtn02").click(function(){
				waterAnimationStopped = true;
				$("#puzzleBtn02").attr("disabled","disabled");
				setTimeout(function(){
					$("#puzzleBtn01").removeAttr("disabled");
					$(".puzzleFaucet").css("display","none");
					$("#puzzleLevelLayer02").html("");
				},1000);
			});

			$("#puzzleBtn03").click(function(){
				puzzleSubmit();
			});

		});
	}

	function initLevel(levelData) {

		// Construct level
		levelData = levelData.split("\n");
		for(var i = 0; i < puzzleHeight; i++) {
			puzzleLevel.push([]);
			for(var j = 0; j < puzzleWidth; j++) {
				puzzleLevel[i].push(levelData[i][j]);
			}
		}

		// Draw level
		$("#puzzleCont").css("width",(puzzleWidth*25+10)+"px").css("height",(puzzleHeight*25+10)+"px");
		$("#puzzleCont > div").css("width",(puzzleWidth*25)+"px").css("height",(puzzleHeight*25)+"px");
		for(var i = 0; i < puzzleHeight; i++) {
			for(var j = 0; j < puzzleWidth; j++) {

				var tile = levelData[i][j];

				var newHtmlLayer01 = "";
				var newHtmlLayer02 = "";
				var newHtmlLayer03 = "";

				if(tile == " " || tile == "u") continue;

				else if(tile == "S") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+((j-1)*25)+'px;top:'+((i-2)*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/t.png'+'\');width:75px;height:75px;" />';
					newHtmlLayer01 = '<div style="position:absolute;left:'+((j-1)*25)+'px;top:'+((i-2)*25)+'px;width:75px;height:75px;background-color:#009fe3;" />';
				}

				else if(tile == "U") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+((j-1)*25)+'px;top:'+((i-2)*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/t.png'+'\');width:75px;height:75px;" />';
				}

				else if(tile == "═") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/ph.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "║") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/pv.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╬") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c4.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╠") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c32.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╦") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c31.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╩") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c33.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╣") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c34.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╗") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c21.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╝") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c24.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╚") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c23.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "╔") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/c22.png'+'\');width:25px;height:25px;" />';
				}

				else if(tile == "V") {
					newHtmlLayer03 = '<div class="puzzleValve" data-x="'+j+'" data-y="'+i+'" style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/vv.png'+'\');width:25px;height:25px;background-position:25px 0px;cursor:pointer;" />';
				}

				else if(tile == "v") {
					newHtmlLayer03 = '<div class="puzzleValve" data-x="'+j+'" data-y="'+i+'" style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/vv.png'+'\');width:25px;height:25px;background-position:0px 0px;cursor:pointer;" />';
				}

				else if(tile == "H") {
					newHtmlLayer03 = '<div class="puzzleValve" data-x="'+j+'" data-y="'+i+'" style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/vh.png'+'\');width:25px;height:25px;background-position:25px 0px;cursor:pointer;" />';
				}

				else if(tile == "h") {
					newHtmlLayer03 = '<div class="puzzleValve" data-x="'+j+'" data-y="'+i+'" style="position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/vh.png'+'\');width:25px;height:25px;background-position:0px 0px;cursor:pointer;" />';
				}

				else if(tile == "F") {
					newHtmlLayer03 = '<div id="f_'+i+'_'+j+'" class="puzzleFaucet" style="display:none;position:absolute;left:'+(j*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/f.png'+'\');width:25px;height:25px;" />';

				}

				else if(tile == "P") {
					newHtmlLayer03 = '<div style="position:absolute;left:'+((j-1)*25)+'px;top:'+(i*25)+'px;background-image:url(\''+'tasks/T10_DE_13_zalivanje_roz/tiles/p.png'+'\');width:75px;height:75px;" />';
				}

				$("#puzzleLevelLayer01").append(newHtmlLayer01);
				$("#puzzleLevelLayer02").append(newHtmlLayer02);
				$("#puzzleLevelLayer03").append(newHtmlLayer03);

			}
		}

		animateFaucets();

		// Make valves clickable
		$(".puzzleValve").click(function(){

			if(waterAnimationStopped) {

				var x = $(this).attr("data-x");
				var y = $(this).attr("data-y");
				var tile = puzzleLevel[y][x];

				if(tile == "V") { puzzleLevel[y][x] = "v"; $(this).css("background-position","0px 0px"); }
				else if(tile == "v") { puzzleLevel[y][x] = "V"; $(this).css("background-position","25px 0px"); }
				else if(tile == "H") { puzzleLevel[y][x] = "h"; $(this).css("background-position","0px 0px"); }
				else if(tile == "h") { puzzleLevel[y][x] = "H"; $(this).css("background-position","25px 0px"); }

			}

		});

	}

	function getTile(y,x) {

		if( y < 0 || x < 0 || y == puzzleLevel.length || x == puzzleLevel[0].length) return null;
		else return puzzleLevel[y][x];

	}

	function animateFaucets() {

		faucetAnimationOffset = (faucetAnimationOffset+25)%125;
		$(".puzzleFaucet").css("background-position",faucetAnimationOffset+"px 0px");

		if(puzzle_spriteAnimationEnabled)
			setTimeout(animateFaucets,100);

	}

	// Finds water sources and starts water flow from them
	function animateWaterFromSources() {

		for(var i = 0; i < puzzleHeight; i++) {
			for(var j = 0; j < puzzleWidth; j++) {
				var tile = getTile(i,j);
				if(tile == "S") animateWater(i,j,"u");
			}
		}

	}

	// TODO: Not all tiles animations are implemented
	//       Only the ones required for T10_DE_13_zalivanje_roz are.
	//       If this code is to be reused for other tasks, all other possible tile
	//       animations should be added.
	function animateWater(y,x,from) {

		if(waterAnimationStopped) return false;

		var tile = getTile(y,x);
		var id   = "w_" + y + "_" + x + "_" + from;
		var newHtml = "";

		if($("#"+id).length > 0) return false;

		if(tile == "S") animateWater(y+1,x,"u");

		else if(tile == "║" || tile == "V") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25)+'px;width:25px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"25px"},250,"linear",function(){
				animateWater(y+1,x,"u");
			});
		}

		else if(tile == "v") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25)+'px;width:25px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"12px"},125,"linear",function(){});
		}

		else if(tile == "╬" && from == "u") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25+7)+'px;top:'+(y*25)+'px;width:11px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"25px",width:"25px",left:x*25+"px"},250,"linear",function(){
				animateWater(y+1,x,"u");
				animateWater(y,x-1,"r");
				animateWater(y,x+1,"l");
			});
		}

		else if(tile == "╠" && from == "u") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25+7)+'px;top:'+(y*25)+'px;width:11px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"25px",width:"25px",left:x*25+"px"},250,"linear",function(){
				animateWater(y+1,x,"u");
				animateWater(y,x+1,"l");
			});
		}

		else if((tile == "═" || tile == "H" || tile == "╩") && from == "l") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25+7)+'px;width:0px;height:11px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"25px"},250,"linear",function(){
				animateWater(y,x+1,"l");
			});
		}

		else if((tile == "═" || tile == "H" || tile == "╩") && from == "r") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+((x+1)*25)+'px;top:'+(y*25+7)+'px;width:0px;height:11px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"25px",left:(x*25)+"px"},250,"linear",function(){
				animateWater(y,x-1,"r");
			});
		}

		else if(tile == "h" && from == "l") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25)+'px;width:0px;height:25px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px"},125,"linear",function(){});
		}

		else if(tile == "h" && from == "r") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+((x+1)*25)+'px;top:'+(y*25)+'px;width:0px;height:25px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px",left:(x*25+18)+"px"},125,"linear",function(){});
		}

		else if(tile == "╦" && from == "l") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25)+'px;width:0px;height:18px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px"},125,"linear",function(){
				$("#"+id).animate({width:"25px",height:"25px"},125,"linear",function(){
					animateWater(y,x+1,"l");
					animateWater(y+1,x,"u");
				});
			});
		}

		else if(tile == "╦" && from == "r") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+((x+1)*25)+'px;top:'+(y*25)+'px;width:0px;height:18px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px",left:(x*25+18)+"px"},125,"linear",function(){
				$("#"+id).animate({width:"25px",height:"25px",left:(x*25)+"px"},125,"linear",function(){
					animateWater(y,x-1,"r");
					animateWater(y+1,x,"u");
				});
			});
		}

		else if(tile == "╩" && from == "u") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25+7)+'px;top:'+(y*25)+'px;width:11px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"25px",width:"25px",left:x*25+"px"},250,"linear",function(){
				animateWater(y,x-1,"r");
				animateWater(y,x+1,"l");
			});
		}

		else if(tile == "╗") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+(x*25)+'px;top:'+(y*25)+'px;width:0px;height:18px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px"},125,"linear",function(){
				$("#"+id).animate({width:"25px",height:"25px"},125,"linear",function(){
					animateWater(y+1,x,"u");
				});
			});
		}

		else if((tile == "╔" || tile == "╠") && from == "r") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+((x+1)*25)+'px;top:'+(y*25+7)+'px;width:0px;height:11px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({width:"12px",left:(x*25+18)+"px"},125,"linear",function(){
				$("#"+id).animate({width:"25px",height:"18px",left:(x*25)+"px"},125,"linear",function(){
					animateWater(y+1,x,"u");
				});
			});
		}

		else if(tile == "F") {
			$("#f_"+y+"_"+x).fadeTo(250,1,function(){
				animateWater(y+1,x,"u");
			});
		}

		else if(tile == "u") {
			animateWater(y+1,x,"u");
		}

		else if(tile == "U") {
			newHtml = '<div id="' + id + '" style="position:absolute;left:'+((x-1)*25)+'px;top:'+((y+1)*25)+'px;width:75px;height:0px;background-color:#009fe3;" />';
			$("#puzzleLevelLayer02").append(newHtml);
			$("#"+id).animate({height:"75px",top:((y-2)*25)+"px"},1000,"linear",function(){ });
			animateWater(y+1,x,"u");
		}

	}

	function puzzleSubmit() {

		waterAnimationStopped = false;
		$("#puzzleBtn01").attr("disabled","disabled");
		$("#puzzleBtn02").attr("disabled","disabled");

		// Serialize current puzzle status to text
		var solution = "";
		for(var i = 0; i < puzzleHeight; i++) {
			for(var j = 0; j < puzzleWidth; j++) {
				solution += getTile(i,j);
			}
			if(i != puzzleHeight-1) solution += "\n";
		}

		puzzle_spriteAnimationEnabled = false;
		bQ.checkSolution(solution);

	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { s: String ->
    val levelData = s.split('\n')
    var reachesFlowers = false

    fun getTile(y: Int, x: Int): Char =
        levelData.getOrNull(y)?.getOrNull(x) ?: ' '

    fun noSpill(y: Int, x: Int, dir: Char): Boolean {
        val tile = getTile(y, x)

        return if (tile == 'F') {
            when (getTile(y + 1, x)) {
                'P' -> {
                    reachesFlowers = true
                    true
                }
                'u' -> noSpill(y + 1, x, 'u')
                else -> false
            }
        } else when (dir) {
            'l' -> when (tile) {
                'H', '═', '╩' -> noSpill(y, x + 1, 'l')
                '╦', '╬' -> noSpill(y, x + 1, 'l') && noSpill(y + 1, x, 'u')
                '╗', '╣' -> noSpill(y + 1, x, 'u')
                else -> true
            }
            'r' -> when (tile) {
                'H', '═', '╩' -> noSpill(y, x - 1, 'r')
                '╦', '╬' -> noSpill(y, x - 1, 'r') && noSpill(y + 1, x, 'u')
                '╔', '╠' -> noSpill(y + 1, x, 'u')
                else -> true
            }
            'u' -> when (tile) {
                'u', 'U', 'S', 'V', '║' -> noSpill(y + 1, x, 'u')
                '╚' -> noSpill(y, x + 1, 'l')
                '╝' -> noSpill(y, x - 1, 'r')
                '╣' -> noSpill(y, x - 1, 'r') && noSpill(y + 1, x, 'u')
                '╠' -> noSpill(y, x + 1, 'l') && noSpill(y + 1, x, 'u')
                '╩' -> noSpill(y, x + 1, 'l') && noSpill(y, x - 1, 'r')
                '╬' -> noSpill(y, x + 1, 'l') && noSpill(y, x - 1, 'r') && noSpill(y + 1, x, 'u')
                else -> true
            }
            else -> true
        }
    }

    levelData.withIndex().all { (y, level) ->
        level.withIndex().all { (x, tile) ->
            (tile != 'S') || (noSpill(y, x, 'u'))
        }
    } && reachesFlowers
},

explanation="""
    <p>Računalniki so sestavljeni iz čipov, ti pa iz "logičnih vrat". Ta se obnašajo
        podobno kot ventili iz naloge, le da se skoznje namesto vode pretaka
        elektrika, namesto cevi pa jih povezujejo žice.</p>

    <p>Vse elektronske naprave, od telefonov do najbolj zapletenih računalnikov so
        sestavljeni le iz ogromnega števila takšnih "električnih ventilov".</p>

"""
)

