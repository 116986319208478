import react.ReactElement


interface Checkable {
    fun checkConsistency(): String
}

fun <P, R>checkExists(what: P?, where: Map<P, R>, obj: String) =
    if (what == null || what in where) "" else "$obj does not exist"

fun mergeConsistency(seq: Collection<Checkable>) =
    seq
        .map { it.checkConsistency() }
        .filter { it != ""}
        .joinToString("\n")


@JsModule("react-render-html")
@JsNonModule
external fun renderHtml(html:String):ReactElement

