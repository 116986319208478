val T09_XX_35_piskajoci_bobri = Task("T09_XX_35_piskajoci_bobri", "Piskajoči bobri",

description = """


<style>
    .word img {
        margin: 0;
        padding: 0;
    }

    #solution .word {
        padding: 5px;
        margin-bottom: 5px;
        border: solid black 1px;
        border-radius: 3px;
        background-color: white;
    }
</style>

<p>Na avtobusih ni nobenih napisov. Svojčas so šoferji avtobusov stali pred
 avtobusi in glasno klicali ime kraja, kamor vozijo. Da bi se izognili
 kričanju, so jim upravitelji parka dali piščalke, na katere - na bogsigavedi
 kakšen način - piskajo ime kraja, kamor vozijo.</p>

 <p>Eden od šoferjev je pravkar poklical svojo kokoško z imenom Koka:
 <span class="word">
     <div data-answer="" class="word">
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
</div>


 </span>.</p>

 <p>Ostali so zapiskali naslednja imena krajev:</p>

<div id="solution" class="block-center bQ-actions bQ-shuffle">
<div id="b0" data-answer="0" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
</div>

<div id="b1" data-answer="1" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
</div>

<div id="b2" data-answer="2" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
</div>

<div id="b3" data-answer="3" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
</div>

<div id="b4" data-answer="4" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
</div>

<div id="b5" data-answer="5" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
</div>

<div id="b6" data-answer="6" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
</div>

<div id="b7" data-answer="7" class="word">
<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
</div>


</div>

 <p>Izberi tistega, s katerim se boš peljal.</p>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	var words = ["bobrnik", "kokrica", "babnica", "besnica", "modrije",
                 "hohle", "bobrdol", "borbek"];

    var symbols = {'a': '-.', 'b': '.---', 'c': '-.-.', 'd': '-..',
                   'e': '-', 'f': '--.-', 'g': '--.', 'h': '....',
                   'i': '..', 'j': '.---', 'k': '-.-', 'l': '.-..',
                   'm': '--', 'n': '-.', 'o': '---', 'p': '.--.',
                   'r': '.-.', 's': '...', 't': '-', u: '..-',
                   'v': '...-', 'z': '--..'};

    var filenames = {
        '.': 'tasks/T09_XX_35_piskajoci_bobri/piska-hi.png',
        '-': 'tasks/T09_XX_35_piskajoci_bobri/piska-lo.png'};

    function code_char(c) {
        var r = '';
        var sym = symbols[c];
        for(var i = 0; i < sym.length; i++) {
            r += '<img src="' + filenames[sym[i]] + '"/>';
        }
        return r;
    }

	function puzzleInit() {
		for(var i = 0; i <= 7; i++) {
            var id = "#b" + i;
			$(id).css("cursor","pointer");
            $(id).attr("data-id", i.toString());
			$(id).click(function(){
				puzzleClick($(this).attr("data-id"));
			});
		}
	}

	function puzzleClick(id) {
   		bQ.checkSolution(id);
    }

	bQ.register(puzzleInit);

}};
"""),

checkSolution = { it == "0" },

explanation="""
    <style>
    .word img {
        margin: 0;
        padding: 0;
    }

    #solution .word {
        padding: 5px;
        margin-bottom: 5px;
        border: solid black 1px;
        border-radius: 3px;
        background-color: white;
    }
    </style>

    <p>Naloga pravi, da je nekdo poklical kokoško z imenom KOKA takole:</p>

    <span class="word">
    <div data-answer="" class="word">
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
         &nbsp;&nbsp;
         <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
    </div>

    <p>Če ime KOKA zapišemo z zaporedjem štirih skupin tonov, si lahko mislimo, da
    vsako zaporedje pomeni eno črko. Poleg tega opazimo tudi, da sta prvo in
    tretje zaporedje enaki - tako kot prva in tretja črka. Tako mimogrede izvemo
    tudi, kako se zapiše črka K. Vemo tudi, kako se zapiše O.</p>

    <p>Vemo, da hočemo v BOBRNIK.</p>
    
    <div id="solution" class="block-center bQ-actions bQ-shuffle">
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
    </div>
    
    <p>To ime se očitno začne s KOK, torej to ne more biti BOBRNIK.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
    </div>
    
    <p>Druga črka ni O, zadnja pa ni K. (Poleg tega sta celo enaki!)</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
    </div>
    
    <p>Spet, druga črka ni O, zadnja pa ne K.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
    </div>
    
    <p>Zadnja črka ni K.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
    </div>
    
    <p>Zadnja črka ni K.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>
    </div>
    
    <p>Zadnja črka ni K.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
    </div>
    
    <p>Prva črka bi morala biti enaka tretji - vendar ni. Poleg tega ima ta beseda
    le šest črk, BOBRNIK pa jih ima sedem.</p>
    
    <div class="word">
    <img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/>&nbsp;&nbsp;<img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-hi.png"/><img src="tasks/T09_XX_35_piskajoci_bobri/piska-lo.png"/>
    </div>
    
    <p>Tole je v resnici BOBRNIK.</p>
    
    <p>Znaki v nalogi niso izmišljeni kar tako. V resnici gre za
        <a target="_blank" href="http://sl.wikipedia.org/wiki/Morsejeva_abeceda">
            Morsejevo abecedo
            </a>, pri kateri znake sporočamo s krajšimi in daljšimi piski. Tule smo
        krajše piske zamenjali z visokimi, daljše z nizkimi.</p>
    
    <p>Znaš prebrati imena preostalih krajev?</p>
    """
)
