import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import org.w3c.dom.events.KeyboardEvent
import react.*
import react.dom.*
import kotlin.browser.document
import kotlin.browser.window

class Repository(
    var locations: MutableMap<LocId, Location> = mutableMapOf(),
    val initialLocation: String = "0000_zacetna_soba",
    var tasks: MutableMap<TaskId, Task> = mutableMapOf()
) {
    fun checkConsistency() = mergeConsistency(locations.values)
}

val repository = Repository()

typealias TakenPaths = MutableMap<Pair<LocId, LocId>, MutableSet<String?>>

data class GameState(
    var location: LocId = repository.initialLocation,
    var inventory: MutableSet<ItemId> = mutableSetOf(),
    var solvedTasks: MutableSet<TaskId> = mutableSetOf(),
    var flags: MutableMap<FlagId, Int> = mutableMapOf(),
    var pathsTaken: TakenPaths = mutableMapOf(),
    var visited: MutableSet<LocId> = mutableSetOf(repository.initialLocation)) {

    fun serialize() = """
        $location
        ${inventory.joinToString("|")}
        ${solvedTasks.joinToString("|")}
        ${flags.entries.map { (k, v) -> "$k:$v" }.joinToString("|") }
        ${pathsTaken.map { (path, dirs) -> "${path.first}:${path.second}:${dirs.joinToString(":")}" }.joinToString("|") }
        ${visited.joinToString("|")}""".trimIndent()

    fun deserialize(s: String) {
        val lines = s.split('\n').toMutableList()
        location = lines.removeAt(0)
        inventory = lines.removeAt(0).split("|").filter { it != "" }.toMutableSet()
        solvedTasks = lines.removeAt(0).split("|").filter { it != "" }.toMutableSet()
        flags = lines.removeAt(0).split("|").filter { it != "" }.associate { it.split(":").let { (k, v) -> k to v.toInt() } }.toMutableMap()
        pathsTaken = lines.removeAt(0).split("|").filter { it != "" }.associate { it.split(":").let { (it[0] to it[1]) to it.takeLast(it.size - 2).toMutableSet<String?>() } }.toMutableMap()
        visited = lines.removeAt(0).split("|").filter { it != "" }.toMutableSet()
    }

    fun reset() {
        location = repository.initialLocation
        inventory= mutableSetOf()
        solvedTasks = mutableSetOf()
        flags = mutableMapOf()
        pathsTaken = mutableMapOf()
        visited = mutableSetOf(repository.initialLocation)
    }
}

interface AppState: RState {
    var gameState: GameState
    var currentPage: Int
    var message: ReactElement?
    var explanation: ReactElement?
    var shownTask: ShowTask?
}


class App: RComponent<RProps, AppState>() {
    val actionQueue = mutableListOf<Action>()
    val commandQueue = mutableListOf<Command>()

    override fun AppState.init() {
        gameState = GameState()
        currentPage = 0
        // shownTask = ShowTask("T13_SI_12_labirint", Actions(Message("success")), Actions(Message("failure")))
        window.localStorage.getItem("state")?.let { gameState.deserialize(it) }
    }

    init {
        document.onkeydown = ::onKeydown
    }

    override fun RBuilder.render() {
        div {
            attrs["id"] = "bQ-main"
            div {
                attrs["id"] = "bQ-left"
            }
            div {
                attrs["id"] = "bQ-bookmarks"
                div {
                    attrs["id"] = "first"
                    attrs {
                        onClickFunction = { setState { currentPage = 0 }}
                    }
                    img(src="assets/lemmling-beaver.svg") {}
                    +"Igra"
                }
                div {
                    attrs["id"] = "second"
                    attrs {
                        onClickFunction = { setState { currentPage = 1 }}
                    }
                    img(src="assets/dog-contactr.svg") {}
                    +"Naloge"
                }
                div {
                    attrs["id"] = "third"
                    attrs {
                        onClickFunction = { setState { currentPage = 2 }}
                    }
                    img(src="assets/impressed-alien.svg") {}
                    +"Nastavitve"
                }
            }
            when (state.currentPage) {
                1 -> explanations(state,
                                  { setState { explanation = renderHtml(it) } },
                                  { setState { shownTask = ShowTask(it,
                                      Actions(Message("Rešitev je pravilna.")),
                                      Actions(Message("Rešitev je napačna."))) } })
                2 -> settings(state) { setState { currentPage = 0} }
                else -> game(state.gameState, ::enqueueCommand) { setState { explanation = renderHtml(it) } }
            }

            if (state.explanation != null)
                explanation(state.explanation!!) { setState { explanation = null } }

            if (state.message != null)
                message(state.message!!, ::closeMessage)

            if (state.shownTask != null) {
                clearQueues()
                task {
                    shownTask = this@App.state.shownTask!!
                    state = this@App.state.gameState
                    endTask = { actions ->
                        actionQueue.addAll(actions)
                        setState({ state -> state.shownTask = null; state },
                            ::processActionQueue)
                    }
                }
            }
        }
    }

    private fun enqueueCommand(command: Command) {
        commandQueue.add(command)
        processCommandQueue()
    }

    private fun processCommandQueue() {
        if (commandQueue.isNotEmpty()) {
            val command = commandQueue.removeAt(0)
            command(state.gameState, ::enqueueAction)
            processActionQueue()
        }
    }

    private fun enqueueAction(action: Action) {
        actionQueue.add(action)
    }

    private fun processActionQueue() {
        if (state.message == null && actionQueue.isNotEmpty()) {
            val action = actionQueue.removeAt(0)
            if (DEBUG) {
                console.log(action)
            }
            val prevLoc = state.gameState.location
            when (action) {
                is ShowTask -> state.shownTask = action
                is Message -> state.message = action.message
                else -> action(state.gameState)
            }
            setState(state) {
                val newLoc = state.gameState.location
                if (newLoc != prevLoc) {
                    val autoCommands = repository.locations[newLoc]?.autoCommands
                    if (autoCommands != null && autoCommands.isNotEmpty()) {
                        clearQueues()
                        commandQueue.addAll(autoCommands)
                    }
                }
                processActionQueue() }
            window.localStorage.setItem("state", state.gameState.serialize())
            if (DEBUG) {
                console.log(state.gameState.serialize())
            }
        }
        processCommandQueue()
    }

    private fun clearQueues() {
        actionQueue.clear()
        commandQueue.clear()
    }

    private fun closeMessage() {
        state.message = null
        setState(state) { processActionQueue() }
    }

    private fun onKeydown(e: KeyboardEvent) {
        if (e.key == "Escape") {
            setState {
                message = null
                explanation = null
                shownTask = null
            }
        }
    }
}


fun RBuilder.message(message: ReactElement, close: (() -> Unit)) =
    div(classes = "overlay") {
        attrs {
            onClickFunction = { close() }
        }
        div(classes = "bQ-message") {
            closeButton(close)
            child(message)
        }
    }


fun RBuilder.explanation(explanation: ReactElement, close: (() -> Unit)) =
    div(classes = "overlay") {
        attrs {
            onClickFunction = { close() }
        }
        div(classes = "bQ-explanation") {
            closeButton(close)
            child(explanation)
        }
    }


fun RBuilder.closeButton(close: (() -> Unit)) =
    button(type = ButtonType.button, classes = "close") {
        attrs {
            onClickFunction = { close() }
        }
        attrs["data-dismiss"] = "alert"
        attrs["aria-hidden"] = true
        +"×"
    }
