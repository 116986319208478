val TXX_XX_01_lokvanji = Task("TXX_XX_01_lokvanji", "Lokvanji",

description = """<p>Na nekem jezeru so rastli lokvanji: množili so se tako hitro, da so vsak dan
    pokrivali dvakrat večjo površino jezera kot prejšnji dan. Že po
    <span id="question"></span> dnevih je bilo jezero popolnoma pokrito z njimi.
    </p>

<p>
    Po koliko dneh je bilo jezero pokrito napol?
</p>

<input id="answer" type="text" class="form-control"
       style="width: 200px" placeholder="Vnesi odgovor" />

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var question;
    var ans_obj;

    function puzzleLoad() {
        question = 2 * _.random(10, 50);
        $('#question').html(question);

        ans_obj = $("#answer");
        ans_obj.bind("enterKey", submitSolution);
        ans_obj.keyup(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });
    }

    function submitSolution() {
        var answer = ans_obj.val();
        if (isNaN(answer)) {
            ans_obj.val('');
            return;
        }
        bQ.checkSolution(question + ' ' + answer);
    }

    bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    try {
        val (question, answer) = solution.split(' ')
        answer.trim().toInt() == question.trim().toInt() - 1
    } catch (e: Exception) { // if user typed something funny
        false
    }
},

explanation="""
    <p>Če se pokrita površina vsak dan podvoji, je bilo jezero napol pokrito
    predzadnji dan. V zadnjem dnevu se površina podvoji: če je bilo prej
    pokritega pol jezera, bo zdaj celo.</p>

    <p>Takšni rasti pravimo <em>eksponentna rast</em>. <!-- Ljudje si težko
    predstavljamo, kako hitra je. Neka stara naloga je spraševala, kako z listom
    papirja priti na Luno. Takole: recimo, da je debel šestnajstinko milimetra.
    Prepognemo ga na pol in dvojni list bo debel osminko. Ponovno na pol; dobimo
    četverni list, ki je debel četrtinko milimetra. Če ga ponovno prepognemo,
    bo debel pol milimetra in po štirih prepogibih bo debel milimeter.</p>

    <p>Ko ga prepognemo petič, bo debel dva milimetra. Šestič: štiri. Sedmič: osem
    milimetrov (skoraj centimeter!). Osmič bomo dosegli 16 mm, devetič 32 mm,
    desetič 64 mm, enajstič 128 mm (dobrih deset centimetrov!), dvanajstič 256
    mm, trinajstič 512 mm in štirinajstič 1024 mm, to je, približno meter.</p>

    <p>Tako nadaljujemo. Odgovor na nalogo (ki je pravzaprav nekakšna šala) je
    torej takšen: z listom papirja pridemo na Luno tako, da ga TODO !!!!!!
    prepognemo in stopimo nanj.</p>

    <p>To je seveda nemogoče zato, ker list težko tolikokrat prepogneš, ker bi moral
    stopiti precej visoko in ker bi bil list potem precej tanek in ne bi imel
    kam stopiti.</p>

    <p>-->Eksponentna rast je zelo pomembna v računalništvu. Veliko nalog je takšnih,
    da se težavnost njihovega reševanja (recimo čas, ki ga potrebujemo za
    reševanje) podvoji z vsakim dodatnim elementom. Že naloga s prenašanjem
    hlodov je takšna: če bi hoteli vedno poiskati takšno rešitev, pri kateri
    bi bili prepričani, da smo zaslužili, kolikor je najvež možno, bi se čas
    reševanja podvojil z vsakim dodatnim hlodom. Za takšne probleme tudi z
    računalnikom ne uspemo vedno najti najboljše rešitve: celo če bi nam nekdo
    dal dvakrat hitrejši računalnik, bi ta zmogel le en hlod več. Za dva hloda
    več bi potrebovali že štirikrat hitrejši računalnik...</p>
"""
)
