val T10_XX_17_strategija = Task("T10_XX_17_strategija", "Strategija",

description = """<p>V igri »potuješ« po »drevesu« na desni. Začneš na vrhu. Izbral si boš eno od
    štirih poti, kar (razen, če izbereš pot 2), privede na novo razpotje. Na
    njem bo možakar spet izbral eno od poti. Nato spet izbiraš ti, spet on...
    dokler ne prideta do konca. Če vaju tam čaka nasmejan obraz, zmagaš ti,
    sicer on.</p>

<p>Katero pot moraš izbrati na začetku, da boš lahko s pametno igro zagotovo
    zmagal?</p>

<style>
    #s1:hover, #s2:hover, #s3:hover, #s4:hover, #s8:hover, #s9:hover {
        stroke: yellow;
    }
</style>

<div class="center-block" style="position:relative;width:459px;height:499px">
	<div id="svgload" style="position:relative;width:459px;height:499px">
	</div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T10_XX_17_strategija/drevo.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
        var i;
        for(i = 1; i <= 4; i++) {
            $('#s' + i).click(pathChosen);
            $('#c' + i).click(pathChosen);
        }
        for(i = 5; i <= 10; i++) {
            $('#s' + i).css('display', 'none');
        }
        for(i = 1; i <= 5; i++) {
            $('#f' + i).css('display', 'none');
        }
 	}

    function pathChosen() {
        var i, f, t;
        var move = parseInt($(this).attr('id').slice(-1));
        $('#s' + move).css('stroke', '#FDF400');
        if (move <= 4) {
            for(i = 1; i <= 4; i++) {
                if (i != move) {
                    $('#s' + i).fadeOut(200);
                }
            }
        }
        setTimeout(function() {
            switch (move) {
                case 1: $('#s5').fadeIn(400,
                            function() {
                                return submitSolution('1', '#f2');
                            });
                        break;
                case 2: submitSolution('2', '#f1');
                        break;
                case 3: $('#s6').fadeIn(400,
                            function() {
                                $('#s8').fadeIn().click(pathChosen);
                                $('#s9').fadeIn().click(pathChosen);
                            });
                        break;
                case 4: $('#s7').fadeIn(400,
                            function() {
                                submitSolution('4', '#f3');
                            });
                        break;
                case 8: $('#s9').fadeOut(100);
                        $('#s10').fadeIn(400,
                            function() {
                                submitSolution('38', '#f5');
                            });
                        break;
                case 9: $('#s8').fadeOut(100);
                        submitSolution('39', '#f4');
                        break;
            }
        }, 1000);
    }


	function submitSolution(s, f) {
        $(f).fadeIn(1000,
                    function() {
                        setTimeout( function() { bQ.checkSolution(s); }, 2000);
                    });
    }

	bQ.register(puzzleLoad);
}};
"""),

checkSolution = { it == "39" },

explanation="""
    <img style="float: left; margin-right: 10px; border-radius: 10px; padding: 10px; background-color: white"
    src="tasks/T10_XX_17_strategija/explanation.png"/>
    
    <p>Če v prvi potezi izbereš skrajno levo ali skrajno desno pot, boš v
    naslednji potezi zagotovo izgubil. Še bolj nesmiselna je druga pot z leve, saj
    v tem primeru izgubiš takoj. V prvi potezi torej izbereš tretjo pot.
    Delavec bo izbral levo. V naslednji potezi boš lahko zmagal.</p>
    
    <p>Ko računalnik igra različne igre proti tebi, na podoben način preučuje vse
    možne poteze. Če je igra zapletena - recimo, ko igramo šah - je drevo preveliko,
    da bi ga lahko pregledal do konca. Zato namesto opazovanja zmag in porazov
    ocenjuje, kako dober položaj ga čaka po določenem številu potez (šahovski
    programi navadno razmišljajo za kakih 15 potez naprej).</p>"""
)
