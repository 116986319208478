val T12_AT_01_pomesane_slike = Task("T12_AT_01_pomesane_slike", "Pomešane slike",

description = """

<p>Pri likovnem pouku so bobri dobili rdeč list papirja, prekrivne barve in po
    štiri kartice z navodili za risanje:
<ol>
    <li>Pobarvaj spodnji del papirja modro.</li>
    <li>Obrni papir za 180 stopinj.</li>
    <li>Pobarvaj spodnjo polovico zeleno.</li>
    <li>Nariši krog desno zgoraj.</li>
</ol>

<p>Zveni enostavno, vendar se je skoraj vsak otrok zmotil na svoj izviren način.
Petru, recimo, so se raztresle kartice po tleh in pobral jih je v napačnem
vrstnem redu: 3 – 1 – 2 – 4.</p>

<p>Slike so dali sušiti in med njimi Peter zdaj išče svojo. Katera je?</p>

<div class="center-block" style="position:relative;width:686px;height:453px">
	<div id="svgload" style="position:relative;width:686px;height:453px">
	</div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	var puzzle_solution = "";

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T12_AT_01_pomesane_slike/pomesane_slike.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
		for(var i = 1; i <= 24; i++) {
            var id = "#g" + i;
			$(id).css("cursor","pointer");
            $(id).attr("data-id", i.toString());
			$(id).click(function(){
				puzzleClick($(this).attr("data-id"));
			});
		}
	}

	function puzzleClick(id) {
   		bQ.checkSolution(id);
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "2" },
    explanation="""<p>Vrstni red, po katerem je risal Peter, je takšen:</p>
<ol>
    <li>Pobarvaj spodnjo polovico zeleno.</li>
    <li>Pobarvaj spodnji del papirja modro.</li>
    <li>Obrni papir za 180 stopinj.</li>
    <li>Nariši krog desno zgoraj.</li>
</ol>

<p>Pridelal je tole:</p>

<div style="text-align: center; margin-bottom: 20px">
    <img src="tasks/T12_AT_01_pomesane_slike/risanje.png"/>
</div>

<p>Ko so otroci risali sliko, so sledili preprostemu programu v štirih korakih.
Vrstni red korakov je, očitno, pomemben, sicer "program" ne naredi tega, kar
bi moral.</p>
"""
)
