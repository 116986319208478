val T10_LT_05_fotografije = Task("T10_LT_05_fotografije", "Urejanje fotografij",

description = """

<div>
<p>Skavtski fotograf se je zgodaj zjutraj sprehodil okrog jezera.</p>

<img src="tasks/T10_LT_05_fotografije/2010-LT-05_pond.png"
     style="margin:20px" class="center-block">

<p>Naredil je štiri fotografije. Zloži jih v pravi vrstni red.
(Fotografije preurejaš tako, da jih povlečeš na ustrezno mesto.)</p>

<div id="photos"  class="center-block" style="width: 245px">
    <div id="p1">
        <img src="tasks/T10_LT_05_fotografije/photo_1.png" style="margin:5px">
    </div>
    <div id="p2">
        <img src="tasks/T10_LT_05_fotografije/photo_2.png" style="margin:5px">
    </div>
    <div id="p3">
        <img src="tasks/T10_LT_05_fotografije/photo_3.png" style="margin:5px">
    </div>
    <div id="p4">
        <img src="tasks/T10_LT_05_fotografije/photo_4.png" style="margin:5px">
    </div>

<button id="oddaj" class="btn btn-default">
    Urejeno!
</button>
</div>


</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

  	function puzzleLoad() {
        $("#photos").sortable();
   		$("#oddaj").click(function () {
            var solution = $("#photos").sortable("toArray").slice(0, 4).toString();
            bQ.checkSolution(solution);
        });
	}

    bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "p1,p4,p3,p2" },

explanation="""
    <p>Poznaš kako igro, v kateri hodiš po svetu in ti računalnik kaže sliko, kot
    bi jo videl z lastnimi očmi, če bi stal tam? Takrat računalnik počne nekaj
    podobnega kot ti, ko si v tej nalogi mislih hodil okrog jezera: ve, kje stoji
    posamezna reč, sestaviti pa mora takšno sliko, kot jo vidi nekdo, ki gleda z
    določenega mesta.</p>
    
    <p>Računalniki pogosto počnejo tudi obratno: damo jim nekaj fotografij, posnetih
    z različnih strani, računalnik pa mora iz njih razbrati, kako so stvari, ki so
    na fotografijah, postavljene v resničnem svetu.</p>"""
)
