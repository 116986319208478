typealias ItemId = String

class HasItem(val itemId: ItemId, message: String = NoMessage): Condition(message) {
    override fun verify(gameState: GameState) = itemId in gameState.inventory
}


class GetItem(val itemId: ItemId): Action() {
    override operator fun invoke(state: GameState) {
        state.inventory.add(itemId)
    }
}

class DropItem(val itemId: ItemId): Action() {
    override operator fun invoke(state: GameState) {
        state.inventory.remove(itemId)
    }
}

