val TXX_XX_04_zmesaj_zdravilo = Task("TXX_XX_04_zmesaj_zdravilo", "Zmešaj zdravilo",

description = """<style>
    .ingredient {
        margin-right: 15px;
    }
</style>
<p>Pred tabo so poličke polne malih stekleničk. Na mizi je majhen kotliček
    za mešanje zdravil. Kaj bosta namešala?</p>

<p id="ingredients">
<button id="PRISALNICA" class="ingredient">PRISALNICA</button>
<button id="ROGOBENA" class="ingredient">ROGOBENA</button>
<button id="PRITANDIJA" class="ingredient">PRITANDIJA</button>
<button id="OFRONDIJA" class="ingredient">OFRONDIJA</button>
<button id="LIMARGANA" class="ingredient">LIMARGANA</button>
<button id="AFTERNIKA" class="ingredient">AFTERNIKA</button>
<button id="PROBONCIJ" class="ingredient">PROBONCIJ</button>
<button id="KOMLAVEC" class="ingredient">KOMLAVEC</button>
<button id="ELOKIMONIA" class="ingredient">ELOKIMONIA</button>
<button id="TRIPOVENICA" class="ingredient">TRIPOVENICA</button>
<button id="ORMOZNIK" class="ingredient">ORMOZNIK</button>
<button id="DODUNIA" class="ingredient">DODUNIA</button>
<button id="ALOTENIJA" class="ingredient">ALOTENIJA</button>
<button id="HALOMINTER" class="ingredient">HALOMINTER</button>
<button id="RABOVOTEN" class="ingredient">RABOVOTEN</button>
<button id="PREITONCIJ" class="ingredient">PREITONCIJ</button>
<button id="SLETOVINIJA" class="ingredient">SLETOVINIJA</button>
<button id="TRESPANIJ" class="ingredient">TRESPANIJ</button>
<button id="OKTIRINA" class="ingredient">OKTIRINA</button>
<button id="PORTAIN" class="ingredient">PORTAIN</button>
</p>

<p>Vsebina kotlička: <span id="cauldron"></span></p>

<button id="done" class="btn btn-default">
    <b>Končano</b></button>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
	function puzzleLoad() {
        $('.ingredient').click(addToCauldron);
        $('#done').click(checkSolution);
	}

    function addToCauldron() {
        $('#cauldron').append($(this).text() + ' ');
        $(this).hide();
    }

	function checkSolution() {
		bQ.checkSolution($('#cauldron').text());
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it.trim().split(' ').toSet() == setOf("ELOKIMONIA", "PRITANDIJA", "RABOVOTEN")},

explanation="""
    <p>Da odkriješ sestavine, ki jih moraš namešati, moraš razvozlati napis v
    knjigi:</p>

    <p style="text-align: center"><em>EB EPCJT ŽESBZJMP, LJ PŽESBZJ ZŠBLP
    CPMFŽFO, NPSBT EPCSP RSFNFTBUJ FMPLJNPOJP, RSJUBOEJKP JO DJŠUP
    NBMP SBCPZPUFOB</em></p>
    
    <p>V pomoč si imel napis "DBSPCOJ OBRPKJ", za katero se ti zdi, da pomeni
    "ČAROBNI NAPOJI". Obakrat gre za dve besedi in enako črk imata. Torej Č zapišemo
    kot D, A kot B, R kot S ... Očitno vsako črko zamenjamo z naslednjo črko v
    abecedi. Da preberemo skrivni napis, moramo vsako njegovo črko zamenjati s
    prejšnjo.</p>
    
    <p>Temu načinu skrivnega pisanja pravimo Cezarjeva šifra, ker naj bi jo
    uporabljal Julij Cezar. Šifro lahko dodatno zapletemo tako, da namesto
    naslednjega znaka jemljemo po več znakov naprej (ali nazaj). A tudi tako ta
    način skrivnega pisanja ni prav dober. Če želimo prebrati napis, lahko preprosto
    preskusimo vse možne zamike.</p>
    
    <p>Bi lahko nalogo rešil tudi z ugibanjem? Težko. Za začetek ti naloga ni
        povedala, koliko sestavin je potrebno zmešati. Recimo, da bi uganil, da
    jih je nekje med 2 in 5. Vseh sestavin je 20. Iz njih lahko narediš 190
    različnih parov, 1140 trojk, 4845 četverk in 15504 peterk. Kar brezupno, ne?</p>
    """
)
