val T11_NL_11_cudni_postar = Task("T11_NL_11_cudni_postar", "Čudni poštar",

description = """
    <div style="margin-top: 15px; margin-left: 20px; margin-right: 20px; text-align: center; float:right">
    <div id="cards" style="
        padding-left: 30px; padding-right: 30px; padding-top: 10px; padding-bottom: 10px;
        margin-bottom: 20px; background-color:#ded2c5; "></div>
    <div><button id="done_button" class="btn btn-default">
        <b>Končano</b>
    </button>
        </div>
</div>

<div>
<p>Čudni poštar deli pošto tako, da ne gre po ulici po vrsti, temveč vedno
    k hiši, ki ima vsaj eno števko skupno s hišo, pred katero je trenutno. Tako
    lahko gre od hiše 23 k hiši 28, 31 ali 72, ne pa tudi k hiši 19, saj
    številka 19 nima ne števke 2 ne števke 3. K nobeni hiši ne gre dvakrat.</p>

<p>Danes je čisto obupan, saj mora nesti pošto k hišam s številkami
    <span id="stevilke"></span>. Zdi se mu,
    da mu ne bo uspelo. Mu lahko pomagaš razporediti pisma v primeren vrstni red?</p>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    function construct_puzzle() {
        var num = ["GH", "G0", "GI", "1B", "1C", "10B", "CD", "I0", "DE", "EF"];
        var conv = _.object("01BCDEFGHI", "01" + _.shuffle("23456789".split("")).join(""));
        num = _.map(num, function (x) {
            return _.map(x, function(y) {
                return conv[y];
            }).join("");
        });
        num = _.sortBy(num, function(x) { return parseInt(x, 10)} );
        $("#stevilke").html(num.slice(0, -1).join(", ") + " in " + num[9]);

        for(var i = 0; i < 10; i++) {
            var newLetter = '<div id="l' + i + '" class="card" data-seq="0" style="width:85px;height:58px;color:blue;font-size:30px;padding-top: 12px;text-align:center;margin-top:0px;margin:5px;background-image:url(\'tasks/T11_NL_11_cudni_postar/pismo.png\')">'+num[i]+'</div>';
            $("#cards").append(newLetter);
        }

        $("#cards").sortable();
        $("#cards").disableSelection();

        $("#done_button").click(puzzleSubmit);
    }

	function puzzleSubmit(){
		var solution = _.map($(".card"), function(x) { return x.innerHTML; }).join(" ");
		bQ.checkSolution(solution);
	}

    bQ.register(construct_puzzle);

}};
"""),

checkSolution = {
    it.split(' ')
        .map { it.toSet() }
        .zipWithNext()
        .all { it.first.intersect(it.second).isNotEmpty() }
},
    explanation="""<p>Številke hiš v nalogi so vsakič drugačne. Videti so lahko, recimo, takole:
<em>13, 17, 20, 24, 26, 40, 58, 75, 89 in 103.</em></p>

<p>Iz njih narišimo takšno sliko.</p>

<div style="margin-bottom: 20px; text-align: center">
    <img src="tasks/T11_NL_11_cudni_postar/explanation.png"/>
</div>

<p>Dve številki smo povezali, če imata kako skupno števko in gre poštar lahko od
    ene do druge. Če nalogo prerišemo v to obliko, postane povsem preprosta:
poiskati moramo pot, ki gre prek vsake številke natančno enkrat. Začela (ali
končala) se bo z 89. Nadaljevanje bo, očitno 58, 75, 17, 13, 103 - če bi šli s
17 na 103, namreč ne bi mogli obiskati 13, nato pa priti v desni del. Končamo
lahko na različne načine; eden je, da s 103 nadaljujemo po poti 40, 24, 20, 26.
</p>

<p>Takšni sliki pravimo - kot si morda izvedel že ob kakšni drugi nalogi - graf.
Poti prek grafa, ki jo iščeš, pravimo Hamiltonova pot, po slavnem matematiku
Williamu Rowanu Hamiltonu, ki se je ukvarjal s tem in podobnimi problemi.</p>

<p>Ob reševanju naloge si se, upamo, naučil, kako lahke postanejo naloge, če jih
predelaš - in po možnosti narišeš - v nazornejši obliki.</p>
"""
)
