val T09_XX_05_odkod_hrup = Task("T09_XX_05_odkod_hrup", "Odkod hrup",

description = """

<div style="float:right">
    <img src="tasks/T09_XX_05_odkod_hrup/mikrofoni.png" style="margin-left: 20px;">
</div>

<p>V parku so postavljene tri velike opazovalnice z mikrofoni. Na zemljevidu
so označene z A, B in C. Vse tri so zaznale hrup, a ob različnih časih, kot kaže
    slika na desni. Do tega
je prišlo zato, ker niso vsi mikrofoni enako oddaljeni od izvora hrupa, zato
zvok potrebuje različno dolgo, da pride do posameznega mikrofona.</p>

<p>Nadzornik sumi, da se nekaj hrupnega dogaja na eni od lokacij označenih s
    križcem. Na kateri?</p>

<div class="center-block" style="width:400px;height:404px;">
	<div id="svgload" style="width:400px;height:404px;"></div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T09_XX_05_odkod_hrup/park.svg',
			settings: {},
			onLoad: puzzleInit
		});

	}

	function puzzleInit() {
		for(var i = 1; i <= 7; i++) {
            $('#g-' + i).click(submitSolution);
        }
    }

    function submitSolution() {
        bQ.checkSolution($(this).attr('id'));
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "g-7" },

explanation="""
    <div style="margin-bottom: 20px; ">
    <img src="tasks/T09_XX_05_odkod_hrup/mikrofoni.png">
    <img src="tasks/T09_XX_05_odkod_hrup/park.svg" style="margin-left: 30px; height: 233px">
    </div>
    
    <p>Hrup je najprej zaznal mikrofon C, nato A in potem B. Iščemo torej točko,
        ki je najbližje C-ju, malo bolj je oddaljena od mikrofona A, najbolj pa od
        mikrofona B.</p>
    
    <p>V poštev prideta le točki ob levem robu: vse ostale so bližje B-ju kot A-ju,
    razen spodnje, ki pa je predaleč od C-ja.</p>
    
    <p>Med levima točkama hitro odkrijemo, da je prava zgornja, saj je spodnja
    najbližja A-ju, ne pa C-ju.</p>"""
)
