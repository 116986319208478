import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.*
import org.w3c.dom.HTMLSelectElement
import org.w3c.files.*
import react.dom.*
import react.*
import styled.*

@JsModule("file-saver")
@JsNonModule
external fun saveAs(blob:Blob, name: String)


interface SettingsProps : RProps {
    var state: AppState
    var toGamePage: () -> Unit
}

class Settings(props: SettingsProps): RComponent<SettingsProps, RState>(props) {
    private var optionRef: HTMLSelectElement? = null

    override fun RBuilder.render() {

        styledDiv {
            attrs["id"] = "bQ-settings"
            css {
                paddingRight = 70.px
            }

            h2 { +"Shranjevanje igre"}

            p {
                +"""Če želiš shraniti trenutno stanje igre vnesi ime datoteke in klikni shrani."""
            }
            div(classes = "input-group mb-3") {
                input(classes = "form-control") {
                    attrs {
                        placeholder = "bobrova-pustolovscina"
                    }
                    attrs["aria-label"] = "Ime datoteke"
                    attrs["aria-describedby"] = "button-save"
                }
                div(classes = "input-group-append") {
                    button(type = ButtonType.button) {
                        attrs["id"] = "button-save"
                        attrs {
                            onClickFunction = {
                                val blob = Blob(
                                    arrayOf(props.state.gameState.serialize()),
                                    BlobPropertyBag("text/plain;charset=utf-8")
                                )
                                saveAs(blob, "bobrova-pustolovscina.b")
                            }
                        }
                        +"Shrani igro"
                    }
                }
            }

            p {
                +"Bi rad naložil, naložila shranjeno igro?"
            }

            div(classes = "custom-file") {
                input(type = InputType.file, classes = "custom-file-input") {
                    attrs["id"] = "customFile"
                    attrs["accept"] = ".b"
                    attrs {
                        onChangeFunction = { e: dynamic ->
                            val reader = FileReader()
                            reader.onload = { b: dynamic ->
                                props.state.gameState.deserialize(b.target.result)
                                props.toGamePage()
                            }
                            reader.readAsText(e.target.files[0])
                        }
                    }
                }

                label(classes = "custom-file-label") {
                    attrs["htmlFor"] = "customFile"
                    +"Izberi datoteko"
                }
            }

            styledP {
                css {
                    marginTop = 20.px
                }
                +"Ali pa želiš začeti igrati od začetka?"
            }
            button(type = ButtonType.button) {
                attrs {
                    onClickFunction = {
                        props.state.gameState.reset()
                        props.toGamePage()
                    }
                }
                attrs["id"] = "button-restart"
                +"Začni od začetka"
            }

            h2 {
                +"O igri"
            }

            p {
                +"""Igra povezuje naloge iz tekmovanja Bober v zgodbo, v kateri mora mali bobrček
                rešiti svojo vas. Pri njeni izdelavi so sodelovali"""
            }
            ul {
                li  { +"Aleksander Bešir (programiranje nalog)" }
                li  { +"Anže Starič (programiranje igre)" }
                li  { +"Janez Demšar (scenarij, programiranje igre in nalog)" }
                li  { +"Katja Zupančič (oblikovanje)" }
                li  { +"Manca Zaviršek (scenarij igre)" }
                li  { +"Matevž Černe (scenarij igre)" }
                li  { +"Žiga Stopinšek (programiranje igre)" }
            }
            p { +"""Sestavljalci nalog so iz različnih držav, ki organizirajo
                    tekmovanje Bober. Večina nalog je vsaj nekoliko predelanih."""}

            if (DEBUG) {
                h2 {
                    +"Razvoj"
                }
                val locations = repository.locations.values.toList()
                styledP {
                    css { marginTop = 20.px }
                    +"Skoči na lokacijo"
                }
                select {
                    ref { optionRef = it }
                    attrs {
                        onChangeFunction = {
                            optionRef?.selectedIndex?.let { idx -> props.state.gameState.location = locations[idx].id }
                        }
                    }
                    locations.forEach {
                        option() { +it.title }
                    }
                }

                styledP {
                    css { marginTop = 20.px }
                    +"Opravljene naloge"
                }
                val tasks = repository.tasks.values.toList()
                val solvedTasks = props.state.gameState.solvedTasks
                styledDiv {
                    css {
                        height = 200.px
                        border = "thin solid black"
                        overflow = Overflow.scroll
                        padding = "8px 8px 8px 8px"
                    }
                    tasks.forEach { task ->
                        p {
                            input(InputType.checkBox, name = task.title) {
                                if (task.id in solvedTasks) {
                                    attrs {
                                        checked = true
                                    }
                                }
                                attrs {
                                    onChangeFunction = {
                                        if (task.id in solvedTasks) {
                                            solvedTasks.remove(task.id)
                                        } else {
                                            solvedTasks.add(task.id)
                                        }
                                    }
                                }
                            }
                            +" ${task.title}"
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.settings(state: AppState, toGamePage: () -> Unit) =
    child(Settings::class) {
        attrs.state = state
        attrs.toGamePage = toGamePage
    }