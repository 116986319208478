val T13_SI_09_vrtavka = Task("T13_SI_09_vrtavka", "Črviva vrtavka",

description = """

<p>Preden odideš iz bolnice, moraš dokazati, da ti ni več slabo. Preskus je
preprost: če lahko rešiš tole nalogo, ne da bi se ti zvrtelo v glavi in ti
postalo slabo, si zdrav.</p>

<p>Kako moraš vrteti vrtavko levo in desno, da pripelješ kroglico, ki je v
    sredini, ven?</p>

<div id="puzzleCont" class="center-block" style="width:394px;height:562px;border:5px solid white;border-radius: 10px;background-color:white;color:black;margin-bottom:5px;">
	<div id="svgload" style="position:absolute;width:384px;height:552px;" />
</div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {
	
	var puzzle_solution = "";

	var conns = [
		/* id    [[ x , y ],[d,l,u,r]] */
		/* 01 */ [[290,329],[0,2,0,19]],
		/* 02 */ [[140,328],[10,0,3,19]],
		/* 03 */ [[140,251],[10,4,0,5]],
		/* 04 */ [[89,251], [0,0,0,5]],
		/* 05 */ [[225,250],[6,4,7,0]],
		/* 06 */ [[225,290],[0,0,7,0]],
		/* 07 */ [[225,156],[6,8,0,9]],
		/* 08 */ [[143,156],[0,0,0,9]],
		/* 09 */ [[280,156],[0,8,0,0]],
		/* 10 */ [[140,435],[0,11,3,12]],
		/* 11 */ [[96,435], [0,0,0,12]],
		/* 12 */ [[238,435],[14,11,13,0]],
		/* 13 */ [[238,387],[14,0,0,0]],
		/* 14 */ [[238,484],[0,15,13,16]],
		/* 15 */ [[167,484],[0,0,0,16]],
		/* 16 */ [[303,484],[18,15,17,0]],
		/* 17 */ [[302,443],[18,0,0,0]],
		/* 18 */ [[302,652],[0,0,17,0]],
		/* 19 */ [[414,328],[20,2,23,0]],
		/* 20 */ [[414,409],[0,21,23,22]],
		/* 21 */ [[357,409],[0,0,0,22]],
		/* 22 */ [[458,409],[0,21,0,0]],
		/* 23 */ [[414,226],[20,25,0,24]],
		/* 24 */ [[458,226],[0,25,0,0]],
		/* 25 */ [[333,226],[26,0,27,24]],
		/* 26 */ [[333,278],[0,0,27,0]],
		/* 27 */ [[333,166],[26,0,0,0]]	
	];
	
	var puzzleState = {
		orientation: 0,
		nodeId: 1
	}
	
	var puzzleBtn01_h = false;
	var puzzleBtn02_h = false;
	var animationInProgress = false;
	
	function puzzleLoad() {
		
		// Load svg
		$("#svgload").svg({
			loadURL: 'tasks/T13_SI_09_vrtavka/vrtavka2.svg',
			settings: {},
			onLoad: puzzleInit
		});

	}
	
	function puzzleInit(){
	    document.onkeyup = keyup;

		// Register buttons
		$("#puzzleBtn01").click(function(){
			if(!animationInProgress) puzzleRoll(1);
		});
		
		$("#puzzleBtn02").click(function(){
			if(!animationInProgress) puzzleRoll(-1);
		});
		
		// Add button effects
		$("#puzzleBtn01").hover(
			function(){ puzzleBtn01_h = true; puzzleRefreshBtns(); },
			function(){ puzzleBtn01_h = false; puzzleRefreshBtns(); }
		);
		
		$("#puzzleBtn02").hover(
			function(){ puzzleBtn02_h = true; puzzleRefreshBtns(); },
			function(){ puzzleBtn02_h = false; puzzleRefreshBtns(); }
		);
	
	}
	
    function keyup(e) {
        e = e || window.event;
        switch (e.keyCode) {
            case 37: if(!animationInProgress) puzzleRoll(1); break;
            case 39: if(!animationInProgress) puzzleRoll(-1);
            default: return;
        }
        e.preventDefault();
        e.stopPropagation();
    }

	function puzzleRefreshBtns() {
	
		if(puzzleBtn01_h && !animationInProgress) {
			$("#puzzleBtn01 circle").attr("fill","yellow");
			$("#puzzleBtn01").css("cursor","pointer");
		}
		else {
			$("#puzzleBtn01 circle").attr("fill","white");
			$("#puzzleBtn01").css("cursor","default");
		}
		if(puzzleBtn02_h && !animationInProgress) {
			$("#puzzleBtn02 circle").attr("fill","yellow");
			$("#puzzleBtn02").css("cursor","pointer");
		}
		else  {
			$("#puzzleBtn02 circle").attr("fill","white");
			$("#puzzleBtn02").css("cursor","default");
		}
	
	}
	
	function puzzleRoll(dir) {
	
		// Disable buttons
		animationInProgress = true;
		puzzleRefreshBtns();
		
		// Add to solution
		if(dir == 1) puzzle_solution += "L";
		else  puzzle_solution += "D";
	
		var newOrientation = (puzzleState.orientation + dir + 4) % 4;
		var curNode = conns[puzzleState.nodeId-1];
		var newNodeId = curNode[1][newOrientation];
		
		var curA = 360-puzzleState.orientation*90;
		var newA = 360-newOrientation*90;
		var da = (newA-curA);
		if(da == 270) da = -90;
		else if(da == -270) da = 90;
		
		// Animate turntable
		$("#turntable").css("dummy","0");
		$("#turntable").animate({ dummy: 100 },{
			duration:500,
			easing:"swing",
			step: function(n){
				var a = curA + da*(n/100);
				$(this).attr("transform","rotate("+a+",271.145,328.055)");
			}
		});
		
		var curX = curNode[0][0];
		var curY = curNode[0][1];
		
		// Animate ball adjusting
		$("#ball").css("dummy","0");
		$("#ball").animate({ dummy: 100 },{
			duration:500,
			easing:"swing",
			step: function(n){
				var a = curA + da*(n/100);
				$(this).attr("transform","rotate(-"+a+","+curX+","+curY+") translate("+curX+","+curY+")");
			}
		});
		
		if(newNodeId == 0) {
			puzzleAfterRoll(newOrientation,puzzleState.nodeId);
			return;
		}
		
		var newNode = conns[newNodeId-1];
		
		var dx = (newNode[0][0] - curX) / 100.0;
		var dy = (newNode[0][1] - curY) / 100.0;
		
		// Animate ball falling
		$("#ball").css("dummy","0");
		$("#ball").animate({ dummy: 100 },{
			duration:1000,
			easing:"linear",
			step: function(n){
				var f = customEase(n);
				var x = curX + f*dx;
				var y = curY + f*dy;
				$(this).attr("transform","rotate(-"+newA+","+x+","+y+") translate("+x+","+y+")");
			},
			done: function(){
			
				puzzleAfterRoll(newOrientation,newNodeId);
			}
		});
	
	}
	
	function puzzleAfterRoll(newOrientation,newNodeId) {
	
		puzzleState.orientation = newOrientation;
		puzzleState.nodeId = newNodeId;
		
		if(puzzleState.nodeId == 18) puzzleCheckSolution();
		else {
			
			// Re-enable buttons
			animationInProgress = false;
			puzzleRefreshBtns();
			
		}
	
	}
	
	function customEase(t){
		var b = 0;
		var c = 100;
		var d = 100;
        if ((t/=d) < (1/2.75)) {
            return c*(7.5625*t*t) + b;
        } else if (t < (2/2.75)) {
            return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
        } else if (t < (2.5/2.75)) {
            return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
        } else {
            return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
        }
	}
	
	function puzzleCheckSolution() {
	
		bQ.checkSolution(puzzle_solution);
	
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    val conns = """0 2 0 19 
        10 0 3 19 
        10 4 0 5 
        0 0 0 5 
        6 4 7 0 
        0 0 7 0 
        6 8 0 9 
        0 0 0 9 
        0 8 0 0 
        0 11 3 12 
        0 0 0 12 
        14 11 13 0 
        14 0 0 0 
        0 15 13 16 
        0 0 0 16 
        18 15 17 0 
        18 0 0 0 
        0 0 17 0 
        20 2 23 0 
        0 21 23 22 
        0 0 0 22 
        0 21 0 0 
        20 25 0 24 
        0 25 0 0 
        26 0 27 24 
        0 0 27 0 
        26 0 0 0"""
        .split('\n')
        .map { it.trim().split(' ').map { val num = it.toInt(); if (num == 0) null else num } }

    var orientation = 0
    solution.fold(1) { node, move ->
        orientation = (orientation + (if (move == 'L') 1 else 3)) % 4
        conns[node - 1][orientation] ?: node
    } == 18
},
    explanation="""<p>Ob tej nalogi se nam res lahko zvrti v glavi. V resnici pa gre za nekaj, kar
si ob reševanju nalog morda že videl - gotovo pa še boš. Če povezave nekoliko
skrivimo, dobimo drevo. Da spravimo žogo iz vrtavke, moramo le povedati, kam naj
zavije v katerem križišču.</p>

<div style="text-align: center">
    <img style="margin-right: 20px" src="tasks/T13_SI_09_vrtavka/vrtavka-rot.png"/>
    <img src="tasks/T13_SI_09_vrtavka/vrtavka-tree.png"/>
</div>

<p>Rešitev je levo - desno - desno - levo - desno - levo.</p>
"""
)
