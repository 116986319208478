val TXX_XX_02_rodbinsko_drevo = Task("TXX_XX_02_rodbinsko_drevo", "Rodbinsko drevo",

description = """<p>Stari bober pravi, da so vsi ljudje v vasi v sorodu. In on pozna prav vse.</p>
<div id="dialog">
<p><em>
    &mdash; Povej ime osebe in povem ti imena njenih otrok. Prvi, ki se je naselil v
    tej vasi, je bil neki Adam...
</em></p>
</div>

<input id="question" type="text" class="form-control"
       style="width: 200px" placeholder="Ime osebe" />

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var tree = {
        Adam: ['Tone', 'Karel', 'Polde'],
        Tone: ['Alenka', 'Jože'],
        Alenka: [],
        'Jože': [],
        Karel: ['Aleš'],
        'Aleš': ['Jana'],
        Jana: [],
        Polde: ['Peter', 'Mojca', 'Tadej'],
        Peter: [],
        Mojca: ['Tina', 'Miran'],
        Tina: ['Ana'],
        Ana: [],
        Miran: ['Štefan', 'Mateja'],
        'Štefan': [],
        Mateja: [],
        Tadej: ['Primož', 'Marjeta'],
        'Primož': [],
        Marjeta: []
    }

	function puzzleLoad() {
        var answ = $('#question');
        answ.bind("enterKey", showChildren);
        answ.keyup(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });
	}

	function showChildren() {
        var question_elm = $('#question');
        var question = question_elm.val().replace(/^\s+|\s+$/g, '');
        if (!question[0]) {
            return;
        }
        question = question[0].toUpperCase() + question.slice(1);
        question_elm.val('');
        var msg = '<br/>&mdash; ' + question
        if (question == "Nasvidenje") {
            $("#dialog p em").append(msg + '.<br/>&mdash; Nasvidenje!');
            question_elm.hide();
            setTimeout(function() { bQ.checkSolution(""); }, 2000);
            return;
        }
        if (question == "Hvala") {
            $("#dialog p em").append(msg + '.<br/>&mdash; Prosim.');
            return;
        }
        msg += '?<br/>&mdash; ';
        var children = tree[question];
        if (children === undefined) {
            msg += "Nobene osebe z imenom '" + question + "' ne poznam";
        }
        else {
            msg += question + ' ';
            switch (children.length) {
                case 0: msg += 'nima otrok'; break;
                case 1: msg += 'ima otroka z imenom ' + children[0]; break;
                case 2: msg += 'ima otroka z imenoma ' +
                                children[0] + ' in ' + children[1]; break;
                default: msg += 'ima otroke z imeni ' +
                                children.slice(0, -1).join(', ') +
                                 ' in ' + children.slice(-1);
            }
        }
        $("#dialog p em").append(msg + '.');
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { false },

    explanation="""Za kaj gre pri tej nalogi in kako jo reševati, si lahko ogledaš ob rešitvi
naloge "Izberi vodiča" (ko jo boš rešil - če je še nisi)."""
)
