val regionGozd2 = Region(
    "3_gozd",

    Location("0300_pristanek", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa (zobje te že srbijo). Na
        mali čistini ležijo ostanki tvojega padala. Če te občutek za
        orientacijo ne vara, je mesto nedaleč odtod v smeri proti
        zahodu. Lahko pa greš proti reki, ki bi morala biti nekje na
        jugovzhodu.
        Kamorkoli že, gozd je teman in neprehoden. Iskanje poti ti bo
        najbrž vzelo precej časa. Pa še zemljevida nimaš!""",

        "Z" to "0303_gozd",
        "JZ" to "0304_gozd",
        "SV" to "0305_gozd",
        "V" to "0306_gozd"),

    Location("0301_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "S" to "0301_gozd",
        "J" to "0304_gozd"),

    Location("0302_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "S" to "0301_gozd",
        "Z" to "0304_gozd",
        "J" to "0303_gozd",
        "V" to "0303_gozd"),

    Location("0303_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "Z" to "0302_gozd",
        "J" to "0304_gozd",
        "V" to "0300_pristanek"),

    Location("0304_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "S" to "0302_gozd",
        "SV" to "0300_pristanek"),

    Location("0305_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "J" to "0300_pristanek"),

    Location("0306_gozd", null, "Gozd", """
        Povsod okrog tebe so visoka drevesa.""",

        "S" to "0305_gozd",
        "Z" to "0300_pristanek",
        "J" to "0300_pristanek",
        "SV" to "0307_soteska",
        "V" to "0308_soteska"),

    Location("0307_soteska", null, "Soteska", """
        Zašel si v nekakšno sotesko.""",

        "J" to "0308_soteska",
        "V" to "0309_soteska"),

    Location("0308_soteska", null, "Soteska", """
        Zašel si v nekakšno sotesko.""",

        "J" to "0306_gozd",
        "Z" to "0306_gozd",
        "S" to "0307_soteska"),

    Location("0309_soteska", null, "Soteska", """
        Zašel si v nekakšno sotesko.""",

        "Z" to "0307_soteska",
        "SZ" to "0307_soteska",
        "SV" to "0310_soteska",
        "V" to "0311_soteska"),

    Location("0310_soteska", null, "Soteska", """
        Soteska se je popolnoma zožala. Proti vzhodu se dviguje
        neprehodno hribovje.""",

        "JZ" to "0309_soteska",
        "J" to "0311_soteska",
        "V" to "0312_konec_soteske"),

    Location("0311_soteska", null, "Soteska", """
        Soteska se je začela ožati in gozd redčiti. Videti je, da bo
        doline kmalu konec. Tale pot najbrž ne vodi nikamor.""",

        "Z" to "0309_soteska",
        "J" to "0308_soteska",
        "S" to "0310_soteska"),

    Location("0312_konec_soteske", null, "Konec soteske", { state ->
        "Prišel si do vznožja neprehodnega skalnega gorovja." +
        if (FlagNotSet("odmaknil veje").verify(state))
        """Pred skalno steno je kup vej. To se ti zdi nekoliko sumljivo: kaj počne kup vej,
        ki jih je očitno nekdo naložil tja, v gozdu, ki je videti, kot da vanj že
        desetletja ni stopila bobrova noga?"""
        else "V skalni steni je videti vrata. Ampak kam?"},

        "SZ" to "0310_soteska",
        "Z" to "0310_soteska",
        "JZ" to "0311_soteska",

        commands = Commands(
            Command("Odmakni veje",
                    showOn=FlagNotSet("odmaknil veje"),
                    action=SetFlag("odmaknil veje")
            ),
            StartTask("Oglej si vrata", "T09_XX_22_ujemi_barvo",
                 showOn=And(FlagIsSet("odmaknil veje"), Not(HasSolved("T09_XX_22_ujemi_barvo"))),
                 onSuccessActions = Actions(Message("Vrata so se odprla!")),
                 onFailureActions = Actions(Message("Vrata se niso niti premaknila."))
            ),
            Command("Vstopi",
                 showOn=HasSolved("T09_XX_22_ujemi_barvo"),
                 action=MoveTo("0400_labirint")
            )
        )
    ),

    hasMap = false
)

