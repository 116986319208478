import kotlinx.html.*
import kotlinx.html.attributes.*
import react.*
import styled.*


fun Float.floatEncode() = toString()
class FloatEncoder(val nDecimals: Int = 3) : AttributeEncoder<Float> {
    override fun encode(attributeName: String, value : Float): String = value.toString()
    override fun decode(attributeName: String, value: String): Float = value.toFloat()
}

class FloatAttribute(nDecimals: Int = 3) : Attribute<Float>(FloatEncoder(nDecimals))

internal val attributeFloatFloat : Attribute<Float> = FloatAttribute()

@Suppress("unused")
fun RBuilder.circle(cx: Double, cy: Double, r: Double, classes: String? = null, block: (StyledDOMBuilder<CIRCLE>.() -> Unit)? = null): ReactElement =
    styledTag(block ?: { }) {
        CIRCLE(attributesMapOf("cx", cx.toString(), "cy", cy.toString(), "r", r.toString(), "class", classes), it
        )
    }

@Suppress("unused")
fun RBuilder.circle(cx: String, cy: String, r: String, classes: String? = null, block: (StyledDOMBuilder<CIRCLE>.() -> Unit)? = null): ReactElement =
    styledTag(block ?: { }) {
        CIRCLE(attributesMapOf("cx", cx, "cy", cy, "r", r, "class", classes), it)
    }

@Suppress("unused")
open class CIRCLE(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
        HTMLTag("circle", consumer, initialAttributes, null, true, true),
        CommonAttributeGroupFacadeFlowInteractivePhrasingContent {
    var cx : Float
        get()  = attributeFloatFloat.get(this, "cx")
        set(newValue) {
            attributeFloatFloat.set(this, "cx", newValue)}

    var cy : Float
        get()  = attributeFloatFloat.get(this, "cy")
        set(newValue) {
            attributeFloatFloat.set(this, "cy", newValue)}

    var r : Float
        get()  = attributeFloatFloat.get(this, "r")
        set(newValue) {
            attributeFloatFloat.set(this, "r", newValue)}
}

@Suppress("unused")
fun RBuilder.ellipse(cx: Double, cy: Double, rx: Double, ry: Double, classes: String? = null, block: (StyledDOMBuilder<ELLIPSE>.() -> Unit)? = null): ReactElement =
    styledTag(block ?: { }) {
        ELLIPSE(
            attributesMapOf(
                "cx",
                cx.toString(),
                "cy",
                cy.toString(),
                "rx",
                rx.toString(),
                "ry",
                ry.toString(),
                "class",
                classes
            ), it
        )
    }

@Suppress("unused")
fun RBuilder.ellipse(cx: String, cy: String, rx: String, ry: String, classes: String? = null, block: (StyledDOMBuilder<ELLIPSE>.() -> Unit)? = null): ReactElement =
    styledTag(block ?: { }) {
        ELLIPSE(attributesMapOf("cx", cx, "cy", cy, "rx", rx, "ry", ry, "class", classes), it)
    }

@Suppress("unused")
open class ELLIPSE(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
        HTMLTag("ellipse", consumer, initialAttributes, null, true, true),
        CommonAttributeGroupFacadeFlowInteractivePhrasingContent {
    var cx : Float
        get()  = attributeFloatFloat.get(this, "cx")
        set(newValue) {
            attributeFloatFloat.set(this, "cx", newValue)}

    var cy : Float
        get()  = attributeFloatFloat.get(this, "cy")
        set(newValue) {
            attributeFloatFloat.set(this, "cy", newValue)}

    var rx : Float
        get()  = attributeFloatFloat.get(this, "rx")
        set(newValue) {
            attributeFloatFloat.set(this, "rx", newValue)}

    var ry : Float
        get()  = attributeFloatFloat.get(this, "ry")
        set(newValue) {
            attributeFloatFloat.set(this, "ry", newValue)}
}


@Suppress("unused")
inline fun RBuilder.line(x1: Double, y1: Double,x2: Double, y2: Double, classes: String? = null): ReactElement =
    styledTag({ }) {
        LINE(
            attributesMapOf(
                "x1",
                x1.toString(),
                "y1",
                y1.toString(),
                "x2",
                x2.toString(),
                "y2",
                y2.toString(),
                "class",
                classes
            ), it
        )
    }

@Suppress("unused")
inline fun RBuilder.line(x1: String, y1: String,x2: String, y2: String, classes: String? = null): ReactElement =
    styledTag({ }) {
        LINE(attributesMapOf("x1", x1, "y1", y1, "x2", x2, "y2", y2, "class", classes), it)
    }

@Suppress("unused")
open class LINE(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
        HTMLTag("line", consumer, initialAttributes, null, true, true),
        CommonAttributeGroupFacadeFlowInteractivePhrasingContent {
    var x1 : Float
        get()  = attributeFloatFloat.get(this, "x1")
        set(newValue) {
            attributeFloatFloat.set(this, "x1", newValue)}

    var y1 : Float
        get()  = attributeFloatFloat.get(this, "y1")
        set(newValue) {
            attributeFloatFloat.set(this, "y1", newValue)}

    var x2 : Float
        get()  = attributeFloatFloat.get(this, "x2")
        set(newValue) {
            attributeFloatFloat.set(this, "x2", newValue)}

    var y2 : Float
        get()  = attributeFloatFloat.get(this, "y2")
        set(newValue) {
            attributeFloatFloat.set(this, "y2", newValue)}
}


@Suppress("unused")
inline fun RBuilder.g(classes: String? = null, block: StyledDOMBuilder<G>.() -> Unit) =
    styledTag(block) {
        G(attributesMapOf("class", classes), it)
    }

@Suppress("unused")
open class G(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
    HTMLTag("g", consumer, initialAttributes, null, false, false), HtmlBlockTag {
}

@Suppress("unused")
inline fun RBuilder.text(transform: String? = null, classes: String? = null, block: StyledDOMBuilder<TEXT>.() -> Unit) =
    styledTag(block) {
        TEXT(attributesMapOf("transform", transform, "class", classes), it)
    }

@Suppress("unused")
open class TEXT(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
    HTMLTag("text", consumer, initialAttributes, null, false, false), HtmlBlockTag {
}

@Suppress("unused")
inline fun RBuilder.polygon(points: String, classes: String? = null) =
    styledTag({ }) {
        POLYGON(attributesMapOf("points", points, "class", classes), it)
    }

@Suppress("unused")
open class POLYGON(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
    HTMLTag("polygon", consumer, initialAttributes, null, false, false), HtmlBlockTag {
}

@Suppress("unused")
inline fun RBuilder.path(points: String, classes: String? = null) =
    styledTag({ }) {
        PATH(attributesMapOf("d", points, "class", classes), it)
    }

@Suppress("unused")
open class PATH(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>):
    HTMLTag("path", consumer, initialAttributes, null, false, false), HtmlBlockTag {
}
