val T07_XX_1A_zaporedje_stevil = Task("T07_XX_1A_zaporedje_stevil", "Zaporedje števil",

description = """<p>Gospod Collatz si je izmislil takšna zaporedja števil. Začne z nekim
    poljubnim številom. Vsako naslednje število izračuna po naslednjem receptu:
</p>
<ul>
    <li>če je število enako 1, smo končali,</li>
    <li>če je število liho, ga pomnoži s 3 in prištej 1,</li>
    <li>če je sodo, pa ga deli z 2.</li>
</ul>

<p>Če začne z, recimo, 26, dobi zaporedje 26, 13, 40, 20, 10, 5, 16, 8, 4, 2, 1.
    Zaporedje je torej dolgo 11 števil.</p>

<p>Si razumel? Če si, pa napiši zaporedje, ki se začne z 12!</p>

<div style="float: left">
    <input id="answer" type="text" class="form-control"
           style="width: 200px; margin-right: 10px" placeholder="Vnesi zaporedje" />
</div>
<div style="float: left">
    <button id="submit" type="submit" class="btn btn-default"><b>Odgovori</b></button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    function puzzleLoad() {
        $('#submit').click(checkSolution);

        var number = _.random(0, 255);
        var answ = $('#answer');
        answ.bind("enterKey", checkSolution);
        answ.keydown(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });
   	}

    function checkSolution() {
        bQ.checkSolution($('#answer').val());
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{
    var curr = ""
    val answ = mutableListOf<Int>()
    it.forEach { c ->
         if (c in "0123456789") {
             curr += c
         }
         else if (c != ' ' && c != ',') {
             return@chk false
         }
         else if (curr != "") {
             answ.add(curr.toInt())
             curr = ""
         }
    }
    if (curr != "") {
        answ.add(curr.toInt())
    }
    answ == listOf(12, 6, 3, 10, 5, 16, 8, 4, 2, 1)
},

explanation="""
    <p>Naloga je od tebe zahtevala, da razumeš preprosto navodilo in mu znaš
    slediti. Računalniški programi niso nič drugega kot navodila, ki jih morajo
    programerji znati sestaviti in razumeti - sledijo pa jim računalniki.</p>

    <p>Zaporedje, ki si ga sestavljal, pa je tudi prav zanimivo. V resnici si ga je
    izmislil matematik Lothar Collatz. Števila v zaporedju padajo in naraščajo, prej
    ko slej pa se končajo z enico. Vsaj vsa števila, s katerimi so poskusili doslej!
    Matematiki se že skoraj sto let trudijo bodisi najti tako začetno število, da bi
    se z njim dobljeno zaporedje ponavljalo, namesto da se izteče v 1 - ali pa, da
    bi dokazali, da takšnega števila ni. A nič od tega jim ne uspe, zato Collatzovi
    domnevi, da takšnega števila ni, rečejo ... <em>Collatzova domneva</em>.</p>"""
)
