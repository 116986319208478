val T12_AT_11_premikanje_vagonov = Task("T12_AT_11_premikanje_vagonov", "Preurejanje vagonov",

description = """

<p>Vagone s spodnjega tira je potrebno prestaviti na zgornjega, tako, da bodo
   stali tam v enakem vrstnem redu, kot so zdaj. Pri tem lahko uporabljamo
   tudi vmesni tir, na katerem začasno parkiramo kak vagon. Za premikanje je na
   voljo le stara lokomotiva, ki lahko vleče po en vagon naenkrat.</p>

<div id="puzzleCont" class="center-block" style="width:510px;height:181px;border:5px solid white;border-radius: 10px;background-color:white;color:black;margin-bottom:5px;">
	<div id="svgload" style="position:absolute;width:500px;height:171px;">
	</div>
	<svg id="puzzleLayer01" style="width:500px;height:171px;position:absolute;pointer-events:none;">
		<line id="conn" x1="0" y1="0" x2="1" y2="1" style="stroke:rgb(255,255,255);stroke-width:12" />
	</svg>
	<div id="puzzleLayer02" style="width:500px;height:171px;position:absolute;pointer-events:none;">
		<div id="puzzleLocomotive" style="position:absolute;width:80px;height:27px;background-image:url('tasks/T12_AT_11_premikanje_vagonov/locomotive.png');top:73px;left:7px;">
			<div id="puzzleLocomotive_h" style="position:absolute;width:1px;height:1px;top:13px;left:75px;"></div>
		</div>
		<div id="puzzleWagon_r" style="position:absolute;width:67px;height:27px;background-image:url('tasks/T12_AT_11_premikanje_vagonov/wr.png');top:128px;left:293px;">
			<div id="puzzleWagon_r_h" style="position:absolute;width:1px;height:1px;top:13px;left:5px;"></div>
		</div>
		<div id="puzzleWagon_b" style="position:absolute;width:67px;height:27px;background-image:url('tasks/T12_AT_11_premikanje_vagonov/wb.png');top:128px;left:360px;">
			<div id="puzzleWagon_b_h" style="position:absolute;width:1px;height:1px;top:13px;left:5px;"></div>
		</div>
		<div id="puzzleWagon_g" style="position:absolute;width:67px;height:27px;background-image:url('tasks/T12_AT_11_premikanje_vagonov/wg.png');top:128px;left:427px;">
			<div id="puzzleWagon_g_h" style="position:absolute;width:1px;height:1px;top:13px;left:5px;"></div>
		</div>
	</div>
</div>


""",

runTask = eval("""{ run: (bQ) => {

	var puzzle_solution = "";
	var puzzle_state = {
		l: 0,
		r: [ [], [], [], ["g","b","r"] ]
	}
	var puzzle_track = 1;
	var puzzle_wagon = "";
    var moving = 0;

	function puzzleLoad() {

		// Load svg
		$("#svgload").svg({
			loadURL: 'tasks/T12_AT_11_premikanje_vagonov/rails.svg',
			settings: {},
			onLoad: puzzleInit
		});

	}

	function puzzleInit(){

		// Register buttons
		$("#kretnica").click(function(){
            if (!moving & (puzzle_state.l == 0)) {
                puzzle_solution += "c";
                puzzle_track = (puzzle_track+1)%3;
                $("#kretnica").attr("transform","translate(300,50) scale(2) rotate("+puzzle_track*45+",0,0) translate(-314.727,-73.075)");
            }
		});
		
		$("#kretnica").hover(function(){
            if (!moving & (puzzle_state.l == 0)) {
				$("#kretnica_h").attr("fill","yellow");
				$("#puzzleCont").css("cursor","pointer");
			}
		},function(){
			$("#kretnica_h").attr("fill","white");
			$("#puzzleCont").css("cursor","default");
		});

		$("#piscalka").click(function(){
            if (moving) {
                return;
            }
			$("#piscalka_h").attr("fill","white");
			$("#puzzleCont").css("cursor","default");
			puzzle_solution += "g";
			var to = 0;
			if(puzzle_state.l == 0) {
				if(puzzle_wagon == "") to = 420 - 67*puzzle_state.r[puzzle_track+1].length;
				else to = 353 - 67*puzzle_state.r[puzzle_track+1].length;
			} else to = 7;
			puzzleAnimateLocomotive(to,function(){
				if(puzzle_wagon != "") {
					if(to == 7) {
						puzzle_state.r[0].push(puzzle_wagon);
					} else {
						puzzle_state.r[puzzle_track+1].push(puzzle_wagon);
						puzzle_wagon = "";
						puzzle_state.r[0].pop();
					}
				} else {
					if(to != 7) {
						if(puzzle_state.r[puzzle_track+1].length > 0) {
							puzzle_wagon = puzzle_state.r[puzzle_track+1].pop();
						}
					}
				}
				if(to == 7) {
					puzzle_state.l = 0;
				} else puzzle_state.l = puzzle_track+1;
				if(puzzleIsSolved()) puzzleCheckSolution();
                moving = 0;
			});
		});
		
		$("#piscalka").hover(function(){
            if (!moving) {
				$("#piscalka_h").attr("fill","yellow");
				$("#puzzleCont").css("cursor","pointer");
			}
		},function(){
			$("#piscalka_h").attr("fill","white");
			$("#puzzleCont").css("cursor","default");
		});

	}

	function puzzleAnimateLocomotive(dest,callback) {
        moving = 1;
		$("#puzzleLocomotive").animate({
			left: dest
		},{
			duration: 1000,
			progress: function(promise,progress,remainingMs){
				// Calculate and set y position and angle
				var x = $(this).position().left;
				var pos = puzzleCalculatePosition(x+40);
				$(this).css("top",pos.y+"px");
				$(this).css("transform","rotate("+pos.a+"deg)");
				$(this).css("-ms-transform","rotate("+pos.a+"deg)");
				$(this).css("-webkit-transform","rotate("+pos.a+"deg)");
				// Calculate and update wagon position
				var wx = x + 73.0;
				var wpos = puzzleCalculatePosition(wx+33);
				$("#puzzleWagon_"+puzzle_wagon).css("left",wx+"px");
				$("#puzzleWagon_"+puzzle_wagon).css("top",wpos.y+"px");
				$("#puzzleWagon_"+puzzle_wagon).css("transform","rotate("+wpos.a+"deg)");
				$("#puzzleWagon_"+puzzle_wagon).css("-ms-transform","rotate("+wpos.a+"deg)");
				$("#puzzleWagon_"+puzzle_wagon).css("-webkit-transform","rotate("+wpos.a+"deg)");
				// Calculate hooks positions and draw connection
				if(puzzle_wagon != "") {
					var lo = $("#puzzleLocomotive_h").offset();
					var wo = $("#puzzleWagon_"+puzzle_wagon+"_h").offset();
					var po = $("#puzzleLayer01").offset();
					var x1 = lo.left - po.left;
					var y1 = lo.top - po.top;
					var x2 = wo.left - po.left;
					var y2 = wo.top - po.top;
					$("#conn").attr("x1",x1);
					$("#conn").attr("y1",y1);
					$("#conn").attr("x2",x2);
					$("#conn").attr("y2",y2);
					$("#conn").css("stroke","rgb(44,44,44)");
				} else {
					$("#conn").attr("x1",0);
					$("#conn").attr("y1",0);
					$("#conn").attr("x2",1);
					$("#conn").attr("y2",1);
					$("#conn").css("stroke","rgb(255,255,255)");
				}
			},
			done: callback
		});

	}

	function puzzleCalculatePosition(x) {

		var pos = {};

		if(x < 159) {
			pos.y = 73;
			pos.a = 0;
		} else if(x > 275) {
			pos.a = 0;
			pos.y = 16 + puzzle_track*56;
		} else {
			var p = (1-Math.cos(((x-159)/116)*(Math.PI)))/2.0;
			pos.a = (45-Math.abs(2*p-1)*45)*(puzzle_track-1);
			pos.y = 73+((puzzle_track-1)*56)*p;
		}

		return pos;

	}

	function puzzleIsSolved() {
		if(puzzle_state.r[1][0] == "g" && puzzle_state.r[1][1] == "b" && puzzle_state.r[1][2] == "r" ) return true;
		else return false;

	}

	function puzzleCheckSolution() {
		bQ.checkSolution(puzzle_solution);

	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution =  { true },

explanation="""V nalogi si se igral z nečim, čemur računalnikarji pravijo sklad. Na sklad
lahko odlagamo reči (recimo vagone), z njega pa jih jemljemo v obratnem vrstnem
redu: najprej dobimo tisto, ki smo jo odložili nazadnje, nato predzadnjo in tako
naprej. Zaradi tega si za
prestavljanje vagonov iz enega tira na drugega potreboval še tretji tir. Če
poskušaš prestaviti vagone iz spodnjega tira naravnost na zgornjega, bodo
končali v obratnem vrstnem redu."""
)
