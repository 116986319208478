typealias FlagId = String

open class FlagEquals(val flagId: FlagId, val value: Int, message: String = NoMessage): Condition(message) {
    override fun verify(gameState: GameState): Boolean = flagId in gameState.flags && gameState.flags[flagId]!! == value
}


class FlagIsSet(val flagId: FlagId, message: String = NoMessage): Condition(message) {
    override fun verify(gameState: GameState): Boolean = gameState.flags[flagId] ?: 0 != 0
}

class FlagNotSet(val flagId: FlagId, message: String = NoMessage): Condition(message) {
    override fun verify(gameState: GameState): Boolean = gameState.flags[flagId] ?: 0 == 0
}


open class SetFlag(val flagId: FlagId, val value: Int = 1): Action() {
    override operator fun invoke(state: GameState) {
        state.flags[flagId] = value
    }
}
