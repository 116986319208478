val T13_SE_06_kontrolna_vsota = Task("T13_SE_06_kontrolna_vsota", "Kontrolna vsota",

description = """

<div style="float:left"><p>Matematik drži v roki nekoliko raztrgan listek s črtno kodo z nekega izdelka.
    Preden ti zastavi vprašanje, pa ti pokaže igro, ki jo lahko igramo s
    številkami na takšnih listkih.</p></div>

<div style="float: right">
    <img style="width: 180px; margin-bottom: 20px" src="tasks/T13_SE_06_kontrolna_vsota/ean-13.png">
</div>


<style>
    #question {
        font-size: 24px;
    }

    #instructions td {
        width: 22px;
        text-align: center;
    }
</style>

<table id="instructions" style="clear: both;">
    <tr>
        <th style="text-align: right">Zapiši vse števke: </th>
        <td>5</td><td>9</td><td>0</td><td>1</td><td>2</td><td>3</td><td>4</td><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>7</td>
    </tr>
    <tr>
        <th style="text-align: right">Spodaj izmenično napiši enice in trojke: </th>
        <td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td>
    </tr>
    <tr>
        <th style="text-align: right">Pomnoži pare števk iz prve in druge vrste:</th>
        <td>5</td><td>27</td><td>0</td><td>3</td><td>2</td><td>9</td><td>4</td><td>3</td><td>2</td><td>9</td><td>4</td><td>15</td><td>7</td>
    </tr>
    <tr>
        <th style="text-align: right; padding-top: 6px; padding-bottom: 6px" colspan="14">Seštej vse skupaj:
        <span style="font-weight: normal">5 + 27 + 0 + 3 + 2 + 9 + 4 + 3 + 2 + 9 + 4 +15 + 7 = 90</span></th>
    </tr>
</table>

<p>Zadnja števka je vedno izbrana tako, da je rezultat deljiv z 10.</p>

<p>Na raztrganem listku, ki ga ima v roki matematik, je številka
    <br/><span id="question"></span>.<br/> Zadnja števka manjka. Kakšna je?</p>

<div style="float: left">
        <input id="answer" type="text" class="form-control"
               style="width: 200px; margin-right: 20px;"
               placeholder="Vnesi število" />
</div>
<div style="float: left">
        <button id="submit" type="submit" class="btn btn-default"><b>Odgovori</b></button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    function puzzleLoad() {
        $('#question').html(_.times(12, function() {return _.random(9); }).join(' '));
        $('#submit').click(checkSolution);
        var ans_obj = $("#answer");
        ans_obj.bind("enterKey", checkSolution);
        ans_obj.keyup(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });

    }

	function checkSolution() {
        bQ.checkSolution($('#question').html() + ' ' + $('#answer').val());
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    val nums = solution
        .split(' ')
        .map { it.trim().toIntOrNull() }
        .filterNotNull()
    nums.size == 13 && nums.withIndex().sumBy { (i, n) -> n * (1 + 2 * (i % 2)) } % 10 == 0
},

explanation="""
    <style>
    #question {
        font-size: 24px;
    }

    #instructions td {
        width: 22px;
        text-align: center;
    }
    </style>

    <p>Računalnik si vsakič izmisli drugo številko. Kako bi rešili nalogo, če nam
        zastavi, na primer, število: 7 4 9 2 3 7 4 5 7 1 7 7</p>
    
    <p>Najprej le sledimo navodilom.</p>
    
    <table id="instructions" style="clear: both;">
        <tr>
            <th style="text-align: right">Zapiši vse števke: </th>
            <td>7</td><td>4</td><td>9</td><td>2</td><td>3</td><td>7</td><td>4</td><td>5</td><td>7</td><td>1</td><td>8</td><td>7</td><td>?</td>
        </tr>
        <tr>
            <th style="text-align: right">Spodaj izmenično napiši enice in trojke: </th>
            <td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td><td>3</td><td>1</td>
        </tr>
        <tr>
            <th style="text-align: right">Pomnoži pare števk iz prve in druge vrste:</th>
            <td>7</td><td>12</td><td>9</td><td>6</td><td>3</td><td>21</td><td>4</td><td>15</td><td>7</td><td>3</td><td>8</td><td>21</td><td>?</td>
        </tr>
        <tr>
            <th style="text-align: right; padding-top: 6px; padding-bottom: 6px" colspan="14">Seštej vse skupaj:
            <span style="font-weight: normal">7 + 12 + 9 + 6 + 3 + 21 + 4 + 15 + 7 + 3 + 8 + 21 + ? = 116 + ?</span></th>
        </tr>
    </table>
    
    <p>Katero število moramo prišteti k 116, da bo rezultat deljiv z 10?</p>
    
    <p>Črtne kode so v resnici sestavljene na ta način: poišči poljuben izdelek s
        črtno kodo (preveri le, da ima 13 števk - obstajajo namreč tudi drugačne)
        in se prepričaj, da je res tako.</p>
    
    <p>Zakaj so črtne kode sestavljene tako? Zato, da lahko čitalec črtne kode
    preveri, ali je pravilno prebral kodo. Če vsota ni deljiva z 10, ve, da se je
    zmotil.</p>"""
)
