val T09_XX_24_detektivska_zgodba = Task("T09_XX_24_detektivska_zgodba", "Detektivska zgodba",

description = """

<p>Policisti so glede zadeve "kraja letalskih ključev" od ovaduhov izvedeli
    dvoje. Najprej, mestni tatovi vedno delajo v parih.
    Kdo dela s kom, kaže spodnja mreža: vsak krogec predstavlja enega tatu in
    dva krogca sta povezana, če imata navado kdaj krasti skupaj. Če dva tatova
    nista povezana, to pomeni, da se ne poznata. Ker se tatovi kličejo po
    nadimkih in ne pravih imenih ali priimkih, pa ne vemo, kateri krogec
    predstavlja katerega tatu.
</p>

<div class="center-block" style="width:512px;padding:10px;border:5px solid white;border-radius: 10px;background-color:white;color:black;margin-bottom:5px;">
    <center><img src="tasks/T09_XX_24_detektivska_zgodba/kriminalci.svg" style="margin:10px"></center>
</div>

<p>Drugo, kar so izvedeli policisti, so priimki vseh mestnih tatov: Kosmatinec,
Zobolomšek, Graboljub, Črnonohtnež, Podplutnik, Bradobrk in Bobrabež. Vse so
pridržali in zaslišali: vsak je moral povedati, katere izmed ostalih pridržanih
pozna. Dobili so takšne sezname poznanstev:</p>

<div id="puzzleCont" class="center-block" style="width:512px;padding:10px;border:5px solid white;border-radius: 10px;background-color:white;color:black;margin-bottom:5px;">
    <table style="width:100%;">
        <tr style="border-bottom:1px solid black;">
            <th>Kosmatinec:</th><td>Zobolomšek, Podplutnik, Črnonohtež</td>
        </tr>
        <tr style="border-bottom:1px solid black;line-height:30px;">
            <th>Zobolomšek:</th><td>Kosmatinec, Podplutnik, Graboljub</td>
        </tr>
        <tr style="border-bottom:1px solid black;line-height:30px;">
            <th>Graboljub:</th><td>Zobolomšek, Bradobrk, Bobrabež</td>
        </tr>
        <tr style="border-bottom:1px solid black;line-height:30px;">
            <th>Črnonohtnež:</th><td>Kosmatinec, Bradobrk</td>
        </tr>
        <tr style="border-bottom:1px solid black;line-height:30px;">
            <th>Podplutnik:</th><td>Kosmatinec, Zobolomšek</td>
        </tr>
        <tr style="border-bottom:1px solid black;line-height:30px;">
            <th>Bradobrk:</th><td>Črnonohtež, Graboljub, Bobrabež</td>
        </tr>
        <tr style="line-height:30px;">
            <th>Bobrabež:</th><td>Bradobrk, Graboljub</td>
        </tr>
    </table>
</div>

<p>Ko so prešteli povezave v gornji mreži, so ugotovili, da je en par tatov
    zatajil, da se pozna. Ključ sta gotovo ukradla tadva. Žal pa policisti ne
    znajo ugotoviti, za kateri par gre. Ti, ki si zvit, pa boš to gotovo odkril.
    </p>

<p>Mimogrede, Kosmatinec je tokrat gotovo nedolžen, saj je bil ravno v času
    kraje zaprt.</p>

<p>Iz spodnjega seznama izberi tatova, ki sta kriva kraje:</p>

<div class="center-block" style="width:100%;text-align:center;">
	<button id="puzzleBtn01" class="btn btn-default puzzleBtns00" style="">Kosmatinec</button>
	<button id="puzzleBtn02" class="btn btn-default puzzleBtns00" style="">Zobolomšek</button>
	<button id="puzzleBtn03" class="btn btn-default puzzleBtns00" style="">Graboljub</button>
	<button id="puzzleBtn04" class="btn btn-default puzzleBtns00" style="">Črnonohtež</button>
	<button id="puzzleBtn05" class="btn btn-default puzzleBtns00" style="">Podplutnik</button>
	<button id="puzzleBtn06" class="btn btn-default puzzleBtns00" style="">Bradobrk</button>
	<button id="puzzleBtn07" class="btn btn-default puzzleBtns00" style="">Bobrabež</button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	let puzzle_solution = "";
	let puzzle_step = 1;

	function puzzleLoad() {
	
		// Register buttons
		$(".puzzleBtns00").click(function(){
		
			var val = $(this).html();
			$(this).attr("disabled","disabled");
			
			if(puzzle_step == 1) {
				puzzle_solution = val + " ";
				puzzle_step = 2;
			} else {
				puzzle_solution = puzzle_solution + val;
				$(".puzzleBtns00").off("click");
				setTimeout(puzzleCheckSolution,500);
			}
		
		});
	
	}
	
	function puzzleCheckSolution() {
		
		bQ.checkSolution(puzzle_solution);
	
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it.split(' ').toSet() == setOf("Podplutnik", "Bradobrk") },
    explanation="""
        <style>
            tr {
                vertical-align: top;
            }

            tr img {
                margin-bottom: 20px;
            }
        </style>

        <p>Najprej pogledamo, kdo je priznal, da pozna koga in sami narišemo sliko
        poznanstev.</p>

        <img class="on-white center-block" style="margin-bottom: 20px"
             src="tasks/T09_XX_24_detektivska_zgodba/confessed-network.png"/>

        <table>
            <tr>
                <td>
                    Vemo, da je resnična mreža takšna, kot jo kaže desna slika. Mreži
                    moramo primerjati in odkriti, kdo je kdo.
                </td>
                <td>
                    <img src="tasks/T09_XX_24_detektivska_zgodba/solution0.png" %}"/>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Na obeh slikah sta po dva trikotnika.</p>

                    <p>Na naši sliki je le eden, ki ni udeležen v nobenem trikotniku.
                        To mora biti Črnonohtnež.</p>
                </td>
                <td>
                    <img src="tasks/T09_XX_24_detektivska_zgodba/solution1.png"/>
                </td>
            </tr>
            <tr>
                <td>
                    Črnonohtnež je povezan z dvema: Kosmatincem in Bradobrkom. Tidve imeni moramo
                    označiti tudi na naši skici. Če bi bil Kosmatinec spodnji, potem bi imel štiri
                    znance, na podani mreži pa ima le tri. Torej bi bil Kosmatinec eden od tatov.
                    Ker pa vemo, da je nedolžen, mora biti Kosmatinec zgornji. Spodnji je torej
                    Bradobrk.
                </td>
                <td>
                    <img src="tasks/T09_XX_24_detektivska_zgodba/solution2.png"/>
                </td>
            </tr>
            <tr>
                <td>
                    Trikotnika na sliki sta povezana: povezuje ju par Zobolomšek in Graboljub.
                    Njuni imeni lahko vpišemo tudi na našo skico: Zobolomšek je tisti, ki je povezan
                    s Kosmatincem, Graboljub z Bradobrkom.
                </td>
                <td>
                    <img src="tasks/T09_XX_24_detektivska_zgodba/solution3.png"/>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Zdaj lahko vpišemo še preostali imeni, tako da dokončamo trikotnika.</p>

                    <p>Ko primerjamo dobljeni mreži, vidimo, da sta v resnici povezana še Bradobrk
                    in Podplutnik. Pa ju imamo, lopova!</p>
                </td>
                <td>
                    <img src="tasks/T09_XX_24_detektivska_zgodba/solution4.png"/>
                </td>
            </tr>
        </table>

        <p>Takšnim mrežam pravimo <em>grafi</em>. Grafi so
            sestavljeni iz "točk" (v našem primeru kriminalcev) in "povezav" med njimi.
            Kako razpostavimo točke, ko jih rišemo, je vseeno, pomembno je le, kdo je
            povezan s kom.</p>

        <p>Grafe uporabljamo vsepovsod: s pomočjo grafom sestavljamo, opazujemo in
        izboljšujemo računališka omrežja, prometne mreže, z njihovo pomočjo iščemo
        najboljše poti iz kraja v kraj, trgovska podjetja s pomočjo grafov ugibajo,
        katere reklame nam pokazati...</p>
    """
)
