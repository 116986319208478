@Suppress("Unused")
val regionVas = Region(
    "0_vas",

    Location("0000_zacetna_soba", Coordinates(279, 238), "Tvoja soba", """
        Nad Bobrovim dolom je napočil nov dan. Dan, ko greš na pot. Saj se še
        spomniš, kakšno? Reka Bobrovščica, ki teče skozi tvojo vas, je začela
        presihati, vsak dan je je manj in manj. Še malo in vas bo propadla. Javil si se,
        da greš ob reki navzgor raziskat, kaj se je zgodilo.
            
        Zdaj pa gremo! Edina pot vodi ven, v širni svet.""",

        "Ven" to "0001_sredisce_vasi",

        commands = Commands(
            StartTask(
                "Ugasni luči",
                "T09_XX_18_ugasanje_luci",
                onSuccessActions = Actions(Message("Luči so ugasnjene")),
                onFailureActions = Actions(Message("Luči moraš ugasniti s petimi pritiski, sicer se ponovno prižgejo. Moral boš začeti od začetka.`"))
            )
        )
    ),

    Location("0001_sredisce_vasi", Coordinates(255, 270), "Središče vasi", """
        V vasi je vse narobe. Bobri so zaskrbljeni: nekateri preurejajo
        jezove, drugi že razmišljajo o tem, kam in kako seliti vas. Vsi pa upajo se bo
        reč uredila. Morda bo voda spet pritekla sama od sebe, morda pa
        boš ti uspel odkriti, kaj se dogaja z reko.
        
        Nikar ne odlašaj, le brž na pot.""",

        "JV" to "0003_vrtickarji",
        "V" to "0004_domace_roze",
        "SZ" to "0002_nosac_hlodov",
        "S" to "0005_most",
        "V hišo" to "0000_zacetna_soba"
    ),

    Location("0002_nosac_hlodov", Coordinates(94, 216), "Vznožje hriba",
        { state: GameState ->
            """<p>Z roba vasi vodi ozka pot visoko v golo pobočje hriba, po katerem teče manjši
            potoček. V poletni vročini bo presahnil, vsaj za kak mesec pa bodo bobri
            preusmerili njegovo vodo proti vasi.</p>""" +
            if ("T11_AT_09_nosac_hlodov" !in state.solvedTasks)
                """<p>Ob vznožju hriba je velik kup lesa, ki ga marljivi nosači odnašajo proti
                   gradbišču. Tu bi se dalo zaslužiti kakšen cekin.</p>"""
            else ""
        },

        "JV" to "0001_sredisce_vasi",

        commands = Commands(
            StartTask(
                "Nosi les", "T11_AT_09_nosac_hlodov",
                onSuccessActions = Actions(
                    Message("Hitro si odnesel hlode v hrib in zaslužil enajst cekinov. Ravno prav za letalo."),
                    GetItem("enajst cekinov")
                ),
                onFailureActions = Actions(Message("To ne bo dovolj za letalo. Poskusi kako drugače."))
            )
        )
    ),

    Location("0003_vrtickarji", Coordinates(342, 312), "Vrtički",
        { state: GameState ->
            if ("T10_AT_06_vrtickarji" in state.solvedTasks)
                "<p>Pridni bobri obdelujejo svoje vrtičke, ki si jim jih pomagal razdeliti.</p>"
            else """
            <p>Bobri si morajo v pomanjkanju vode na novo razdeliti vrtic&#x30c;ke. To pa sploh ni
            tako preprosto. Pomagaj jim in mogoče ti bodo hvaležni.</p> 
            """
        },

        "S" to "0004_domace_roze",
        "V" to "0007_otrosko_igrisce",
        "SZ" to "0001_sredisce_vasi",

        commands = Commands(
            StartTask(
                "Pomagaj vrtičkarjem", "T10_AT_06_vrtickarji",
                onSuccessActions = Actions(
                    Message("Hvaležni vrtičkarji ti dajo nekaj hrane."),
                    GetItem("hrana")
                ),
                onFailureActions = Actions(Message("Vrtičkarji niso prav zadovoljni. Poskusi ponovno."))
            )
        )
    ),

    Location("0004_domace_roze", Coordinates(348, 261), "Vrt z rožami", { state ->
        """<p>Kot vsak pravi bober imaš tudi ti vrtiček z rožami. V posodah nad njimi je
        dovolj vode za precej časa. """ +
        if (HasSolved("T10_DE_13_zalivanje_roz").verify(state)) """</p>
                <img style="float:right" src="tasks/T10_DE_13_zalivanje_roz/icon.png"/>"""
        else "Le svoj zalivalni izum moraš vključiti.</p>"},

        "S" to "0006_recni_breg",
        "J" to "0003_vrtickarji",
        "Z" to "0001_sredisce_vasi",

        commands = Commands(
            StartTask(
                "Vključi zalivalnik", "T10_DE_13_zalivanje_roz",
                onFailureActions = Actions(Message("Tole ne bo dobro. Poskusi ponovno.")),
                onSuccessActions = Actions(Message("Tako, za tvoje rože je poskrbljeno."))
            )
        )
    ),

    Location("0005_most", Coordinates(256, 167), "Most v svet", """
        Most, pod katerim skoraj ni več vode, vodi na drugi breg Bobrovščice, ob
        katerem se vije pot skozi gozd. Na kamnu pred mostom sedi star bober, ki je
        videl že več sveta kot kdorkoli v vasi. Gotovo ti bo znal povedati kaj
        pametnega.""",

        "J" to "0001_sredisce_vasi",
        "V" to "0006_recni_breg",

        commands = Commands(
            Command(
                "Vprašaj za nasvet",
                Actions(
                    Message("""
                    Onstran gozda je veliko mesto, od koder vozijo letala na
                    izlete ob Bobrovščici navzgor. Iz letala boš najbolj videl, kaj
                    se dogaja z njo. Potreboval boš nekaj denarja za vozovnico;
                    včasih so bile po enajst cekinov."""),
                    SetFlag("nasvet_modreca")
                )
            ),
            Command(
                "Čez most",
                Actions(
                    Message(
                        """
                    Mama ti pomaha v slovo: "Ne pozabi mi poslati razglednice iz
                    velikega mesta!"""
                    ),
                    MoveTo("0100_drugi_breg")
                ),
                Conditions(
                    FlagIsSet(
                        "nasvet_modreca",
                        "Preden greš iz vasi, le vprašaj modrega starca za nasvet."
                    ),
                    FlagIsSet(
                        "pozdravil_sestro",
                        """Menda ne boš odšel, ne da bi poiskal svojo sestrico in se
                     poslovil od nje?"""
                    ),
                    HasItem(
                        "enajst cekinov",
                        "Brez denarja pa že ne moreš na pot."
                    ),
                    HasItem(
                        "hrana",
                        "Brez hrane vendar ne moreš na pot. Saj boš še umrl od lakote!"
                    ),
                    HasSolved(
                        "T09_XX_18_ugasanje_luci",
                        """Pot bo dolga. Se ne bi vrnil v svojo sobo in ugasnil luči?
                     Elektrika vendar ni zastonj."""
                    ),
                    HasSolved(
                        "T10_DE_13_zalivanje_roz",
                        """Z eno nogo si že na mostu, ko si se na srečo spomnil: pozabil
                     si nastaviti svoj sistem za zalivanje rož!"""
                    )
                ),
                leadsTo = "0100_drugi_breg"
            )
        )
    ),

    Location("0006_recni_breg", Coordinates(363, 192), "Rečni breg", """
        Kakšen žalosten prizor! Kjer je bila še pred par tedni široka reka, v kateri
        je vse mrgolelo od življenja, se vije le skromen potoček. Žabe se preplašeno
        drenjajo po mlakužah. Mlinčki, ki so jih bobrčki postavljali ob rečnih rokavih,
        se zibajo v vetru. Korenine mogočnih dreves ob bregu so videti kot okostnjaki.

        Tu nimaš kaj iskati.""",

        "Z" to "0005_most",
        "J" to "0004_domace_roze"
    ),

    Location("0007_otrosko_igrisce", Coordinates(440, 305), "Otroško igrišče", """
        Na gugalnici se guga tvoja mala sestrica. Stopiš k njej in jo objameš v
        slovo.
               
        "<em>Pogrešala te bom,</em>" skoraj zajoka.<br/>
        "<em>Nič ne skrbi, saj bom hitro nazaj,</em>" jo potolažiš.""",

        "Z" to "0003_vrtickarji",

        autoCommands = Commands(
            Command(
                "",
                actions = Actions(SetFlag("pozdravil_sestro"))
            )
        )
    )
)