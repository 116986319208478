@Suppress("unused")
val regionGozd = Region(
    "1_gozd",

    Location("0100_drugi_breg", Coordinates(153, 330), "Drugi breg", """
        Ko je človek enkrat končno na poti - se takoj počuti drugače, mar ne?
        Občutek pustolovščine ti vliva poguma, ko korakaš po poti proti ... kam
        pravzaprav? Si že na prvem razpotju. Vzhodna pot vodi ob Bobrovščici navzgor in
        zahodna navzdol (nekaj ti govori, da ta ne bo prava). Potki, ki držita proti
        severu, izginjata v gozdu. Prav všeč ti nista, a stari bober je vendarle rekel
        "onstran gozda".""",
        
        "Z" to "0101_gozd",
        "S" to "0106_gozd",
        "SV" to "0107_gozd",
        "V" to "0103_skalna_soteska"
    ),

    Location("0113_ribnik", Coordinates(195, 121), "Ribnik", { state ->
            if (HasSolved("T11_AT_08_zabin_sprehod").verify(state))
                "Ob ribniku sedi žaba in liže liziko. Ob vsakem oblizu ti nekoliko predolgo pokaže jezik."
            else
                """<p>Res ne veš, kaj bi si mislil ob žabi, ki te norčavo gleda s kamna na robu
                       ribnika. Hudobna ni videti; posebej prijazna tudi ne. </p>
                   <p>Na kamnu na drugi strani ribnika je nekaj nenavadnega: budilka. Žaba
                      pove, da so jo zgodaj zjutraj prinesle srake in pustile tam. Če hočeš, ti jo
                      prinese.</p>"""
        },

        "JZ" to "0112_dedek_in_babica",

        commands = Commands(
            Command(
                "Plavaj po budilko",
                showOn = Not(HasSolved("T11_AT_08_zabin_sprehod")),
                action = Message(
                    """Žaba začne klicati srake. To ne bo dobro, zato
                       hitro zlezeš iz vode. Presneto! Prav si imel, res
                       ni posebej prijazna."""
                )
            ),
            Command(
                "Daj žabi liziko",
                showOn = HasItem("lizika"),
                actions = Actions(
                    DropItem("lizika"),
                    SetFlag("dal_liziko_zabi"),
                    Message("Žaba od veselja kar poskoči.")
                )
            ),
            StartTask(
                "Prosi žabo za budilko", "T11_AT_08_zabin_sprehod",
                conditions = Conditions(FlagIsSet("dal_liziko_zabi", "Žaba zahteva za plačilo liziko.")),
                onSuccessActions = Actions(Message("Žaba ti izroči budilko in odide s tvojo liziko."),
                                           GetItem("budilka"))
            )
        )
    ),

    Location("0109_gozd", Coordinates(223, 206), "Gozd",
        "Zašel si v globoko gozdno kotanjo. Pod debelimi prastarimi drevesi je temno in hladno.",

        "Z" to "0110_gozd",
        "JZ" to "0107_gozd"
    ),

    Location("0117_v_reki", Coordinates(15, 180), "Reka", """
        Komaj si se rešil iz močnega vodnega toka in priplaval do brega. Saj bi se
        skoraj utopil!
        
        Voda, v kateri stojiš, ni prav nič prijetna. Le hitro ven, preden se prehladiš.""",

        "Ven" to "0102_druga_reka"
    ),

    Location("0110_gozd", Coordinates(187, 198), "Gozd", """
        Poti so vedno bolj zaraščene. Spotikaš se ob korenine in skale. Tu ne boš
        prišel nikamor, obrni se nazaj proti jugu ali vzhodu.""",

        "J" to "0107_gozd",
        "V" to "0109_gozd"
    ),

    Location("0103_skalna_soteska", Coordinates(210, 326), "Skalna soteska", """
        Rečna dolina se je stisnila v ozko sotesko. Tu naprej ni poti. Moral boš skozi gozd.""",

        "Z" to "0100_drugi_breg"
    ),

    Location("0101_gozd", Coordinates(35, 318), "Gozd", """
        Na južni strani se nad tabo dviguje hrib, prav tisti, na katerega si nosil
        hlode, da si zaslužil denar za letalo. (Le pazi nanj! Gozd je videti teman in
        nihče ne ve, koga boš srečal v njem.)
        
        Kaj pa se sliši s severa? Šumenje vode! To bo potreno raziskati.""",

        "S" to "0102_druga_reka",
        "SV" to "0106_gozd"
    ),

    Location("0108_gozd", Coordinates(253, 270), "Gozd", """
        Ob gozdni poti so naložena posekana debla. Sledovi na tleh kažejo, da jih
        vlačijo proti severu. Če te čut za orientacijo ne vara, je tvoja reka na jugu.""",

        "Z" to "0107_gozd",
        "SV" to "0114_travnik",
        "V" to "0104_skalno_gorovje"
    ),

    Location("0112_dedek_in_babica", Coordinates(91, 171), "Dedek in babica", { state ->
        if (HasSolved("T09_XX_09_mostovje").verify(state)) """
        <p>Pred skromnim bivališm na bregu mogočne reke stojita vesela starejša
        bobra, dedek in babica. Voda je že odplavila preostale dele mostu, ki si ga
        porušil, da jima lisice ne bi več kradle hrane.</p>"""
        else

            """<p>Pred skromnim bivališčem na bregu mogočne reke stojita obupana starejša
        bobra, dedek in babica. Ko te zagledata, ti hitita
        razložiti, kaj ju tare: prek mostov prihajajo
        vsako noč lisice krast hrano. Morala bi odplavati tja in ga porušiti, a tok je
        močan in onadva stara.</p>
        
        <p>Odlično priložnost imaš, da se izkažeš!</p>""" },

        "JZ" to "0105_gozd",
        "SV" to "0113_ribnik",
        "J" to "0111_jasa",

        commands = Commands(
            StartTask("Poruši most", "T09_XX_09_mostovje",
                 onSuccessActions=Actions(
                     Message("Hvaležna dedek in babica ti podarita liziko."),
                     GetItem("lizika")),
                 onFailureActions=Actions(
                     Message("""Preglodal si napačni steber. Ko si se začel razgledovati,
                             kateri bi bil pravi, te je vodni tok zgrabil in odnesel."""),
                     MoveTo("0117_v_reki"))
            )
        )
    ),

    Location("0116_zelezniska_postaja", Coordinates(377, 171), "Železniška postaja", { state -> """
        <p>Na mali postaji je vse narobe. Strojevodja je včasih tu, včasih ne.
        Vagoni so parkirani pravilno ali pa napačno.
        Sprevodnik prodaja karte po pet žebljev. Žebljev?! Le eno je gotovo: železniška proga je
        le ena in vozi tja, kamor želiš iti: naravnost v mesto.</p>
        """ + if (FlagNotSet("strojevodja_buden").verify(state))
        """<p>Postaja je polna ljudi, strojevodja pa - čeprav je bober - spi kot polh.</p"""
        else ""},

        "J" to "0114_travnik",
        "Z" to "0115_zaga",

        commands = Commands(
            Command("Zbudi strojevodjo",
                actions = Actions(Message("""Pred strojevodjo si postavil budilko in jo
                                        navil. Pošteno je zazvonila, strojevodja se je
                                        pošteno zdrznil, se zbudil in pošteno zazehal.
                                     """),
                             SetFlag("strojevodja_buden"),
                             DropItem("budilka")),
                conditions = Conditions(
                    FlagNotSet("strojevodja_buden",
                               """Saj si ga že zbudil. Budilko je prilepil na
                               okensko polico s trikomponentnim lepilom; zdaj
                               jo lahko srake ukradejo le še skupaj s hišo
                               (in strojevodjo). To pa je malo verjetno."""),
                    HasItem("budilka",
                            """<p>Spi kot mrtev. Pa še smrči zraven.</p>
                            <p>Na zaprašeni okenski polici je
                            videti, kje je nekoč stala budilka. Očitno jo je nekdo
                            odnesel. Sraka tatinska. Poišči jo in mu jo vrni. Morda
                            ga ta zbudi.</p>""")
                )
            ),
            Command("Kupi vozovnico",
                showOn=Not(HasItem("vozovnica za vlak")),
                actions=Actions(Message("Kupil si vozovnico v mesto."),
                                DropItem("pet žebljev"),
                                GetItem("vozovnica za vlak")),
                condition=HasItem("pet žebljev",
                            """Vozovnice do mesta stanejo pet žebljev.
                               Morda jih imajo v žagi ob robu naselja.
                            """)
            ),
            StartTask("Uredi vagone", "T12_AT_11_premikanje_vagonov",
                 showOn=And(Not(HasSolved("T12_AT_11_premikanje_vagonov")),
                            FlagIsSet("strojevodja_buden"))
            ),
            Command("Odpelji se v mesto",
                actions=Actions(DropItem("vozovnica za vlak"),
                                MoveTo("0200_mesto")),
                conditions=Conditions(
                    HasItem("vozovnica za vlak",
                            "Misliš, da se boš vozil kar zastonj?"),
                    FlagIsSet("strojevodja_buden",
                              """Na postaji je polno bobrov, ki bi se radi
                                 peljali. A nič ne pomaga: strojevodja je star
                                 len bober, ki spi kot polh."""),
                    HasSolved("T12_AT_11_premikanje_vagonov",
                              """Preden odidete, bo potrebno prerazporediti
                                 vagone. Strojevodja je še vedno nekoliko
                                 omotičen. Pomagaj mu.""")
                )
            )
        )
    ),

    Location("0115_zaga", Coordinates(312, 167), "Žaga", { state -> """
        <p>Zdaj vidiš, kam so vozili posekane hlode iz gozda: pred tabo je žaga in
        moderno skladišče obdelanih hlodov.</p>""" +
            if (FlagNotSet("pomagal_v_zagi").verify(state)) """
                <p>Na parkirišču je siten bober, ki je prišel po nekaj hlodov.</p>"""
            else ""},

        "V" to "0116_zelezniska_postaja",

        commands = Commands(
            StartTask("Pomagaj naložiti hlode", "T07_XX_3C_skladisce_hlodov",
                showOn = Always(),
                conditions=Conditions(Not(HasSolved("T07_XX_3C_skladisce_hlodov",
                    """Hlodi so že naloženi. Ne bodi bolj priden, kot je treba."""))),
                 onSuccessActions=Actions(
                     Message("Hvaležni delavci ti dajo pest žebljev"),
                     GetItem("pet žebljev"),
                     SetFlag("pomagal_v_zagi")),
                 onFailureActions=Actions(Message("""Sitni bober se je še bolj razjezil.
                                                     Tole se ti ni najbolj posrečilo."""))
            )
        )
    ),

    Location("0102_druga_reka", Coordinates(22, 199), "Rečni breg", """
        Mogočni reki, ob kateri stojiš, res ne manjka vode, a kaj, ko ni prava.
        Teče namreč po drugi strani hribovja. Ni kaj, treba bo nazaj na pot.""",

        "J" to "0101_gozd"
    ),

    Location("0105_gozd", Coordinates(64, 238), "Gozd", """
        Gozd postaja vedno temnejši in diši ti po medvedih. Misel, da bi postal
        zajtrk, ti ni všeč, zato se boš obrnil na eno od poti proti vzhodu.""",

        "SV" to "0112_dedek_in_babica",
        "V" to "0111_jasa",
        "JV" to "0106_gozd"
    ),

    Location("0107_gozd", Coordinates(188, 256), "Gozd", """
        Grmičevje prerašča zapuščene poti, ki vodijo v vse smeri.""",

        "S" to "0110_gozd",
        "SV" to "0109_gozd",
        "SZ" to "0111_jasa",
        "JZ" to "0100_drugi_breg",
        "V" to "0108_gozd"
    ),

    Location("0114_travnik", Coordinates(350, 226), "Gozd", """
        Dobro zvožen kolovoz povezuje gozd z naselji na severu.""",

        "JZ" to "0108_gozd",
        "S" to "0116_zelezniska_postaja"
    ),

    Location("0104_skalno_gorovje", Coordinates(352, 292), "Skalno gorovje", """
        Povzpel si se na visok hrib, odkoder je lep razgled vsenaokrog. Na južni
        strani vidiš Bobrovščico - ali kar je ostalo od nje. Njena soteska je globoko
        pod tabo in njeni bregovi so prestrmi, da bi se mogel spustiti dol. Proti
        zahodu se razprostira velik gozd. Daleč na severu se začenjajo travniki in
        prva naselja.""",

        "Z" to "0108_gozd"
    ),

    Location("0111_jasa", Coordinates(106, 219), "Gozdna jasa", """
        Jasa sredi gozda je prav prijetna in sončni žarki ti dobro denejo. Na
        severu je videti bivališče, pred njim pa starejšo bobrovko.""",

        "S" to "0112_dedek_in_babica",
        "Z" to "0105_gozd",
        "J" to "0106_gozd",
        "JV" to "0107_gozd"
    ),

    Location("0106_gozd", Coordinates(131, 276), "Gozd", """
        Shojena pot na sever vodi proti jasi, severovzhodna pa globje v gozd, od
        koder se slišijo čudni zvoki. Kaj pa, če bi šel raje nazaj na jug, proti
        reki?""",

        "S" to "0111_jasa",
        "J" to "0100_drugi_breg",
        "SZ" to "0105_gozd",
        "JZ" to "0101_gozd"
    )
)