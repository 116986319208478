val T09_XX_09_mostovje = Task("T09_XX_09_mostovje", "Tatinske lisice",

description = """<p>V reki so postavljeni stebri in prek njih deske. Prek tega zasilnega mosta
    hodijo lisice krast hrano. Če preglodaš steber, padejo v vodo vse deske,
    postavljene nanj.</p>

<p>Kateri steber boš preglodal, da lisice ne bodo več mogle prek reke?</p>

<div class="center-block" style="position:relative;width:522px;height:328px;border:5px solid white;border-radius:10px;">
	<div id="svgload" style="width:512px;height:318px;">
	</div>
	<div id="puzzleFoxLayer" style="position:absolute;top:0px;left:0px;width:512px;height:318px;"></div>
	<div id="puzzleButtonLayer" style="position:absolute;top:0px;left:0px;width:512px;height:318px;"></div>
</div>

""",

runTask = eval("""{ run: (bQ) => {

	var puzzle_graphics_ratio_x = 512.0 / 573.0;
	var puzzle_graphics_ratio_y = 318.0 / 356.0;
	var puzzle_animation_fox_offset = 0;
	var puzzle_spriteAnimationEnabled = true;
	
	var puzzle_graph = [
		["r01","s01","c01"], ["r02","c01","c03"],
		["r03","c01","c02"], ["r04","c03","c02"],
		["r05","c02","c18"], ["r06","s02","c04"],
		["r07","c04","c05"], ["r08","c04","c07"],
		["r09","c04","c06"], ["r10","c03","c07"],
		["r11","c07","c08"], ["r12","c07","c18"],
		["r13","c18","c17"], ["r14","c02","c17"],
		["r15","c17","c15"], ["r16","c15","c16"],
		["r17","c15","c12"], ["r18","c17","c12"],
		["r19","c06","c08"], ["r20","c08","c09"],
		["r21","c05","c10"], ["r22","c10","c11"],
		["r23","c10","c09"], ["r24","c08","c10"],
		["r25","c06","c07"], ["r26","c05","c06"],
		["r27","c06","c10"], ["r28","c17","c09"],
		["r29","c12","c14"], ["r30","c12","c13"],
		["r31","c13","s03"], ["r32","c14","c13"],
		["r33","c12","s04"], ["r34","c15","c14"]
	];
	
	var puzzle_solution = "";

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T09_XX_09_mostovje/mostovi.svg',
			settings: {},
			onLoad: puzzleInit
		});

	}
	function puzzleInit() {

		for(var i = 1; i <= 18; i++) {
		
			var id = i;
			if( i < 10) id = "0" + id;
			id = "c" + id;
			
			var left = parseFloat($("#"+id).attr("cx"))*puzzle_graphics_ratio_x - 15;
			var top  = parseFloat($("#"+id).attr("cy"))*puzzle_graphics_ratio_y - 15;
			var btnId = "btn_" + id;
			var newHtml = '<div id="'+btnId+'" class="puzzleBtns" data-c="'+id+'" style="position:absolute;left:'+left+'px;top:'+top+'px;width:30px;height:30px;background-image:url(\''+'tasks/T09_XX_09_mostovje/c.png'+'\');background-position:30px 0px;cursor:pointer;" />';
			
			$("#puzzleButtonLayer").append(newHtml);
			
			$("#"+btnId).hover(function(){
				$(this).css("background-position","0px 0px");
			},function(){
				$(this).css("background-position","30px 0px");
			});
			
			$("#"+btnId).click(function(){
				puzzleClick($(this).attr("data-c"));
			});
		
		}

	}
	
	function puzzleClick(id) {
	
		puzzle_solution = id;
	
		// Remove buttons
		$(".puzzleBtns").remove();
	
		// Destroy node
		$("#"+id).fadeOut(1000);
	
		// Destroy all edges connected to this node
		for(var i = 0; i < 34; i++) {
			var edge = puzzle_graph[i];
			if(edge[1] == id || edge[2] == id) {
				puzzleAnimateEdgeSink(i);
				puzzle_graph[i] = ["","",""];
			}
		}
		
		// Proceed by fox animation
		setTimeout(puzzleAnimateFox,1000);
	
	}
	
	function puzzleAnimateEdgeSink(i) {
	
		var id = puzzle_graph[i][0];
		$("#"+id).fadeOut(1000);
	
	}
	
	function puzzleAnimateFox() {
	
		// Create fox
		var left = 550.0 * puzzle_graphics_ratio_x;
		var top  = 160.0 * puzzle_graphics_ratio_y;
		var newHtml = '<div id="puzzleFox" style="display:none;position:absolute;left:'+left+'px;top:'+top+'px;height:20px;width:35px;background-image:url(\''+'tasks/T09_XX_09_mostovje/fox.png'+'\');background-position:35px 0px;"></div>'
		$("#puzzleFoxLayer").append(newHtml);
		$("#puzzleFox").fadeIn(1000,function(){
			var path1 = puzzleFindPathFrom("s03",[]);
			var path2 = puzzleFindPathFrom("s04",[]);
			if(path1.length > 0) {
				puzzleAnimateFoxRunning();
				puzzleAnimateFox_step(path1,0);
			}
			else if(path2.length > 0) {
				puzzleAnimateFoxRunning();
				puzzleAnimateFox_step(path2,0);
			}
			else puzzleAnimateFoxLost();
		});
	
	}
	
	function puzzleAnimateFoxRunning() {
	
		puzzle_animation_fox_offset = (puzzle_animation_fox_offset + 35) % 105;
		$("#puzzleFox").css("background-position",puzzle_animation_fox_offset+"px 0px");
		
		if(puzzle_spriteAnimationEnabled)
			setTimeout(puzzleAnimateFoxRunning,100);
	
	}
	
	// Finds a path to left shore, if it exists, otherwise returns empty array
	function puzzleFindPathFrom(node,visitedNodes) {
	
		if(node == "s01") return visitedNodes.concat(["s01"]);
		if(node == "s02") return visitedNodes.concat(["s02"]);
		
		// Find connected nodes
		for(var i = 0; i < 34; i++) {
		
			var edge = puzzle_graph[i];
			var edgeId = edge[0];
			var node1  = edge[1];
			var node2  = edge[2];
			
			if(node1 == node) {
				if(visitedNodes.indexOf(node2) == -1) {
					var tryPath = puzzleFindPathFrom(node2,visitedNodes.concat([node1]));
					if(tryPath.length > 0) return tryPath;
				}
			}
			
			if(node2 == node) {
				if(visitedNodes.indexOf(node1) == -1) {
					var tryPath = puzzleFindPathFrom(node1,visitedNodes.concat([node2]));
					if(tryPath.length > 0) return tryPath;
				}
			}
		
		}
		
		return [];
		
	}
	
	function puzzleAnimateFox_step(path,step) {
	
		if(step == path.length) {
			puzzleCheckSolution();
			return false;
		}
	
		var node = path[step];
		
		var left = 0;
		var top  = 0;
		
		if(node == "s03") {
			left = 547*puzzle_graphics_ratio_x - 17;
			top  = 144*puzzle_graphics_ratio_y - 22;
		} else if(node == "s04") {
			left = 547*puzzle_graphics_ratio_x - 17;
			top  = 200*puzzle_graphics_ratio_y - 22;
		} else if(node == "s01") {
			left = 73*puzzle_graphics_ratio_x - 17;
			top  = 163*puzzle_graphics_ratio_y - 22;
		} else if(node == "s02") {
			left = 59*puzzle_graphics_ratio_x - 17;
			top  = 241*puzzle_graphics_ratio_y - 22;
		} else {
			left = parseFloat($("#"+node).attr("cx"))*puzzle_graphics_ratio_x - 17;
			top  = parseFloat($("#"+node).attr("cy"))*puzzle_graphics_ratio_y - 22;
		}
		$("#puzzleFox").animate({left:left+"px",top:top+"px"},1000,"linear",function(){
			puzzleAnimateFox_step(path,step+1);
		});
	
	}
	
	function puzzleAnimateFoxLost() {
	
		setTimeout(puzzleCheckSolution,1000);
	
	}
	
	function puzzleCheckSolution() {
	
		puzzle_spriteAnimationEnabled = false;
		bQ.checkSolution(puzzle_solution);
	
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "c17" },

explanation="""
    Mostovi so podobni internetu. Ko gledamo spletne strani, dobivamo besedila
    in slike z različnih računalnikov. Vsi ti podatki pridejo do nas prek
    povezav (v tej nalogi jih predstavljajo mostovi) med številnimi računalniki,
    usmerjevalniki in podobnimi
    napravami (stebri, v tej nalogi). Če se katera od teh naprav pokvari ali
    pa je kaka povezava prekinjena, gredo podatki po drugi poti.
    Načrtovalci interneta morajo poskrbeti, da je omrežje čimbolj odporno in
    takšna "druga pot" vedno obstaja. V nalogi ni bilo tako in je že en sam
    porušen steber prekinil povezavo med bregovoma.
    """
)
