val T13_JP_02_tuneli = Task("T13_JP_02_tuneli", "Začarani tuneli",

description = """

<style>
    #buttons div {
        background-color: #fff8dc;
        border-radius: 20px;
        border: solid #fff8dc 5px;
        margin-right: 20px;
    }
</style>
<p>Ugotovil si tole: v cevi hodijo po štirje bobri. Kadar gredo v črno cev,
    pridejo iz nje v obratnem vrstnem redu.

<img src="tasks/T13_JP_02_tuneli/black-tunnel.svg">

<p>Kadar gredo skozi belo cev, se v njej zamenjata prvi in zadnji v vrsti, drugi
    in tretji pa ostaneta v enakem vrstnem redu.</p>

<img src="tasks/T13_JP_02_tuneli/white-tunnel.svg">

<p>Voditelj, nek neznan skavt, Alenka (s pentljo v laseh) in ti (slučajno imaš
    v roki rožo) se odpravljate skozi tri cevi,
    namreč črno, belo in črno, tako da se boste dobro premešali. Razmišljaš, da
    bi bilo najlepše, če bi takrat, ko pridete ven, voditelj hodil spredaj,
    neznani skavt za njim, nato Alenka in za Alenko ti.</p>

<div>
    <div id="pre-tunnel" style="position: relative; top: 8px; z-index: -1"></div>
    <div style="height: 100px">
        <div style="z-index: -3; position: absolute; margin-top: 60px;
             background-color: green; width: 680px; height: 40px;"></div>
        <div id="tunnels-back" style="position: absolute; left: 250px; z-index: -2"><img src="tasks/T13_JP_02_tuneli/tunnels-back.svg"></div>
        <div id="tunnels-front" style="position: absolute; left: 250px; z-index: 0"><img src="tasks/T13_JP_02_tuneli/tunnels.svg"></div>
    </div>
    <div id="post-tunnel"></div>
</div>

<p style="clear: both">Kako se morate razporediti na vhodu?</p>

<div id="buttons">
    <div id="pre-1" style="float:left; width: 65px; height: 82px"></div>
    <div id="pre-2" style="float:left; width: 65px; height: 74px"></div>
    <div id="pre-3" style="float:left; width: 53px; height: 66px"></div>
    <div id="pre-4" style="float:left; width: 47px; height: 58px"></div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var clicked = 0;
    var answer = '';

  	function puzzleLoad(img) {
        if (img < 5) {
            $("#pre-" + img).svg({
                loadURL: 'tasks/T13_JP_02_tuneli/beaver-' + img + '.svg',
                settings: {},
                onLoad: function() { puzzleLoad(img + 1); }});
        }
        else {
            for(var i = 1; i <= 4; i++) {
                $("#pre-" + i).click(enqueue);
            }
        }
	}

    function enqueue() {
        var pre = $('#pre-tunnel');
        pre.prepend($(this));
        $(this).css('padding-top', 87 - $(this).height());
        clicked += 1;
        if (clicked == 4) {
            pre.animate({'left': 400}, 4000, other_side);
        }
        answer += $(this).attr('id').slice(-1);
    }

    function other_side() {
        var pre = $($('#pre-tunnel')[0]);
        pre.css('visibility', 'hidden').css('left', 0);
        pre.prepend(pre.children()[3]);
        pre.append(pre.children()[1]);
        $('#tunnels-back').css('left', 0);
        $('#tunnels-front').css('left', 0);
        pre.css('visibility', 'visible').animate({'left': 440}, 4000,
            function() { setTimeout(check_solution, 2); });
    }

    function check_solution() {
        bQ.checkSolution(answer);
    }

    bQ.register(function() { puzzleLoad(1); });

}};

//# sourceURL=cudni_postar.js
"""),

checkSolution = { it == "2431" },

    explanation="""
    <p>Kako tipičen programerski problem! Tuneli so ukaz in zanima nas, kakšen
    <em>vhod</em> v program potrebujemo za določen <em>izhod</em> (da, programerji
    v resnici govorijo o vhodih in izhodih). Da pridemo do odgovora, bomo sledili
    programu nazaj.</p>
    
    <p>Želeli bi takšen izhod.</p>
    
    <div style="margin-bottom: 20px; text-align: center">
        <img src="tasks/T13_JP_02_tuneli/explanation-1.png"/>
    </div>
    
    <p>Črni tunel obrne vrstni red. Da bi torej iz njega prišli v takšnem vrstnem
        redu, kot želimo, morajo vanj v ravno obratnem.</p>
    
    <div style="margin-bottom: 20px; text-align: center">
        <img src="tasks/T13_JP_02_tuneli/explanation-2.png"/>
    </div>
    
    <p>Beli tunel zamenja prvega in zadnjega. Tako vemo, kaj mora vanj.</p>
    
    <div style="margin-bottom: 20px; text-align: center">
        <img src="tasks/T13_JP_02_tuneli/explanation-3.png"/>
    </div>
    
    <p>Končno, če želimo, da iz prvega tunela pridejo bobri v gornjem vrstnem redu,
    morajo vanj v ravno nasprotnem.</p>
    
    <div style="margin-bottom: 20px; text-align: center">
        <img src="tasks/T13_JP_02_tuneli/explanation-4.png"/>
    </div>
    """
)
