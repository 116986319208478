import react.*
import react.dom.*


fun RBuilder.game(state: GameState, enqueueCommand: (Command) -> Unit, explainTask: (String) -> Unit) {
    div(classes="bQ-page-a") {
        attrs["id"] = "bQ-location"

        val currLoc = repository.locations[state.location]!!
        val currRegion = currLoc.region!!

        if (currRegion.hasMap) {
            div(classes = "mapcontainer") {
                img(src = "maps/${currRegion.name}.svg", classes = "map") {}
                mapOverlay(state.visited, state.pathsTaken, state.location)
            }
        }
        else {
            div(classes = "nomapcontainer") {
            }
        }
        compass(currLoc.commands.filter { it.showOn?.verify(state) ?: true}, enqueueCommand)
        location(currLoc, currLoc.commands, state, enqueueCommand, explainTask)
    }
}