open class Command(val description: String,
                   val actions: Actions, val conditions: Conditions,
                   val leadsTo: LocId?=null, val showOn: Condition?=null): Checkable {
    // Todo: it there a way to cast Action to Actions?
    constructor(description: String,
                action: Action, condition: Condition?=null,
                leadsTo: LocId?=null, showOn: Condition?=null):
        this(description,
            Actions(action),
            Conditions(condition), leadsTo, showOn)

    constructor(description: String,
                action: Action, conditions: Conditions,
                leadsTo: LocId?=null, showOn: Condition?=null):
        this(description, Actions(action), conditions, leadsTo, showOn)

    constructor(description: String,
                actions: Actions, condition: Condition?=null,
                leadsTo: LocId?=null, showOn: Condition?=null):
        this(description, actions, Conditions(condition), leadsTo, showOn)

    operator fun invoke(state: GameState, enqueueAction: (Action) -> Unit) {
        val problem = conditions(state)
        if (problem == NoProblem)
            actions.forEach(enqueueAction)
        else if (problem != NoMessage)
            enqueueAction(Message(problem))
    }

    override fun checkConsistency() =
        listOf(
            mergeConsistency(actions),
            mergeConsistency(conditions),
            checkExists(leadsTo, repository.locations, "Location"),
            showOn?.checkConsistency()
        ).filter { it != null && it != "" }
         .joinToString("\n")
}
