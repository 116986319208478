val T09_XX_14_lov_na_avtobuse = Task("T09_XX_14_lov_na_avtobuse", "Lov na avtobuse",

description = """<p>Mimi ti razloži svojo težavo. Stojita na postaji R. Mimi mora biti ob 14.14
   na postaji S. Ura je 13.58. Avtobusi vozijo takole:
<ul>
<li>Rumeni avtobus odpelje s postaje R na vsakih petnajst minut,
    in sicer 12, 27, 42 in 57 minut čez polno uro.</li>
<li>Zeleni odpelje 19, 39 in 59 minut čez polno uro.</li>
<li>Rdeči odpelje 3, 23 in 42 minut čez polno uro.</li>
<li>Modri vozi na dve minuti - ob polni uri, dve minuti čez uro, štiri minute
    čez uro...</li>
</ul>

<p>Ob povezavah na sliki je napisano, koliko minut potrebuje avtobus za
    posamezno pot. Avtobusi se na postajah počakajo, da potniki lahko
    prestopijo.</p>

<p>Sestavi pot, po kateri lahko Mimi pride pravočasno do šole.</p>

<div class="center-block" style="position:relative;width:480px;height:317px">
	<div id="svgload" style="position:relative;width:480px;height:317px">
	</div>
</div>

<div style="margin-top: 15px; text-align: center">
    <button id="done_button" class="btn btn-default">
        <b>Preveri</b>
    </button>
</div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var lines = ["#green7", "#green9", "#red7", "#red5", "#blue1",
                 "#yellow14", "#yellow3"];
    var thin = "3";
    var thick = "7";

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T09_XX_14_lov_na_avtobuse/shema.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
        _.each(lines, function(name) {
            $(name).attr("stroke-width", thin);
            $(name).attr("data-id", name);
            $(name).click(function() {
                $(this).attr("stroke-width",
                    $(this).attr("stroke-width") == thick ? thin : thick);
            });
        });
        $("#done_button").click(function(){
            submitSolution();
        });
	}

	function submitSolution() {
        var solution = _.filter(lines, function(name) {
            return $(name).attr("stroke-width") == thick; }).join(",");
        bQ.checkSolution(solution);
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    solution.split(',').toSet() == setOf("#blue1", "#yellow3", "#green9") },

    explanation="""
        <p>V nalogi si spoznal, kako zapleteno je lahko iskanje najboljše poti: to ni
    niti pot z najmanj prestopanji, niti pot, po kateri ti bo vožnja z
    avtobusom vzel najmanj časa. Na rdečem avtobusu bi Mimi preživela 12 minut
    in pripeljal bi jo naravnost pred šolo. Pravilna rešitev (zeleni -
    modri - rumeni) pa zahteva dve prestopanji in traja minuto dlje.</p>

<p>Kako bi reševal podobno nalogo, če bi bilo avtobusov in avtobusnih postaj
    na tisoče? Ali celo milijone? V resnici so si računalnikarji izmislili
    zvite postopke, s katerimi se tudi tako velikih mrež ne bi ustrašili. Ko
    navigacijske naprave poiščejo najkrajšo pot med dvema krajema, lahko v
    resnici v trenutku poiščejo tudi najkrajšo pot prek celega sveta, če bi
    bilo ravno treba.</p>
    """
)
