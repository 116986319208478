val T07_XX_3C_skladisce_hlodov = Task("T07_XX_3C_skladisce_hlodov", "Skladišče hlodov",

description = """ 

<p>Bobri imajo skladišče hlodov s štirimi prostori; v vsakega gredo največ
   štirje hlodi. Imajo tudi robota, ki mu z ukazom x -> y (kjer sta x in y
   oznaki prostorov) naročijo, naj premakne zgornji hlod iz prostora x v
   prostor y. Če, recimo, napišejo D -> B bo premaknil zgornji hlod iz prostora
   D (to je hlod 8) v prostor B. Poleg tega imajo ukaz x -> T, ki premakne
   hlod iz prostora x na tovornjak. Tako, na primer, A -> T naloži zgornji
   hlod iz prostora A (to je hlod 1) na tovornjak.</p>

<p>Sitna stranka potrebuje hlode številka 5, 7 in 9. Mojster, ki upravlja s
strojem, je na malici. Znaš sprogramirati stroj, da bo naložil te hlode na
tovornjak?</p>

<div id="puzzleCont" class="center-block" style="width:540px;height:371px;border:5px solid white;border-radius: 10px;">
	<div id="puzzleLevelLayer01" style="width:530px;height:361px;position:absolute;background-color:white;"></div>
	<div id="puzzleLevelLayer02" style="width:530px;height:361px;position:absolute;background-image:url('tasks/T07_XX_3C_skladisce_hlodov/background.png');"></div>
	<div id="puzzleLevelLayer03" style="width:530px;height:361px;position:absolute;"></div>
	<div id="puzzleLevelLayer04" style="width:530px;height:361px;position:absolute;background-image:url('tasks/T07_XX_3C_skladisce_hlodov/truck.png');"></div>
	<div id="puzzleLevelLayer05" style="display:none;width:530px;height:361px;position:absolute;background-image:url('tasks/T07_XX_3C_skladisce_hlodov/fail.png');"></div>
</div>

<div class="center-block"  style="width:740px;padding-top:5px;padding-bottom:5px;padding-left:105px;">
	<button id="puzzleBtn05" puzzle-data="0" class="btn btn-default puzzleBtns01" style="width:50px; margin-right: 2px">A</button>
	<button id="puzzleBtn06" puzzle-data="1" class="btn btn-default puzzleBtns01" style="width:50px; margin-right: 2px">B</button>
	<button id="puzzleBtn07" puzzle-data="2" class="btn btn-default puzzleBtns01" style="width:50px; margin-right: 2px">C</button>
	<button id="puzzleBtn08" puzzle-data="3" class="btn btn-default puzzleBtns01" style="width:50px; margin-right: 6px">D</button>
	<button id="puzzleBtn09" puzzle-data="4" class="btn btn-default puzzleBtns01" style="width:50px; margin-right: 6px" disabled="disabled">T</button>
	&nbsp; |  &nbsp;
	<button id="puzzleBtn10" class="btn btn-default" disabled="disabled">Briši</button>
	<button id="puzzleBtn11" class="btn btn-default" disabled="disabled"><b>Pojdi!</b></button>
</div>

<div id="puzzleInstrs" class="center-block" style="width:540px;min-height:40px;border:5px solid black;border-radius:10px;background-color:black;color:#00FF00;padding-left:5px;padding-right:5px;font-family:monospace;font-weight:bold;" />
""",

runTask = eval("""{ run: (bQ) => {
	
	var puzzle_offset_x = 100;
	var puzzle_offset_y = 305;
	
	var puzzleLevel = [
		[2,1],
		[6,5,4,3],
		[7],
		[9,8],
		[]
	];
	
	var puzzleLabels = ["A","B","C","D","T"];
	
	var puzzle_button_step = 1;
	var puzzle_instructions = [];
	var puzzle_solution = "";

	function puzzleLoad() {
	
		var left, top, newHtml;
		
		// Load logs
		for(var i = 0; i < 4; i++) {
			for(var j = 0; j < puzzleLevel[i].length; j++) {
				left = puzzle_offset_x + i*57;
				top  = puzzle_offset_y - j*50;
				newHtml = '<div id="puzzleLog_'+puzzleLevel[i][j]+'" style="position:absolute;left:'+left+'px;top:'+top+'px;width:50px;height:50px;background-image:url(\''+'tasks/T07_XX_3C_skladisce_hlodov/log.png'+'\');color:black;padding:10px;font-weight:bold;font-size:20px;text-align:center;">'+puzzleLevel[i][j]+'</div>';
				$("#puzzleLevelLayer03").append(newHtml);
			}
		}
		
		// Load crane
		left = puzzle_offset_x + 10;
		top  = puzzle_offset_y - 303;
		newHtml = '<div id="puzzleCrane" style="position:absolute;left:'+left+'px;top:'+top+'px;width:30px;height:30px;background-image:url(\''+'tasks/T07_XX_3C_skladisce_hlodov/crane.png'+'\');" />';
		$("#puzzleLevelLayer03").append(newHtml);
		
		// Load grabber
		left = puzzle_offset_x + 10;
		top  = puzzle_offset_y - 250;
		newHtml = '<div id="puzzleGrab" style="position:absolute;left:'+left+'px;top:'+top+'px;width:30px;height:30px;background-image:url(\''+'tasks/T07_XX_3C_skladisce_hlodov/grab.png'+'\');" />';
		$("#puzzleLevelLayer03").append(newHtml);
		
		// Create cord
		
		left = puzzle_offset_x + 23;
		top  = puzzle_offset_y - 273;
		newHtml = '<div id="puzzleCord" style="position:absolute;left:'+left+'px;top:'+top+'px;width:4px;height:23px;background-color:gray;" />';
		$("#puzzleLevelLayer03").append(newHtml);

		// Set buttons
		$(".puzzleBtns01").click(function(){
		
			if(puzzle_button_step == 1) {
			
				puzzle_button_step = 2;
				var from = $(this).attr("puzzle-data");
				puzzle_instructions.push([from]);
				$("#puzzleBtn09").removeAttr("disabled");
				$(this).attr("disabled","disabled");
				$("#puzzleBtn10").removeAttr("disabled");
				$("#puzzleBtn11").attr("disabled","disabled");
				puzzleUpdateInsts();
				
			} else {
			
				puzzle_button_step = 1;
				var to = $(this).attr("puzzle-data");
				puzzle_instructions[puzzle_instructions.length-1].push([to]);
				$(".puzzleBtns01").removeAttr("disabled");
				$("#puzzleBtn11").removeAttr("disabled");
				$("#puzzleBtn09").attr("disabled","disabled");
				puzzleUpdateInsts();
			
			}
				
		});
		
		$("#puzzleBtn10").click(function(){
		
			puzzle_instructions.pop();
		
			if(puzzle_button_step == 1) puzzleUpdateInsts();
			else {
			
				puzzle_button_step = 1;
				var to = $(this).attr("puzzle-data");
				puzzle_instructions[puzzle_instructions.length-1].push([to]);
				$(".puzzleBtns01").removeAttr("disabled");
				$("#puzzleBtn11").removeAttr("disabled");
				puzzleUpdateInsts();
			
			}
		
		});
		
		$("#puzzleBtn11").click(function(){
			$(".puzzleBtns01").attr("disabled","disabled");
			$("#puzzleBtn10").attr("disabled","disabled");
			$("#puzzleBtn11").attr("disabled","disabled");
			puzzleAnimateStep(0);
		});
		
	}
	
	function puzzleUpdateInsts() {
	
		var newHtml = "";
		for(var i = 0; i < puzzle_instructions.length; i++) {
		
			var from = puzzleLabels[puzzle_instructions[i][0]];
			var to = puzzleLabels[puzzle_instructions[i][1]];
			if(typeof to == "undefined") to = "_";
			
			newHtml += '<div id="puzzleInst_'+i+'" style="display:inline-block;border:1px solid #00FF00;padding:5px;margin-right:5px;margin-top:5px;margin-bottom:5px;">' + from + '&nbsp;&rarr;&nbsp;' + to + '</div>';
		
		}
		
		$("#puzzleInstrs").html(newHtml);
	
	}
	
	function puzzleAnimateStep(n) {
	
		if(n == puzzle_instructions.length) setTimeout(puzzleCheckSolution,1500);
		else {
		
			$("#puzzleInst_"+n-1).css("color","#00FF00").css("background-color","black;");
			$("#puzzleInst_"+n).css("color","black").css("background-color","#00FF00");
		
			puzzleMoveLog(
				puzzle_instructions[n][0],
				puzzle_instructions[n][1],
				function(){
					puzzleAnimateStep(n+1);
				}
			);
		
		}
	
	}
	
	function puzzleMoveLog(from,to,callback) {
	
		var left, top, height, lp = 0, tp = 0;
		if(to == 4 && puzzleLevel[4].length == 0) {
			lp = 68;
			tp  = -165;
		}
		else if(to == 4 && puzzleLevel[4].length == 1) {
			lp = 12;
			tp  = -115;
		}
		else if(to == 4 && puzzleLevel[4].length == 2) {
			lp = 40;
			tp  = -105;
		}
	
		// Move crane to the chosen position
		left = puzzle_offset_x + 10 + from*57;
		$("#puzzleCrane, #puzzleGrab").animate({left: left},1000,"swing");
		left = puzzle_offset_x + 23 + from*57;
		$("#puzzleCord").animate({left: left},1000,"swing",function(){
		
			// Lower grab to top log
			var topLogPos = puzzleLevel[from].length-1;
			top = puzzle_offset_y - topLogPos*50 - 5;
			$("#puzzleGrab").animate({top: top},1000,"swing");
			height = puzzle_offset_y - 35 - topLogPos*50;
			$("#puzzleCord").animate({height: height},1000,"swing",function(){
			
				// Pick-up log
				
				if(puzzleLevel[from].length < 1) {
					puzzleFail();
					return false;
				}
				
				top = puzzle_offset_y - 250;
				$("#puzzleGrab").animate({top: top},1000,"swing");
				height = 23;
				$("#puzzleCord").animate({height: height},1000,"swing");
				top = puzzle_offset_y - 245;
				$("#puzzleLog_"+puzzleLevel[from][topLogPos]).animate({top: top},1000,"swing",function(){
				
					// Move crane to desired position
					left = puzzle_offset_x + to*57 + lp;
					$("#puzzleLog_"+puzzleLevel[from][topLogPos]).animate({left: left},1000,"swing");
					left = puzzle_offset_x + 10 + to*57 + lp;
					$("#puzzleCrane, #puzzleGrab").animate({left: left},1000,"swing");
					left = puzzle_offset_x + 23 + to*57 + lp;
					$("#puzzleCord").animate({left: left},1000,"swing",function(){
					
						// Drop log
						
						if( (puzzleLevel[to].length > 3) || (to == 4 && puzzleLevel[to].length > 2) ) {
							puzzleFail();
							return false;
						}
						
						var newLogPos = puzzleLevel[to].length;
						top = puzzle_offset_y - newLogPos*50 - 5 + tp;
						$("#puzzleGrab").animate({top: top},1000,"swing");
						top = puzzle_offset_y - newLogPos*50 + tp;
						$("#puzzleLog_"+puzzleLevel[from][topLogPos]).animate({top: top},1000,"swing");
						height = puzzle_offset_y - 35 - newLogPos*50 + tp;
						$("#puzzleCord").animate({height: height},1000,"swing",function(){
						
							// Move grab back up
							top = puzzle_offset_y - 250;
							$("#puzzleGrab").animate({top: top},1000,"swing");
							height = 23;
							$("#puzzleCord").animate({height: height},1000,"swing",function(){
							
								// Update puzzleLevel
								puzzleLevel[to].push(puzzleLevel[from].pop());
							
								// Run callback function
								callback();
							
							});							
						});						
					});				
				});			
			});		
		});
	
	}
	
	function puzzleFail() {
	
		$("#puzzleLevelLayer05").fadeIn();
		setTimeout(puzzleCheckSolution,1500);
	
	}
	
	function puzzleCheckSolution() {
	
		puzzle_solution = "";
		for(var i = 0; i < puzzle_instructions.length; i++) {
			puzzle_solution += puzzleLabels[puzzle_instructions[i][0]] + ">" + puzzleLabels[puzzle_instructions[i][1]];
			if(i != puzzle_instructions.length - 1) puzzle_solution += "\n";
		}
		
		bQ.checkSolution(puzzle_solution);
	
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{ solution ->
    val state = mapOf(
        'A' to mutableListOf(2, 1),
        'B' to mutableListOf(6, 5, 4, 3),
        'C' to mutableListOf(7),
        'D' to mutableListOf(9, 8),
        'T' to mutableListOf()
    )
    solution.split('\n').forEach {
        val src = state[it[0]]
        val dst = state[it[2]]
        if (src == null || dst == null || src.isEmpty() || dst.size == 4)
            return@chk false
        dst.add(src.removeAt(src.size - 1))
    }
    state['T']?.toSet() == setOf(5, 7, 9)
},

explanation="""
    <p>V nalogi si moral sestaviti preprost program v programskem jeziku, ki ima
    samo en ukaz - ukaz za prestavljanje hloda. Ob ukazu moramo povedati, od
    kod naj vzame hlod in kam naj ga odloži.</p>

    <p>Ker se program ni izvajal sproti, si si moral, ko si programiral, sam
    predstavljati, kako bo videti skladišče v določenem trenutku in kateri mora
    biti tvoj naslednji ukaz. Paziti si moral, da nisi odlagal hloda na kako
    mesto, ki je že polno, razmišljati si moral o tem, kateri hlod je v
    določenem trenutku na vrhu posameznega prostora...</p>

    <p>Tudi pravi programerji morajo programirati "na pamet" in si predstavljati
    celo situacije, ki se najbrž ne bodo nikoli zgodile.</p>"""

)
