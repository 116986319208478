val NoMessage = " "  // non-non-empty blank string!
val NoProblem = ""

open class Condition(val message: String = NoMessage): Checkable {
    open fun verify(gameState: GameState) = true
    open operator fun invoke(gameState: GameState) = if (verify(gameState)) NoProblem else message
    override fun checkConsistency() = ""
}


class Conditions(conditions: ArrayList<Condition>) : ArrayList<Condition>(conditions) {
    constructor(condition: Condition?):
            this(if (condition == null) arrayListOf() else arrayListOf(condition))
    constructor(vararg conditions: Condition): this(arrayListOf(*conditions))
    constructor(): this(arrayListOf())

    operator fun invoke(state: GameState)
            = asSequence().map { it(state) }.firstOrNull { it != NoProblem } ?: NoProblem
}


class Never(): Condition() {
    override fun verify(state: GameState) = false
}

class Always(): Condition() {
    override fun verify(state: GameState) = true
}

class Not(val condition: Condition, message: String = NoMessage)
        : Condition(if (message == NoMessage) condition.message else message) {
    override fun verify(state: GameState) = !condition.verify(state)
    override fun checkConsistency() = condition.checkConsistency()
}


open class _Compound(val conditions: Conditions, message: String = NoMessage): Condition(message) {
    override fun checkConsistency() = mergeConsistency(conditions)
}

class Or(conditions: Conditions, message: String = NoMessage): _Compound(conditions, message) {
    constructor(vararg conditions: Condition, message: String = NoMessage)
            : this(Conditions(*conditions), message)

    override fun verify(gameState: GameState) =
        conditions.isEmpty() || conditions.any { it.verify(gameState) }
}


class And(conditions: Conditions, message: String = NoMessage): _Compound(conditions, message) {
    constructor(vararg conditions: Condition, message: String = NoMessage)
            : this(Conditions(*conditions), message)

    private fun firstFailed(gameState: GameState)
            = conditions.asSequence().firstOrNull { !it.verify(gameState) }

    override fun verify(state: GameState) = firstFailed(state) == null

    override operator fun invoke(gameState: GameState) = firstFailed(gameState)?.message ?: NoProblem
}