val regionNarodni_Park = Region(
    "5_narodni_park",

    Location("0500_jasa", null, "Jasa", """
        Okrog široke gozdne jase so mogočna drevesa. Vendar gozd ni videti grozeče,
        kot na drugi strani hriba, preden si vstopil v labirint matematikov.
        
        Saj res, labirint matematikov - kje pa je? Moral bi biti nekje na zahodu, a
        tam stoji le skalna stena. V njej bi morala biti vrata, skozi katera si prišel
        iz labirinta, vendar jih ni videti nikjer. Čudno, čudno.""",

        "Z" to "0501_stor"),

    Location("0501_stor", null, "Štor", """
        <p>Na štoru sedi star polh in nekaj gloda.</p>
        <p><em>&mdash; Kam pa tebe nese, mladi bobrček?</br>
        &mdash; K veliki reki, gospod polh. A sem se izgubil. Mi lahko poveste, kje
        pravzaprav sem?</br>
        &mdash; Kako pa si prišel sem, če ne veš, kje si?</br>
        &mdash; Skozi labirint matematikov, gospod?</br>
        &mdash; Labirint matematikov? Tisti iz pravljice?</br>
        &mdash; Ne, ne, resničen je. Res, videl sem ga.</br>
        &mdash; No, če nočeš povedati, pa nič. Samo - da je nisi popihal od doma?</br>
        &mdash; Ne, gospod. Poslali so me pogledat, zakaj presiha velika reka.</br>
        &mdash; Velika reka? Da, tudi jaz sem nekaj slišal o tem. Kakorkoli, v narodnem
        parku si. Čisto na koncu, pravzaprav. Če hočeš priti ven, boš moral proti
        vzhodu. Tam je veliko parkirišče za avtobuse. Pelji se v Bobrnik, tam
        prideš do reke.</em></p>
        <p>Gospodu se zahvališ za nasvet. Ime mesta, kamor moraš, Bobrnik, pa si le
        zapiši. Če ga pozabiš, boš imel še težave.</p>""",

        "V" to "0500_jasa",
        "J" to "0502_gozd"),

    Location("0502_gozd", null, "Gozd", """
        Gost gozd. Ojoj, menda se ja ne bomo spet izgubljali?""",

        "S" to "0501_stor",
        "J" to "0503_nadzornik"),

    Location("0503_nadzornik", null, "Hišica", { state ->
        if (FlagIsSet("nadzornik_false").verify(state)) """
        <p>Pred malo leseno hišico si viha brke nadzornik parka.</p>
        <p>&emdash; Si že nazaj?</br>
        &emdash; Ničesar ni bilo, samo gozd.</br>
        &emdash; Potem pa ni bilo tam... Poskusi še enkrat!</p>"""
        else
        """
        <p>Pred malo leseno hišico si viha brke nadzornik parka. Pravi, da je v parku
        nekaj narobe, nekakšen hrup, potres ali nekaj takega.</p>
        """},

        "S" to "0502_gozd",
        "Z" to "0526_gozd",

        commands = Commands(
            StartTask("Pomagaj nadzorniku", "T09_XX_05_odkod_hrup",
                onSuccessActions = Actions(
                    Message("""Nadzornik te prosi, da stopiš tja in odkriješ, kaj se 
                               dogaja. Malo te je strah (kaj pa, če so sloni?), vendar 
                               vseeno privoliš, vzameš zemljevid in greš."""),
                    SetFlag("nadzornik_false", 0),
                    MoveTo("0504_gozd")),
                onFailureActions = Actions(
                    Message("""Nadzornik te prosi, da stopiš tja in odkriješ, kaj se 
                               dogaja. Malo te je strah (kaj pa, če so sloni?), vendar 
                               vseeno privoliš, vzameš zemljevid in greš."""),
                    SetFlag("nadzornik_false", 1),
                    MoveTo("0526_gozd")
                )
            )
        )
    ),

    Location("0504_gozd", null, "Gozd", """
        Gost gozd. V bližini je nekaj hrupnega, vendar zveni veselo:
        tu nekje se nekdo zabava. Poišči ga!""",

        "V" to "0505_gozd",
        "J" to "0506_gozd"),

    Location("0505_gozd", null, "Gozd", """
        Gost gozd. V bližini je nekaj hrupnega, vendar zveni veselo:
        tu nekje se nekdo zabava. Poišči ga!""",

        "Z" to "0504_gozd",
        "JZ" to "0506_gozd"),

    Location("0506_gozd", null, "Gozd", """
        Gost gozd. V bližini je nekaj hrupnega, vendar zveni veselo:
        tu nekje se nekdo zabava. Poišči ga!""",

        "S" to "0504_gozd",
        "SV" to "0505_gozd",
        "V" to "0507_skavti"),

    Location("0507_skavti", null, "Skavtski tabor", { state ->
        if (HasSolved("T10_LT_05_fotografije").verify(state)) """
        <p>Skavti so odšli proti vzhodu. Le brž za njimi! Ne boj se, ne boš se izgubil,
        saj so kar dobro potacali pot.</p>"""
        else """
        <p>Pa smo odkrili, odkod hrup! Skavti imajo tule svoj tabor. Nekam se jim mudi,
        zato že pred zajtrkom pospravljajo svoje šotore.</p>
        <p>Pozanimaš se, kam gredo, in izveš, da na vzhod. Rade volje te vzamejo s
        seboj (ti pa bi šel rade volje z njimi, saj ti je izgubljanja v gozdu že
        dovolj). Vendar bi radi prej preskusili tvoje orientacijske zmožnosti.</p>
        """},

        "Z" to "0506_gozd",
        "S" to "0505_gozd",

        commands = Commands(
            StartTask("Opravi preskus", "T10_LT_05_fotografije",
                onSuccessActions = Actions(
                    Message("Skavti se strinjajo, da greš z njimi.")
                ),
                onFailureActions = Actions(
                    Message("\"Če hočeš z nami, se boš moral bolj potruditi,\" ti rečejo skavti.")
                )
            ),

            Command("V",
                showOn=HasSolved("T10_LT_05_fotografije"),
                action=MoveTo("0508_zajtrk", "V")
            ),
            Command("V",
                showOn=Not(HasSolved("T10_LT_05_fotografije")),
                action=MoveTo("0527_gozd", "V")
            )
        )
    ),

    Location("0508_zajtrk", null, "Jasa", { state ->
        if (HasSolved("T08_XX_30_labirinti").verify(state))
            """<p>Skavti so odšli proti vzhodu. Le brž za njimi! Ne boj se, ne boš se izgubil,
               saj so kar dobro potacali pot.</p>"""
        else
            """
        <p>Skavti so se ustavili na jasi in ne gredo nikamor, dokler ne dobijo zajtrka.
        Voditelji so jim pustili hrano v labirintu, da bodo moral rod malih bobrčkov
        pokazati nekaj orientacijskih veščin. Po hrano naj bi šla Alenka, vendar pravi,
        da jo je strah in hoče, da greš z njo.</p>"""},

        "Z" to "0506_gozd",
        "S" to "0528_gozd",

        commands = Commands(
            StartTask("Pojdi z Alenko", "T08_XX_30_labirinti",
                onSuccessActions = Actions(
                    Message("""Z Alenko se zmagoslavno vrneta s hrano. Ko vaju ostali
                               zagledajo, Alenka hitro spusti tvojo roko. Škoda, vmes
                               ti je mogoče postalo malo všeč.""")
                ),
                onFailureActions = Actions(
                    Message("Po dolgem sprehodu se poklapano vrneta brez hrane.")
                )
            ),

            Command("V",
                showOn=HasSolved("T08_XX_30_labirinti"),
                action=MoveTo("0509_pot", "V")
            ),

            Command("V",
                showOn=Not(HasSolved("T08_XX_30_labirinti")),
                action=MoveTo("0528_gozd", "V")
            )
        )
    ),

    Location("0509_pot", null, "Gozdna pot", { state ->
        "<p>Na vzhod se izgublja zaraščena gozdna pot. Sam ji ne boš mogel slediti.</p>" +
                if (HasSolved("T13_JP_02_tuneli").verify(state)) ""
        else
        """<p>Na jasi pa so postavljeni neke zanimive cevi (mali skavtki trdijo, da
        so začarane!) in skavti se odpravljajo skoznje.</p>"""},

        "Z" to "0508_zajtrk",
        "J" to "0511_gozd",

        commands = Commands(
            StartTask("Vstopi v tunel", "T13_JP_02_tuneli",
                onSuccessActions = Actions(
                    MoveTo("0512_pot")
                ),
                onFailureActions = Actions(
                    Message("""Skoraj si že vstopil, a v zadnjem trenutku ugotovil,
                               da na ta način ne boš hodil za Alenko.""")
                )
            ),
            Command("V",
                showOn=HasSolved("T13_JP_02_tuneli"),
                action=MoveTo("0512_pot", "V")
            ),
            Command("V",
                showOn=Not(HasSolved("T13_JP_02_tuneli")),
                action=MoveTo("0510_god", "V")
            )
        )
    ),

    Location("0510_gozd", null, "Gozd",
        "Pa imamo. Zdaj si se pa izgubil! Mar ne bi šel raje s skavti?",

        "SV" to "0510_gozd",
        "S" to "0527_gozd",
        "J" to "0510_gozd",
        "JV" to "0509_pot"),

    Location("0511_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil! Mar ne bi šel raje s skavti?""",

        "S" to "0509_pot",
        "J" to "0511_gozd",
        "V" to "0510_gozd"),

    Location("0512_pot", null, "Pot", """
        <p>Načrt je uspel. Z Alenko hodita malo zadaj. Zdi se ti, da ptički danes nekam
        lepo pojejo. In da Alenki prav pristaja roža, ki si ji jo potisnil med
        lase. ("<em>Kaj pa reka? Nisi šel na pot, da bi odkril, kaj se dogaja z
        reko?</em>" te vpraša nek nadležen glas v glavi. "<em>Kakšna reka?</em>" mu
        odgovori drugi.)</p>
        <p>Ups, kam pa so šli ostali? Skavtov pa le ne izgubi izpred oči!</p>""",

        "S" to "0513_gozd",
        "SV" to "0513_gozd",
        "J" to "0513_gozd",
        "V" to "0514_slap"),

    Location("0513_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil! Mar ne bi šel raje s skavti?""",

        "J" to "0512_pot"),

    Location("0514_slap", null, "Gozdna pot", { state ->
        """<p>Kakšen slap! V življenju si, kot vsak bober, videl že veliko slapov. Ampak
        tako lepega pa še nikoli!</p>
        <p>Pred slapom je mala stojnica, na kateri starejša bobrovka prodaja preproste
        ogrlice.""" +
                if (HasSolved("T13_SI_04_ogrlice").verify(state)) ""
        else " Alenka se je zagledala vanje in se kar ne more odtrgati od njih."},

        "SV" to "0518_gozd",

        commands = Commands(
            StartTask("Kupi ogrlico", "T13_SI_04_ogrlice",
                onSuccessActions = Actions(
                    Message("Alenka je z ogrlico še lepša kot prej!")),
                onFailureActions = Actions(
                    Message("""Alenka si natakne ogrlico in se skoraj zaduši. Izbral
                               si premajhno ogrlico! Zdaj nujno potrebuje nekaj vode,
                               da se spravi k sebi. Brž odhitiš ponjo."""),
                    MoveTo("0515_reka")
                )
            ),
            Command("J",
                action=MoveTo("0516_sadovnjak", "J"),
                condition=HasSolved(
                    "T13_SI_04_ogrlice",
                    "Alenka še vedno gleda ogrlice. Brez nje pa res ne moreš naprej!"
                )
            )
        )
    ),

    Location("0515_reka", null, "Reka",
        "<p>V reki si namaka tace bober z dvema lončkoma.</p>",

        commands = Commands(
            StartTask("Izposodi si lonček", "T13_SI_06_pretakanje",
                showOn = Always(),
                onSuccessActions = Actions(
                    Message("""Uspelo ti je! Bober ti posodi lonček, ti ga brž 
                               napolniš z vodo in tečeš nazaj k Alenki."""),
                    MoveTo("0514_slap")
                ),
                onFailureActions = Actions(
                    Message("Joj, ne! Pohiti!")
                )
            )
        )
    ),

    Location("0516_sadovnjak", null, "Gozdna pot", { state ->
        if (HasSolved("T10_LT_06_pot_do_jabolka").verify(state))
            "<p>Na jablani v sadovnjaku zadovoljno prede črv. Skavti pa so že odšli nekam proti vzhodu.</p>"
        else """<p>Pa sta z Alenko spet dohitela skavte. Vsi so zbrani okrog drevesa v
                sadovnjaku in bolščijo v njegovo deblo.</p>""" },

        "S" to "0514_slap",

        commands = Commands(
            StartTask("Bolšči v deblo", "T10_LT_06_pot_do_jabolka",
                onSuccessActions = Actions(
                    Message("Črv se ti prijazno zahvali in se loti dela.")),
                onFailureActions = Actions(
                    Message("Ubogi črviček poklapano zleze tja, kjer je bil na začetku.")
                )
            ),

            Command("V",
                showOn=HasSolved("T10_LT_06_pot_do_jabolka"),
                action=MoveTo("0519_avtobusi", "V")
            ),
            Command("V",
                showOn=Not(HasSolved("T10_LT_06_pot_do_jabolka")),
                action=MoveTo("0520_gozd", "V")
            )
        )
    ),

    Location("0517_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil!""",

        "V" to "0518_gozd",
        "S" to "0518_gozd"),

    Location("0518_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil!""",

        "Z" to "0517_gozd",
        "S" to "0517_gozd",
        "JZ" to "0514_slap",
        "J" to "0518_gozd"),

    Location("0519_avtobusi", null, "Avtobusno parkirišče", { state -> """
        Na velikem avtobusnem parkirišču ob vhodu v park stojijo številni avtobusi, ki
        vozijo v vse smeri. Še dobro, da si na začetku avtobusa izvedel, kam se moraš
        peljati. Saj si si zapisal, ne?""" +
        if (FlagIsSet("alenka_explained").verify(state)) ""
        else """
            Tudi skavti se razidejo. Alenka bi morala domov ... vendar se odloči, da ne bo
            nič hudega, če ostane še nekaj dni s teboj, zato se pritihotapi nazaj k tebi."""},

        "Z" to "0516_sadovnjak",

        commands = Commands(
            StartTask("Izberi avtobus", "T09_XX_35_piskajoci_bobri",
                onSuccessActions = Actions(
                    SetFlag("alenka_explained"),
                    Message("""Za Alenko se usedeta na avtobus in odpeljeta. Ker se
                               šoferju smilita, vaju vzame kar zastonj."""),
                    MoveTo("0600_sredisce")
                ),
                onFailureActions= Actions(
                    SetFlag("alenka_explained"),
                    Message("""Za Alenko se usedeta na avtobus in odpeljeta. Ker se "
                              "šoferju smilita, vaju vzame kar zastonj."""),
                    MoveTo("0522_postajalisce")
                )
            )
        )
    ),

    Location("0520_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil!""",

        "J" to "0516_sadovnjak",
        "Z" to "0521_gozd"),

    Location("0521_gozd", null, "Gozd", """
        Pa imamo. Zdaj si se pa izgubil!""",

        "V" to "0516_sadovnjak",
        "J" to "0518_gozd"),

    Location("0522_postajalisce", null, "Avtobusno postajališče", """
        Pripeljal si se, kamor vozi avtobus, na katerega si vstopil. Vendar to ni
        videti kot naselje, ki
        ti ga je opisal oni mož na štoru. Na srečo pa ni bilo videti, da bi se peljal
        daleč, tako da bosta z Alenko hitro našla pot nazaj do vhoda v park, kjer vaju
        čaka pravi avtobus.""",

        "V" to "0523_gozd"
    ),

    Location("0523_gozd", null, "Gost gozd",
        "Gost gozd. Vendar vhod v park, kjer vaju čakajo avtobusi, ne more biti daleč.",

        "V" to "0523_gozd",
        "J" to "0524_gozd"
    ),

    Location("0524_gozd", null, "Gost gozd",
        "Gost gozd. Vendar vhod v park, kjer vaju čakajo avtobusi, ne more biti daleč.",

        "S" to "0523_gozd",
        "SV" to "0523_gozd",
        "J" to "0524_gozd",
        "V" to "0525_gozd"
    ),

    Location("0525_gozd", null, "Gost gozd",
        "Gost gozd. Vendar vhod v park, kjer vaju čakajo avtobusi, ne more biti daleč.",

        "Z" to "0524_gozd",
        "JZ" to "0524_gozd",
        "V" to "0523_gozd",
        "J" to "0519_avtobusi"
    ),

    Location("0526_gozd", null, "Gozd",
        "Gost gozd. Samo gozd. Prav ničesar drugega kot gozd.",

        "V" to "0503_nadzornik"
    ),

    Location("0527_gozd", null, "Gozd",
        "Pa imamo. Zdaj si se pa izgubil! Mar ne bi šel raje s skavti?",

        "Z" to "0504_gozd"
    ),

    Location("0528_gozd", null, "Gozd",
        "Pa imamo. Zdaj si se pa izgubil! Mar ne bi šel raje s skavti?",

        "Z" to "0528_gozd",
        "JV" to "0528_gozd",
        "J" to "0508_zajtrk"
    ),

    hasMap = false
)

