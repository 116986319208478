val T08_XX_30_labirinti = Task("T08_XX_30_labirinti", "Iskanje po labirintih",

description = """ 

<p>Voditelji so skrili hrano v štiri labirinte. Zadošča, da gresta v enega od
    njih. Da bi si ga lažje izbrala, so vama pokazali zemljevide, vendar jih ne
    bosta smela vzeti s seboj - zapomniti pa si jih tudi ne moreta.</p>

<p>Odločila sta se, da bosta hodila skozi labirint tako, da se bosta vedno
    držala desne stene, tako da se ne bosta izgubila.  Hrano najdeta le, če
    stopita v sobo s hrano. V katerem labirintu bosta, če se držita desne
    stene, v resnici prišla do hrane?</p>

<div class="center-block" style="width: 482px">
<div id="maze-1" style="width: 221px; height: 279px; float: left; margin: 10px">
	<img src="tasks/T08_XX_30_labirinti/maze_a.svg" %}" style="width: 221px; height: 279px;
	          position:absolute;pointer-events:none;">
    <img id="box-1" src="tasks/T08_XX_30_labirinti/box.svg" style="position: relative; visibility: hidden"/>
</div>

<div id="maze-2" style="width: 221px; height: 279px; float: left; margin: 10px">
	<img src="tasks/T08_XX_30_labirinti/maze_b.svg" style="width: 221px; height: 279px;
	          position:absolute;pointer-events:none;">
    <img id="box-2" src="tasks/T08_XX_30_labirinti/box.svg" style="position: relative; visibility: hidden"/>
</div>
<br/>
<div id="maze-3" style="width: 221px; height: 279px; float: left; margin: 10px">
	<img src="tasks/T08_XX_30_labirinti/maze_c.svg" style="width: 221px; height: 279px;
	          position:absolute;pointer-events:none;">
    <img id="box-3" src="tasks/T08_XX_30_labirinti/box.svg" style="position: relative; visibility: hidden"/>
</div>

<div id="maze-4" style="width: 221px; height: 279px; float: left; margin: 10px">
	<img src="tasks/T08_XX_30_labirinti/maze_d.svg" style="width: 221px; height: 279px;
	          position:absolute;pointer-events:none;">
    <img id="box-4" src="tasks/T08_XX_30_labirinti/box.svg" style="position: relative; visibility: hidden;"/>
</div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var corr;
    var positions = [
        null,
        [[[111, 133], [153, 82], [48, 93]],
         [[90, 198], [48, 68], [69, 2], [132, 173]]],
        [[[174, 119], [174, 67], [132, 40], [47, 147]],
         [[90, 121], [71, 81], [113, 81], [90, 250]]],
        [[[28, 132], [173, 92], [153, 53], [48, 95]],
         [[26, 83], [69, 2], [48, 250], [90, 120]]],
        [[[69, 131], [173, 41], [69, 106], [89, 172]],
         [[89, 93], [6, 69], [69, 211], [132, 251]]]
    ]

	function puzzleLoad() {
        corr = _.random(1, 4);
        for(var maze = 1; maze <= 4; maze++) {
            var row = positions[maze][maze == corr ? 1 : 0];
            var coords = _.sample(row);
            $('#box-' + maze).css('top', coords[1]).css('left', coords[0]).css('visibility', 'visible').click(submit);
            $('#maze-' + maze).click(submit);
        }
    }

    function submit(e) {
        bQ.checkSolution($(this).attr('id').slice(-1) + ' ' + (4 - corr));
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = {
    try {
        val (x, y) = it.trim().split(' ').map { it.trim().toInt() }
        x == 4 - y
    }
    catch (e: Exception) { false }
},

explanation="""
    <img style="float: left; margin-right: 20px"
     src="tasks/T08_XX_30_labirinti/maze_a-path.svg"/>

    <p>Pot se drži zunanjega zidu. Pripelje nas lahko v katerikoli del labirinta,
    tudi v sredino, nikoli pa se ne ovije okrog "otokov", to je, zidov, ki se
    ne dotikajo zunanjega zidu.</p>

    <p>Če torej sledimo desni (ali levi) steni, se ne bomo izgubili in bomo gotovo
    prej ko slej prišli iz labirinta, ni pa nujno, da bomo obiskali vsa mesta v
    njem.</p>
    
    <p>Kaj pa, če se naša pot začne sredi labirinta in bi radi prišli ven? Kaj se
    bo zgodilo, če se držimo desne stene? Tedaj je vse odvisno od tega, kje začnemo
    in kam smo obrnjeni. Če se primemo stene, ki pripada "otoku", se bomo vrteli
    okrog otoka.</p>
    
    <img style="float: left; margin-right: 20px"
         src="tasks/T08_XX_30_labirinti/maze_a-isle.svg"/>
    
    <p>Nekateri labirinti so sestavljeni tako, da nimajo otokov. V tem primeru bomo
        tako, da se držimo desne stene, vedno prehodili ves labirint. Če začnemo
        kje znotraj labirinta, pa nas bo desna (ali desna) stena vedno pripeljala
        ven.</p>
    
    <p style="clear: both">&nbsp;</p>"""
)
