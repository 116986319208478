import react.ReactElement

open class Action: Checkable {
    open operator fun invoke(state: GameState) {}
    override fun checkConsistency() = ""
}


class Actions(actions: ArrayList<Action>) : ArrayList<Action>(actions) {
    // Todo: can this be an automated cast?
    constructor(action: Action): this(arrayListOf(action))
    constructor(vararg actions: Action): this(arrayListOf(*actions))
    constructor(): this(arrayListOf())
}


open class Message(val message: ReactElement): Action() {
    constructor(message: String): this(renderHtml(message))
}
