val T07_XX_28_ponarejeni_kovanec = Task("T07_XX_28_ponarejeni_kovanec", "Ponarejeni kovanec",

description = """
<p>Trgovec iz Bobrograda je prejel štiri zlatnike. Nekdo mu je prišepnil, da je
    eden ponarejen in lažji. Ponaredek bo odkril z dvema tehtanjema. Takole
    razmišlja:</p>

<ul id="question">
    <li>Če ugotovim, da sta prvi in tretji kovanec enako težka, <br/>
        potem preverim, ali sta prvi in drugi kovanec enako težka;
        <ul><li>če sta, potem je ponarejen
                <select id="ans-a" class="selectpicker"><option>Izberi...</option><option>prvi</option><option>drugi</option><option>tretji</option><option>četrti</option></select>
                kovanec!</li>
            <li>sicer pa je ponarejen
                <select id="ans-b" class="selectpicker"><option>Izberi...</option><option>prvi</option><option>drugi</option><option>tretji</option><option>četrti</option></select>
                kovanec!</li>
</ul></li>
    <li>Sicer (torej, če prvi in tretji kovanec nista enako težka), <br/>
        potem preverim, ali sta prvi in drugi kovanec enako težka;
        <ul><li>če sta, potem je ponarejen
                <select id="ans-c" class="selectpicker"><option>Izberi...</option><option>prvi</option><option>drugi</option><option>tretji</option><option>četrti</option></select>
                kovanec!</li>
            <li>sicer pa je ponarejen
                <select id="ans-d" class="selectpicker"><option>Izberi...</option><option>prvi</option><option>drugi</option><option>tretji</option><option>četrti</option></select>
                kovanec!</li>
        </ul>
    </li>
</ul>

<div style="float: left">
        <button id="submit" type="submit" class="btn btn-default"><b>Končano</b></button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var not_done = true;

    function puzzleLoad() {
        $("select").change(select_changed);
        $('#submit').toggleClass('disabled', true);
        $("#submit").click(checkSolution);
    }

    function gather() {
        return _.map($('#question').find(':selected'),
                     function(x) { return x.text; })
    }

    function select_changed() {
        not_done = _.any(gather(),
                         function (x) { return x == "Izberi..."});
        $('#submit').toggleClass('disabled', not_done);
    }

	function checkSolution() {
        if (!not_done) {
            bQ.checkSolution(gather().join(' '));
        }
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "četrti drugi tretji prvi" },

explanation="""
    <p>Ponarejeni kovanec je tisti, ki ni težak toliko, kot ostali trije. Poglejmo
    vse štiri možnosti.</p>
    
    <ul>
        <li><em>Če ugotovim, da sta prvi in tretji kovanec enako težka,</em><br/>
            potem vemo, da nista ponarejena, temveč je tisti, ki se razlikuje od
            ostalih treh, bodisi drugi bodisi četrti;<br/>
            <em>potem preverim, ali sta prvi in drugi kovanec enako težka;</em>
            <ul><li><em>če sta, potem</em> ... vemo, da so prvi trije kovanci enako
                težki, torej je ponarejen četrti;</li>
                <li><em>sicer pa</em> ... vemo, da je ponarejen drugi, saj ni enako
                    težak kot (neponarejena) prva dva;</li>
            </ul></li>
        <li><em>Sicer (torej, če prvi in tretji kovanec nista enako težka),</em><br/>
            potem vemo, da je ponarejen eden od njiju (prvi ali tretji),
            drugi in četri pa sta prava;<br/>
            <em>potem preverim, ali sta prvi in drugi kovanec enako težka;</em>
            <ul><li><em>če sta, potem je ponarejen</em>
                tretji, saj je prvi enako težak kot drugi, torej ni ponarejen,
                <li><em>sicer pa je ponarejen</em>
                prvi, saj ni enako težak kot neponarejeni drugi.
            </ul>
        </li>
    </ul>
    
    <p style="margin-top: 20px;">Besedilo naloge spominja na pogojne stavke, ki jih uporabljamo v programih.
    V večini programskih jezikov računalnik izvaja zaporedje ukazov, s pogojnimi
    stavki pa, kadar je potrebno, določimo, katero zaporedje naj se izvede v katerem
    primeru.</p>
    
    <p>Po drugi strani pa si moral pri reševanju naloge logično razmišljati. Tako
    kot računalnikarji.</p>"""
)
