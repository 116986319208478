import kotlin.random.Random

val people = listOf("Jaroslav", "Romeo", "Rudolf", "Jan", "Janez",
                    "Romana", "Jaro", "Jaromir", "Rok", "Jos", "Robi",
                    "Robert", "Janko", "Roman", "Jakob", "Jana")

class AssignPerson: Action() {
    override operator fun invoke(state: GameState) {
        state.flags["komu-zvoni"] = Random.nextInt(people.size)
    }
}

val regionMesto = Region(
    "2_mesto",

    Location("0200_mesto", Coordinates(252, 364), "Mesto", { state ->
        """<p>Na severu se začenja veliko mesto. Še nikoli nisi videl toliko prometa,
        toliko bobrov, toliko trgovin in izložb.</p>""" +
                if (HasSolved("T09_XX_07_cestna_razsvetljava").verify(state)) "" else
                    """<p> Na ulici stoji nekaj mestnih mož, ki živahno razpravljajo o neki pomembni
        stvari.</p>"""},

        "SZ" to "0208_blok_racunalnikarjev",
        "V" to "0201_posta",
        "S" to "0202_glavna_ulica",

        commands = Commands(
            StartTask("Govori z možmi", "T09_XX_07_cestna_razsvetljava",
                onSuccessActions = Actions(
                    Message("""Mestnim možem je tvoja rešitev všeč. Bobri so hitri in
                               mesto je že isti večer razsvetljeno.""")
                ),
                onFailureActions = Actions(
                    Message("""Mestni možje si ogledajo tvoj načrt in ocenijo, da je
                               predrag. Lahko poiščeš kakšen cenejši način za
                               osvetlitev mesta?""")
                )
            )
        ),

        autoCommands = Commands(
            Command("",
                condition=FlagNotSet("person-assigned"),
                actions=Actions(
                    AssignPerson(),
                    SetFlag("person-assigned"))
            )
        )
    ),

    Location("0201_posta", Coordinates(427, 321), "Pošta", { state -> """
        <p>Nenadoma se znajdeš med kupom bobrov oblečenih v modre uniforme, ki se vozijo
        z majhnimi kolesi, na katerih so obešene velike torbe. Poštarji!</p>""" +
        if (HasSolved("T11_NL_11_cudni_postar").verify(state)) "" else """
        <p>Eden od njih ne gre nikamor, le zraven svojega kolesa stoji in bulji v list
        papirja, ki ga drži v rokah.</p>"""},

        "Z" to "0200_mesto",
        "Vstopi" to "0205_na_posti",

        commands = Commands(
            StartTask("Pomagaj poštarju", "T11_NL_11_cudni_postar",
                onSuccessActions = Actions(
                    Message("""Poštar se razveseli tvoje rešitve. Preden oddirka po
                                svoji poti, ti hvaležno podari znamko."""),
                    GetItem("poštna znamka")
                ),
                onFailureActions = Actions(Message("Takole ne bo šlo."))
            )
        )
    ),

    Location("0202_glavna_ulica", Coordinates(250, 284), "Glavna ulica", """
        Junaško korakaš po glavni ulici skozi mesto. Na obeh straneh ulice je posajen
        lipov drevored. Zobje te pošteno zasrbijo, a še pravočasno opaziš napis:
        glodanje prepovedano.""",

        "SV" to "0207_letalisce",
        "S" to "0203_glavna_ulica",
        "J" to "0200_mesto"
    ),

    Location("0203_glavna_ulica", Coordinates(250, 214), "Glavna ulica", { state ->
        "<p>Široke aleje kar noče biti konca." +
                if (HasSolved("T13_HU_01_avtomat_za_sladoled").verify(state)
                    && HasSolved("T13_SI_14_natakar").verify(state)) " Nekoliko te že bolijo noge.</p>"
                else " Nekoliko te že bolijo noge, pa še žeja te muči.</p>"},

        "S" to "0204_slascicarna",
        "J" to "0202_glavna_ulica"
    ),

    Location("0204_slascicarna", Coordinates(250, 176), "Slaščičarna", { state ->
        "<p>Na klopci sedi bober tvojih let in liže sladoled." +
                if (HasSolved("T13_HU_01_avtomat_za_sladoled").verify(state)) "" else """"
        Odlično, tole bo rešitev za tvojo žejo: sladoled! Bober s sladoledom ti pove,
        da so tule štiri slaščičarne: v eni imajo dober sladoled, sladoled v ostalih
        treh pa je bolj na "kdor ga kupi, se zastrupi"."""},

        "J" to "0203_glavna_ulica",

        commands = Commands(
            Command("S",
                condition=FlagIsSet(
                    "pojedel_sladoled",
                    "Ulica je še dolga, dolga, ti pa si tako žejen..."),
                action=MoveTo("0210_glavna_ulica", "S")
            ),
            StartTask("V slaščičarno", "T13_HU_01_avtomat_za_sladoled",
                showOn = Always(),
                onSuccessActions = Actions(
                    SetFlag("pravi_sladoled"),
                    MoveTo("0206_v_slascicarni")
                ),
                onFailureActions = Actions(
                    SetFlag("pravi_sladoled", 0),
                    MoveTo("0206_v_slascicarni")
                )
            )
        )
    ),

    Location("0205_na_posti", Coordinates(428, 309), "Pošta", { state ->
        if (HasItem("letalska vozovnica").verify(state))
            """<p>V veliki poštni stavbi prodajajo kup zanimivih reči, vendar si jih ne moreš
                  privoščiti, saj si porabil ves denar za letalsko vozovnico.</p>"""
        else
            """<p>V veliki poštni stavbi prodajajo kup zanimivih reči, vendar si jih ne moreš
        privoščiti, saj potrebuješ denar za letalo.<p>
        """ +
        if (HasSolved("T09_XX_43_razglednica").verify(state)) ""
        else """<p>Žalosten se spomniš, da potrebuješ razglednico za mamo. Tedaj opaziš mizico z
        napisom: "Natisni svojo razglednico - brezplačno!".</p>"""},

        "Ven" to "0201_posta",

        commands = Commands(
            StartTask("Natisni razglednico", "T09_XX_43_razglednica",
                conditions=Conditions(Not(HasSolved("T09_XX_43_razglednica"), "Saj si jo že natisnil!")),
                onSuccessActions = Actions(
                    Message("Kako lepa razglednica! Mama je bo gotovo vesela."),
                    GetItem("razglednica")
                ),
                onFailureActions = Actions(
                    Message("Tole ni bilo čisto po načrtih. Poskusi ponovno.")
                )
            ),
            Command("Pošlji razglednico",
                conditions=Conditions(
                    HasItem("razglednica", "Kaj pa boš poslal? Saj nimaš razglednice!"),
                    HasItem("poštna znamka",
                        "Preden oddaš razglednico, boš potreboval še poštno znamko."),
                    FlagNotSet("pisal mami", "Kako - še eno?")),
                actions=Actions(
                    DropItem("razglednica"),
                    DropItem("poštna znamka"),
                    SetFlag("pisal mami"),
                    Message("Razglednica je oddana. Gremo naprej!")
                )
            )
        )
    ),

    Location("0206_v_slascicarni", Coordinates(283, 163), "V slaščičarni", """
        V slaščičarni imajo vse vrste tort, kremnih rezin, lesnih rezin, orehove lupine,
        sveže lubje ... tebe pa zanima le sladoled. Vendar nič ni zastonj. Sladoled
        dobiš, če boš pomagal rešiti malo zagato, ki se je zgodila natakarju.""",

        commands = Commands(
            StartTask("Pomagaj natakarju", "T13_SI_14_natakar",
                showOn=Always(),
                onSuccessActions = Actions(
                    Message("Odžejaš se s sladoledom in že si nared, da greš naprej."),
                    SetFlag("pojedel_sladoled")),
                onFailureActions = Actions(
                    Message("Ni uspelo. Premisli ponovno."))
            ),

            Command("Ven",
                showOn = And(FlagIsSet("pojedel_sladoled"), FlagNotSet("pravi_sladoled")),
                actions = Actions(
                    SetFlag("pojedel_sladoled", 0),
                    Message("Postalo ti je slabo. Si prepričan, da si šel v pravo slaščičarno?"),
                    MoveTo("0212_bolnica")
                )
            ),

            Command("Ven",
                showOn = Or(FlagNotSet("pojedel_sladoled"), FlagIsSet("pravi_sladoled")),
                action = MoveTo("0204_slascicarna")
            )
        )
    ),

    Location("0207_letalisce", Coordinates(379, 253), "Letališče", { state ->
        val name = people[state.flags["komu-zvoni"]!!]!!
        val fem = name.last() == 'a'
        """
        <p>Z malega letališča vzletajo letala na panoramske polete ob reki. Še malo, pa
        boš izvedel, kaj se dogaja z njo!</p>""" +
            if (   FlagIsSet("ve-za-podrazitev").verify(state)
                && !(HasItem("vozovnica za letalo").verify(state)
                     || HasItem("štirinajst cekinov").verify(state)))
                """Nekdo opazi tvojo zadrego. 
                   "<em>${if (fem) "Moja znanka" else "Moj znanec"} ${name}
                   ima morda delo zate. Poišči ${if (fem) "jo" else "ga"}.</em>"
                   """
            else ""},

        "JZ" to "0202_glavna_ulica",

        commands = Commands(
            Command("Kupi vozovnico",
                showOn=Not(Or(HasItem("vozovnica za letalo"), HasItem("štirinajst cekinov"))),
                actions=Actions(
                    Message("""Prijazna prodajalka ti s prijaznim nasmehom (ah, kako lepe zobe
                               ima ta bobrovka!) pove, da so se vozovnice
                               podražile: potreboval boš še tri cekine."""),
                    SetFlag("ve-za-podrazitev")
                )
            ),
            Command("Kupi vozovnico",
                showOn=And(Not(HasItem("vozovnica za letalo")), HasItem("štirinajst cekinov")),
                actions=Actions(
                    Message("Kupil si vozovnico."),
                    DropItem("štirinajst cekinov"),
                    GetItem("vozovnica za letalo"))
            ),
            StartTask("Odleti", "T14_XX_01_letalisce",
                conditions=Conditions(
                    HasItem("vozovnica za letalo", "Kje pa imaš vozovnico?"),
                    FlagIsSet("pilot ima kljuce",
                        "Pilot ne more prižgati letala, ker mu je nekdo ukradel ključe. " +
                                "Policiji ne uspe najti tatu."),
                    FlagIsSet("pisal mami", "Ne boš poslal mami razglednice iz mesta? Saj si ji obljubil!")
                ),
                onSuccessActions = Actions(
                    Message("Vstopiš na letalo. Vreme je lepo in reka z okolico se bosta odlično videla."),
                    Message("""Letalo se nenadoma začne čudno tresti. Nekaj pošteno poči,
                               letalo zajame dim. Stevardesa, bleda kot zid, razdeli potnikom
                               padala. Skočiš iz letala, padalo se odpre. Ko se počasi spuščaš
                               proti tlom, si misliš "Toliko o lepem razgledu.""""),
                    DropItem("vozovnica za letalo"),
                    MoveTo("0300_pristanek")
                ),
                onFailureActions = Actions(
                    Message("Če ti ne uspe razporediti vseh potnikov, ne boš mogel odleteti.")
                )
            )
        )
    ),

    Location("0208_blok_racunalnikarjev", Coordinates(115, 339), "Stanovanjski blok", """
        V stanovanjskem bloku živijo sami čudni strici. Menda so računalnikarji.""",

        "JV" to "0200_mesto",
        "SV" to "0209_dolgocasni_blok",
        "SZ" to "0214_sola",

        commands = Commands(
            StartTask("Pozvoni", "T09_XX_33_zvonci",
                onSuccessActions = Actions(
                    Message("""Vrata pride odpret prijazen bober. Poveš, mu da
                               iščeš kako delo. Napoti te k bližnjemu dolgočasnemu
                               stanovanjskemu bloku, ki bi ga bilo treba prepleskati.
                               "Povej, da sem te jaz poslal, pa te bodo zaposlili,"
                               ti naroči."""),
                    SetFlag("pozvonil")),
                onFailureActions = Actions(
                    Message("""Od nekod se zasliši jezen glas: "Ta mularija se spet
                               igra z zvonci. Zdaj imam pa dovolj!" Si prepričan, da si
                               pozvonil pri pravem bobru?"""),
                    Message("""Pred blokom se ustavi policijski avto. Mrka policista
                               te odpeljeta s seboj."""),
                    MoveTo("0215_zapor")
                )
            )
        )
    ),

    Location("0209_dolgocasni_blok", Coordinates(164, 315), "Stanovanjski blok", { state ->
        if (HasSolved("T10_NL_07_pleskar").verify(state))
            "Kako lep stanovanjski blok! Prav imenitno si ga pobarval."
        else "Kakšen dolgočasen stanovanjski blok! Tole bi bilo res potrebno malo prepleskati." },

        "JZ" to "0208_blok_racunalnikarjev",

        commands = Commands(
            StartTask("Prepleskaj blok", "T10_NL_07_pleskar",
                showOn=FlagIsSet("pozvonil"),
                conditions=Conditions(
                    Not(HasSolved("T10_NL_07_pleskar", "Blok si pobarval tako temeljito, da bo lep še vsaj sedem let")),
                    HasItem("čopič", "S čim boš pa barval? Potrebno si bo priskrbeti čopič."),
                    HasSolved("T09_XX_07_cestna_razsvetljava",
                        """Hotel si začeti barvati, a so te spodili, češ da jim
                                 podnevi že ne boš delal napote s svojimi piskri in
                                 čopiči. Vrnil si se zvečer, vendar je bilo tako temno,
                                 da nisi mogel delati. Zakaj to nesrečno mesto nima
                                 javne razsvetljave?!""")
                ),
                onSuccessActions = Actions(
                    DropItem("enajst cekinov"),
                    DropItem("čopič"),
                    GetItem("štirinajst cekinov"),
                    Message("Hvaležni stanovalci ti plačajo tri cekine.")
                ),
                onFailureActions = Actions(
                    Message("Narobe! Hitro spereš barvo in upaš, da ti bodo pustili poskusiti ponovno.")
                )
            )
        )
    ),

    Location("0210_glavna_ulica", Coordinates(247, 63), "Glavna ulica", """
        Na severnem koncu mesta glavna ulica zavija proti zahodu.""",

        "J" to "0204_slascicarna",
        "Z" to "0211_avtobusna_postaja"
    ),

    Location("0211_avtobusna_postaja", Coordinates(126, 65), "Avtobusna postaja", { state ->
        "<p>Na veliki tabli so zapisani odhodi in prihodi avtobusov." +
                if (HasSolved("T09_XX_14_lov_na_avtobuse").verify(state)) "" else
                    " Pod tablo stoji objokana bobrovka Mimi, ki bo zamudila šolo." },

        "V" to "0210_glavna_ulica",

        commands = Commands(
            StartTask("Pomagaj bobrovki", "T09_XX_14_lov_na_avtobuse",
                onSuccessActions = Actions(
                    Message("""Policist, ki stoji na postaji, opazi tvojo zvitost in
                               te odpelje s seboj. "Nič se ne boj," ti zagotovi. "Radi bi te
                               prosili za pomoč."""),
                    MoveTo("0213_policija")
                ),
                onFailureActions = Actions(
                    Message("""Mimi razmisli rešitev, ki si jo predlagal in vidi, da
                               bo na ta način prišla prepozno. Prepričana, da bo gotovo
                               zamudila, zajoka še glasneje. Pohiti, morda je še čas!""")
                )
            ),
            Command("Na policijsko postajo",
                action=MoveTo("0213_policija"),
                showOn=HasSolved("T09_XX_14_lov_na_avtobuse")
            )
        )
    ),

    Location("0212_bolnica", Coordinates(394, 117), "Bolnica", """
        Lepa reč: tvoje sladoledanje se je končalo v bolnišnici, kjer si celo noč zavzeto bruhal.""",
        commands = Commands(
            StartTask("Ven", "T13_SI_09_vrtavka",
                onSuccessActions = Actions(
                    Message(""""Drugič pa le glej, kam greš na sladoled!" se ti nasmehne
                             prijazna zdravnica."""),
                    MoveTo("0204_slascicarna")),
                onFailureActions = Actions(
                    Message("Dokler ne boš zmogel rešiti te naloge, nisi dovolj pri sebi, da greš iz bolnice.")
                )
            )
        )
    ),

    Location("0213_policija", Coordinates(77, 152), "Policijska postaja", { state -> """
        </p>Malo te je strah, saj še nikoli nisi bil na policijski postaji. Vendar so
        policisti videti kar prijazni.""" +
            if (HasSolved("T09_XX_24_detektivska_zgodba").verify(state))
                " Odkar si jim pomagal razrešiti problem ukradenih ključev pa ste sploh prijatelji.</p>"
            else
                """</p>
        Postrežejo ti z vročo čokolado in ti povedo, kaj jih muči: pred dnevi so pilotu
        letala ukradli ključe. Vse podatke, ki jih
        potrebujejo, imajo, potrebovali bi le nekoga, ki bi bil dovolj pameten, da bi
        jih razvozlal. Ko so videli, kako spretno si pomagal punčki, ki je zamujala
        šolo, so pomislili - ta bo znal!</p>"""},

        "SV" to "0211_avtobusna_postaja",

        commands = Commands(
            StartTask("Poišči tatu", "T09_XX_24_detektivska_zgodba",
                onSuccessActions = Actions(
                    Message("""Policisti so Podplutnika in Bradobrka malo privili
                              (tako, da so ju grdo gledali in podobne reči).
                              Kmalu sta skesano priznala zločin in vrnila ključe."""),
                    SetFlag("pilot ima kljuce")
                ),
                onFailureActions = Actions(
                    Message("""Izkazalo se je, da se motiš: tadva (ali vsaj eden od
                              njiju) sta tokrat nedolžna.""")
                )
            )
        )
    ),

    Location("0214_sola", Coordinates(56, 292), "Šola", { state ->
        if (HasSolved("T12_AT_01_pomesane_slike").verify(state))
            "Pred šolo je kup glasnih bobrčkov, ki navdušeno igrajo nogomet."
        else
            "Pred šolo je kup glasnih bobrčkov, ki se prepirajo. Gotovo o čem pomembnem."},

        "JV" to "0208_blok_racunalnikarjev",

        commands = Commands(
            StartTask("Reši prepir", "T12_AT_01_pomesane_slike",
                onSuccessActions = Actions(
                    Message("Hvaležni bobrček ti da majhen čopič."),
                    GetItem("čopič")
                ),
                onFailureActions = Actions(
                    Message("Hrup je še narasel. Najbrž si se zmotil.")
                )
            )
        )
    ),

    Location("0215_zapor", Coordinates(77, 152), "Zapor", """
        Okrog tebe so sami čudni bobri. Občutek imaš, da so nekateri ušpičili
        marsikaj hujšega kot to, da so se igrali z zvonci. Med njimi se počutiš
        neprijetno, zato se ti mudi odtod.""",

        commands = Commands(
            StartTask("Ven iz zapora", "T13_SI_12_labirint",
                onSuccessActions = Actions(
                    Message(""""Pojdi! Pa da se ne vidimo več!""""),
                    MoveTo("0208_blok_racunalnikarjev")
                ),
                onFailureActions = Actions(
                    Message(""""Narobe!" zagrmi jezni policist. "Bi nam rad pokvaril robota ali kaj?"""")
                )
            )
        )
    )
)