val T09_XX_18_ugasanje_luci = Task("T09_XX_18_ugasanje_luci", "Ugašanje luči",

description = """<p>Luč v tvoji sobi je sestavljena iz 64 žarnic. Nekatere so prižgane, druge
    ugasnjene. Če pritisneš eno od njih, se ugasne ali prižge - skupaj z njo
    pa se ugašajo tudi druge. Odkrivanje pravila, po katerem se to dogaja,
    bo ravno pravšnja uganka zate!
</p>

<p>Vse moraš ugasniti s petimi pritiski. Če ti ne uspe,
    se ponovno prižgejo tako, kot so bile prižgane v začetku.</p>

    <div id="svgload" class="center-block" style="width:384px;height:384px;">
    </div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	var puzzle_state = [
			[ 1, 1, 1, 1, 1, 1, 1, 1 ],
			[ 1, 1, 1, 1, 1, 1, 1, 1 ],
			[ 1, 1, 0, 0, 0, 0, 1, 1 ],
			[ 1, 1, 0, 0, 0, 0, 1, 1 ],
			[ 1, 1, 0, 0, 0, 0, 1, 1 ],
			[ 1, 1, 0, 0, 0, 0, 1, 1 ],
			[ 1, 1, 1, 1, 1, 1, 1, 1 ],
			[ 1, 1, 1, 1, 1, 1, 1, 1 ]
		],
		puzzle_return = "";

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T09_XX_18_ugasanje_luci/ugasanje.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
		$("#svgload svg").attr({
		'width': $("#svgload").width(),
		'height': $("#svgload").height()
        });

		$("ellipse").css("cursor","pointer");

		$("ellipse").click(function(){
			var id = $(this).attr("id");
			puzzle_return += " " + id;
			var x = id[1];
			var y = id[0];
			for(var yi = 0; yi <= y; yi++) {
				for(var xi = 0; xi <= x; xi++) {
					if(puzzle_state[yi][xi] == 0){
						puzzle_state[yi][xi] = 1;
						$("#" + yi + xi).attr("fill","url(#fill_on)");
					} else {
						puzzle_state[yi][xi] = 0;
						$("#" + yi + xi).attr("fill","#9D9D9C");
					}
				}
			}

			if(!_.contains(_.flatten(puzzle_state), 1)) {
				checkSolution(puzzle_return);
				return;
			}

		});

	}

	function checkSolution(solution) {

		$("ellipse").off("click");

		for(var yi = 0; yi < 8; yi++) {
			for(var xi = 0; xi < 8; xi++) {
				if(puzzle_state[yi][xi] == 1){
					$("#" + yi + xi).attr("stroke","#DB0020");
					$("#" + yi + xi).attr("stroke-width","1");
				}
			}
		}

	    setTimeout(function(){
			bQ.checkSolution(solution);
		},1500);

	}

	bQ.register(puzzleLoad);

}};

"""),

checkSolution = {
    it.trim().split(' ').sorted().joinToString(" ") == "11 15 51 55 77"
},

explanation = """
    <p>Računalnikarji pogosto govorijo o "bitih". Bit je kot lučka, ki je prižgana
    ali ugasnjena. Z biti je mogoče računati. Ena od osnovnih operacij z biti je
    "negacija": če je bit prižgan, se ugasne, če ugasnjen, se prižge. Kot si opazil,
    so luči narejene tako, da se "negira" luč, ki si jo kliknil in vse luči levo in
    navzgor od nje.</p>

    <p>Če luč negiramo dvakrat (ali štirikrat, šestkrat, osemkrat...), je tako, kot
        da se je sploh nismo dotaknili. Če jo lihokrat, je enako, kot da smo jo
        enkrat. Naloga je od tebe zahtevala, da prižgane luči "negiraš" lihokrat,
        ugasnjene pa sodokrat.</p>

    <p>Zdaj, ko si odkril rešitev in poznaš tudi malo ozadja, poskusi rešiti nalogo
        ponovno
    (tako da jo poiščeš na strani z nalogami, ki si jo že rešil). Razmisli tole: je
    vrstni red klikanja luči pomemben? Ali pa je pomembno le, katere luči klikneš,
    vrstni red pa je lahko poljuben?</p>
"""

)
