import kotlinx.html.js.onClickFunction
import react.RBuilder
import react.dom.*

fun RBuilder.explanations(state: AppState,
                          showExplanation: (String) -> Unit,
                          retakeTask: (TaskId) -> Unit) {
    div {
        h2(classes="explanation-page-title") { +"Razlage ozadja nalog" }
        attrs["id"] = "bQ-explanations"
        val shownTasks = repository.tasks.entries
                .filter { (id, _) -> DEBUG || (id in state.gameState.solvedTasks) }
        if (shownTasks.isEmpty()) {
            p {
                +"Tu si boš lahko ogledal razlage nalog, ki si jih rešil, in jih ponovno poskusil."
            }
        }
        else {
            shownTasks.forEach { (_, task) ->
                div(classes = "task-list") {
                    div(classes = "img") {
                        img(src = "tasks/${task.id}/icon.png") {}
                    }
                    p(classes = "task-title") {
                        +task.title
                    }
                    p {
                        if (task.explanation != null)
                            button(classes = "btn") {
                                attrs {
                                    onClickFunction = { showExplanation(task.explanation!!) }
                                }
                                +"Razlaga rešitve"
                            }
                        button(classes = "btn") {
                            attrs {
                                onClickFunction = { retakeTask(task.id) }
                            }
                            +"Poskusi ponovno"
                        }
                    }
                }
            }
        }
    }
}
