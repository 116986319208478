import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.button
import react.dom.div

typealias TaskId = String

external fun polishTask()


data class Task(
    val id: TaskId,
    val title: String,
    val description: String,
    val runTask: dynamic,
    val checkSolution: ((String) -> Boolean)? = null,
    val checkSolutionStateful: (String, GameState) -> Boolean = {
            solution, _ -> checkSolution?.invoke(solution) ?: false},
    val explanation: String? = null
) {
    init {
        repository.tasks[id] = this
    }
}

class BQ(val taskId: TaskId, val submit: (String) -> Unit) {
    var puzzleInit: (() -> Unit)? = null

    @JsName("register")
    fun register(func: () -> Unit) { puzzleInit = func }

    @JsName("checkSolution")
    fun checkSolution(solution: String) = submit(solution)

    @JsName("getTaskImageUrl")
    fun getTaskImageUrl(_ignoredTaskID: String, name: String) = "tasks/$taskId/$name"
}

class HasSolved(val taskId: TaskId, message: String = ""): Condition(message) {
    override fun verify(gameState: GameState) = taskId in gameState.solvedTasks
    override fun checkConsistency() = checkTaskExists(taskId)
}

open class ShowTask(taskId: TaskId, val onSuccessActions: Actions, val onFailureActions: Actions): Action() {
    val task = repository.tasks[taskId]!!

    override fun checkConsistency() = checkTaskExists(task.id)
}

fun checkTaskExists(taskId: TaskId?) =
    checkExists(taskId, repository.tasks, "Task $taskId")


class StartTask(description: String, taskId: TaskId,
                conditions: Conditions = Conditions(),
                val onSuccessActions: Actions = Actions(),
                val onFailureActions: Actions = Actions(),
                showOn: Condition = Not(HasSolved(taskId))
):
    Command(description, ShowTask(taskId, onSuccessActions, onFailureActions), conditions, showOn=showOn) {

    override fun checkConsistency() =
        listOf(super.checkConsistency(),
            mergeConsistency(onSuccessActions),
            mergeConsistency(onFailureActions)
        ).joinToString("\n")
}

interface TaskProps: RProps {
    var shownTask: ShowTask
    var state: GameState
    var endTask: (Actions) -> Unit
}


val task = functionalComponent<TaskProps> { props ->
    val task = props.shownTask.task

    useEffect {
        polishTask()

        if (task.runTask != null) {
            val bQ = BQ(task.id) { solution ->
                if (task.checkSolutionStateful(solution, props.state)) {
                    props.state.solvedTasks.add(task.id)
                    props.endTask(props.shownTask.onSuccessActions)
                }
                else {
                    props.endTask(props.shownTask.onFailureActions)
                }
            }

            (task.runTask as (bQ: BQ) -> Unit)(bQ)
            bQ.puzzleInit?.invoke()
        }
    }

    div {
        div(classes = "overlay") {}
        div {
            attrs["id"] = "bQ-task"
            button(type = ButtonType.button, classes = "close") {
                attrs {
                    onClickFunction = { props.endTask(Actions()) }
                }
                attrs["data-dismiss"] = "alert"
                attrs["aria-hidden"] = true
                +"×"
            }

            val taskHtml = renderHtml(task.description)
            child(taskHtml)
        }
    }
}

fun RBuilder.task(handler: TaskProps.() -> Unit) = child(task) {
    attrs {
        handler()
    }
}
