val T13_SE_10_barvne_plosce = Task("T13_SE_10_barvne_plosce", "Tlakovanje",

description = """

<style>
    #illustrations img {
        margin-right: 20px;
    }

    #illustrations img:last-of-type {
        margin-right: 0;
    }

    #answers div {
        margin: 4px;
    }
</style>

<p>Naletel si na matematika, ki je bil nekoč cestar in še vedno razpravlja o
tlakovanju trgov. Ukvarjal se je s tlakovanji, v katerih sta bili
sosednji ploščici vedno različnih barv.</p>

<p>Recimo, da bi tlakoval trg z modrimi, rumenimi in rdečimi ploščami.
    Če postavi modro ploščo tako, kot kaže leva slika, morajo biti vse plošče
    okrog nje rumene ali rdeče, nobena pa modra, saj bi se tako dotikali dve
    modri plošči. Tudi rdeče in rumene si morajo slediti izmenično, kot je
    narisano na naslednjih dveh slikah.</p>

<p>Podobno morajo biti tudi okrog rumene plošče same rdeče in modre. Eno od
rumenih plošč smo obkrožili na zadnji sliki.</p>

<div id="illustrations" class="center-block" style="margin-bottom: 20px;">
    <img src="tasks/T13_SE_10_barvne_plosce/tiles-1.svg">
    <img src="tasks/T13_SE_10_barvne_plosce/tiles-2.svg">
    <img src="tasks/T13_SE_10_barvne_plosce/tiles-3.svg">
    <img src="tasks/T13_SE_10_barvne_plosce/tiles-4.svg">
</div>

<div id="svg-question" style="float: right; width:367px; height:312px; margin-left: 15px">
</div>

<p>Recimo, da pobarva označeno ploščo z <span id="q-color"></span>, kot kaže
    slika na desni. Kakšne barve bo plošča, označena z vprašajem?</p>

<div id="answers" class="center-block bQ-actions bQ-shuffle" style="width: auto;">
    <div id="answer-0" style="float: left">
        <button id="submit" type="submit" class="btn btn-default"
                style="background-color: yellow"><b>Rumena</b></button>
    </div>

    <div id="answer-1" style="float: left">
        <button id="submit" type="submit" class="btn btn-default"
                style="background-color: blue"><b>Modra</b></button>
    </div>

    <div id="answer-2" style="float: left">
        <button id="submit" type="submit" class="btn btn-default"
                style="background-color: red"><b>Rdeča</b></button>
    </div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var color;

	function puzzleLoad() {
		$("#svg-question").svg({
			loadURL: 'tasks/T13_SE_10_barvne_plosce/tiles-all.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
        for(var i = 0; i <= 2; i++) {
            $('#answer-' + i).click(submitSolution);
        }
        color = _.random(2);
        $('#given').css('fill', ['yellow', 'blue', 'red'][color]);
        $('#q-color').html(['rumeno', 'modro', 'rdečo'][color]);
    }

    function submitSolution() {
        bQ.checkSolution($(this).attr('id').slice(-1) + ' ' + color);
    }
	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it.length == 3 && it[0] == it[2] },

explanation="""
    <p>Naloga, ki jo dobiš, ni vedno enaka: računalnik vsakič znova izbere barvo
    plošče, ki je že pobarvana. Tule se, da bo preprosteje, delajmo, da je
    začetna pobarvana plošča (na sliki smo jo označili s številko 1) modra.</p>

    <p>Naloge se lahko lotimo na dva načina.</p>
    
    <p><img style="float: left; margin-right: 10px" src="tasks/T13_SE_10_barvne_plosce/tiles-explanation.png"/>
        Prvi način je, da opazimo, kako se ponavlja zaporedje barv na poti od začetne
        plošče do plošče, katere barva nas zanima. Odločimo se, kako bomo pobarvali
        desno gornjo sosednjo ploščo, ki smo jo označili z 2. V tem primeru bo
        plošča 3 rdeča in plošča 4 bo morala biti modra. Zato bo
        morala biti 5 rdeča. Plošča 6 mora biti zato rumena in tako naprej. Ko
        pridemo do plošče 9, opazimo, da se je na ploščah 7-8-9 ponovil isti vzorec
        kot na 1-2-3, torej bo tudi naprej vse enako: na vsej poti desno navzgor
        se ponavlja zaporedje modra-rumena-rdeča-modra-rumena-rdeča-modra-rumena...
        Plošča, katere barva nas zanima, bo modra.</p>
    
    <p>Pa če bi ploščo 2 pobarvali z rdečo? Zaporedje bi bilo v tem primeru
        modra-rdeča-rumena namesto modra-rumena-rdeča; plošča, ki nas zanima, bi
        bila še vedno modra.</p>
    
    <p>Drugi način reševanja temelji na preprostem razmisleku: ena barva je podana,
        ostali dve izbiramo sami in jih poljubno zamenjujemo. Če je naloga
        zastavljena tako, da je pravilni odgovor znan, je pravilni odgovor lahko le
        "modra".</p>
    
    <p>Računalnik prve plošče ne bo vedno pobarval modro. Če si izbere rumeno, je
        pravilni odgovor rumena; če rdečo, rdeča.</p>"""
)
