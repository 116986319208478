val T07_XX_X1_ugibanje_stevila = Task("T07_XX_X1_ugibanje_stevila", "Ugibanje števila",

description = """<div id="dialog">
<p>
    &mdash; Izmislil sem si število med 0 in 255. Ugani ga! Ugibaš lahko osemkrat.
</p>
</div>

<input id="answer" type="text" class="form-control"
       style="width: 200px" placeholder="Vnesi število" />

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var number;
    var guesses = 8;

	function puzzleLoad() {
        number = _.random(0, 255);
//        number = 183;
        var answ = $('#answer');
        answ.bind("enterKey", checkAnswer);
        answ.keyup(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });
	}

	function checkAnswer() {
        var answ = $('#answer');
        var answer = answ.val();
        answ.val('');
        answer = parseInt(answer);
        if (isNaN(answer)) {
            return;
        }
        var msg = '<br/>&mdash; ' + answer + '?<br/>&mdash; ';
        if (number > answer) {
            msg += "Več.";
        }
        else if (number < answer) {
            msg += "Manj.";
        }
        else {
            msg += "Bravo!"
        }
        var dlg = $("#dialog p");
        dlg.append(msg);
        if (number == answer) {
            answ.hide();
            setTimeout(function() {
                bQ.checkSolution("1");
            }, 3000);
            return;
        };
        if (!--guesses) {
            answ.hide();
            setTimeout(function() {
                bQ.checkSolution("0");
            }, 3000);
        }
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "1" },

explanation="""
    <p>Kako najhitreje ugibati števila? Z razpolavljanjem. Če smo, recimo, odkrili,
    da gre za število med 110 in 120, bomo vprašali ali je večje ali manjše (ali
    enako 115). Če moramo ugibati število med 0 in 255, je to 256 različnih števil.
    Ko ugotovimo, ali je v spodnji ali zgornji polovici, ostane le še 128 števil.
    Po drugem koraku jih je 64, po tretjem 32, po četrtem 16 ... in po osmem eno
    samo.</p>
    
    <p>Kaj pa, če bi podvojili število števil? Če bi morali ugibati števila med 0 in
    511, torej 512 števil? Potrebovali bi le eno ugibanje več, torej devet. Če
    bi morali uganiti število med 1024 možnimi, bi potrebovali 10 ugibanj, za
    2048 možnih števil 11 ugibanj ... za milijon števil le 20.</p>
    
    <p>Ugibanje je tesno povezano z dvojiškim zapisom števil. Vrnimo se k številom
    do 255. Recimo, da moramo uganiti število 183.</p>
    
    <p>
    — Izmislil sem si število med 0 in 255. Ugani ga! Ugibaš lahko osemkrat.
    <br/>— 128?<br/>— Več.<br/>— 192?<br/>— Manj.<br/>— 160?<br/>— Več.<br/>— 176?
    <br/>— Več.<br/>— 184?<br/>— Manj.<br/>— 180?<br/>— Več.<br/>— 182?<br/>— Več.
    <br/>— 183?<br/>— Bravo!
    </p>
    
    <p>Recimo, da začnemo z 0. V prvem poskusu v bistvu vprašamo, k temu prištejemo
    128. Izvemo, da <b>da</b>. Nato vprašamo, ali prištejemo 64 (128 + 64 bi bilo
    192). Izvemo, da <b>ne</b>, torej ostanemo pri 128. Nato v bistvu vprašamo, ali
    prištejemo 32 (ko nas zanima, ali je število večje ali enako 160). <b>Da</b>;
    tako smo prišli do 160. Nato vprašamo, ali prišteti 16. <b>Da</b>. Torej imamo
    176. Vprašamo, ali prištejemo 8. <b>Ne.</b> Prištejemo 4? <b>Da</b>, torej imamo
    180. Prištejemo 2? <b>Da</b>; 182. Prištejemo 1? <b>Da</b>: rezultat je 183.</p>
    
    <p>Odgovori so bili da-ne-da-da-ne-da-da-da, točneje,
        več-manj-več-več-manj-več-več-več. Če <em>da</em> in <em>ne</em> zamenjamo
    z 1 in 0, dobimo 10110111 - kar ni nič drugega kot dvojiški zapis števila 183.
    </p>"""
)
