val T14_XX_01_letalisce = Task("T14_XX_01_letalisce", "Polnjenje letal",

description = """

<p>Pred tabo se je nabralo že precej bobrovskih družinic, ki se želijo, prav
    tako kot ti, peljati z letalom. Na letališču imajo tri letala, v vsako gre
    dvanajst potnikov. Razporedi družine vanje, pa se boš lahko peljal še ti.
</p>

<p>Za vsako družino, ki je na vrsti, izberi letalo; a pazi, da bo za vse dovolj prostora!</p>

<div id="puzzleCont" class="center-block" style="position:relative;width:512px;height:620px;color:black;margin-bottom:5px;">
	<div id="puzzlePlanes" style="position:absolute;left:246px;width:266px;height:300px;background-image:url('tasks/T14_XX_01_letalisce/airplanes.png');">
		<div id="puzzleWindows" style="position:absolute;width:266px;height:300px;"></div>
		<div id="puzzleBtn01" style="position:absolute;height:100px;width:266px;background-image:url('tasks/T14_XX_01_letalisce/overlay.png');cursor:pointer;"></div>
		<div id="puzzleBtn02" style="position:absolute;top:100px;height:100px;width:266px;background-image:url('tasks/T14_XX_01_letalisce/overlay.png');cursor:pointer;"></div>
		<div id="puzzleBtn03" style="position:absolute;top:200px;height:100px;width:266px;background-image:url('tasks/T14_XX_01_letalisce/overlay.png');cursor:pointer;"></div>
	</div>
	<div id="puzzleFams" style="position:absolute;width:246px;height:620px;">
		<div id="puzzleLabel01" style="position:absolute;top:20px;left:-40px;">Naslednji:</div>
        <div class="bQ-shuffle">
            <div id="f_0" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f1.png"></div>
            <div id="f_1" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f2.png"></div>
            <div id="f_2" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f3.png"></div>
            <div id="f_3" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f4.png"></div>
            <div id="f_4" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f5.png"></div>
            <div id="f_5" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f6.png"></div>
            <div id="f_6" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f7.png"></div>
            <div id="f_7" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f8.png"></div>
            <div id="f_8" style="text-align:center;"><img src="tasks/T14_XX_01_letalisce/f/f9.png"></div>
        </div>
	</div>
</div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	var puzzle_fs = [3, 2, 3, 4, 3, 5, 5, 6, 4];
	var puzzle_seats = [0,0,0];
	var puzzle_solution = [[],[],[]];
	var puzzle_i = 0;
		
	function puzzleLoad() {
	
		// Fadeout buttons
		$("#puzzleBtn01,#puzzleBtn02,#puzzleBtn03").fadeTo(1,0);
		
		// Draw windows
		for(var i = 0; i < 3; i++) {
			for(var j = 0; j < 12; j++) {
				var y = 55 + i*100;
				var x = 115 + (11-j)*10;
				var newHtml = '<div id="pw_'+i+'_'+j+'" style="position:absolute;left:'+x+'px;top:'+y+'px;height:5px;width:5px;background-color:black;" />';
				$("#puzzleWindows").append(newHtml);
			}
		}
		
		// Register buttons
		$("#puzzleBtn01,#puzzleBtn02,#puzzleBtn03").hover(function(){
			$(this).fadeTo(100,1);
		},function(){
			if($(this).css("display") != "none") $(this).fadeTo(100,0);
		});
		
		$("#puzzleBtn01").click(function(){ puzzleAction(0); });
		$("#puzzleBtn02").click(function(){ puzzleAction(1); });
		$("#puzzleBtn03").click(function(){ puzzleAction(2); });

	}
	
	function puzzleAction(n) {
	    const family = parseInt($(".bQ-shuffle").children()[puzzle_i].id.slice(2))

		// Disable buttons
		$("#puzzleBtn01,#puzzleBtn02,#puzzleBtn03").fadeTo(1,0);
		$("#puzzleBtn01,#puzzleBtn02,#puzzleBtn03").css("display","none");
	
		// Add passengers
		puzzle_seats[n] += puzzle_fs[family];
		puzzle_solution[n].push(puzzle_fs[family]);
		
		// Refresh windows
		for(var i = 0; i < 3; i++) {
			for(var j = 0; j < puzzle_seats[i]; j++) {
				$("#pw_"+i+"_"+j).css("background-color","orange");
			}
		}
		
		// Remove family from queue
		$("#f_"+family).fadeTo(500,0,function(){
			$("#f_"+family).slideUp(500,function(){
				puzzle_i++;
				// Test if fail
				if(puzzle_seats[0] > 12 || puzzle_seats[1] > 12 || puzzle_seats[2] > 12 || puzzle_i == 9) {
					puzzleCheckSolution();
				} else {
					// Re-enable buttons
					$("#puzzleBtn01,#puzzleBtn02,#puzzleBtn03").css("display","inline");
				}
			
			});
		});
	
	}
	
	function puzzleCheckSolution() {
	
		var s0 = puzzle_solution[0].join("-");
		var s1 = puzzle_solution[1].join("-");
		var s2 = puzzle_solution[2].join("-");
		
		if(s0 == "") s0 = "99";
		if(s1 == "") s1 = "99";
		if(s2 == "") s2 = "99";
		
		var final_solution = s0 + " " + s1 + " " + s2;
		
		bQ.checkSolution(final_solution);
	
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    solution
        .split(' ')
        .map { it.split("-").sumBy { it.toInt() } }
        .sorted() == listOf(11, 12, 12)
},
    explanation="""<p>Podobno nalogo si že rešil: v eni od prvih nalog si moral napolniti svoj
nahrbtnik s hlodi. Hm, nahrbtnik? Saj hlodov ne nosimo v nahrbtnikih?</p>

<p>Ne, a računalnikarji takšnim problemov pravijo problemi polnjenja nahrbtnika.
V nalogi s hlodi si moral "napolniti nahrbtnik" s hlodi z različno vrednostjo.
Tule je problem nekoliko drugačen: nahrbtnikov je več in vanje moraš naložiti
vse stvari. Njihova "vrednost" je enaka, pač pa so različno velike, kar zahteva
nekaj razmišljanja.</p>

<p>Ker letal in družin ni bilo veliko, pa tudi velike niso bile, naloga ni
težka. Če bi bilo letal in družin več, pa bi postala naloga veliko težja - tako
težka, da pravzaprav sploh ne poznamo dobrih postopkov za njeno reševanje.</p>
"""
)
