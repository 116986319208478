val T07_XX_25_laznivi_jazbeci = Task("T07_XX_25_laznivi_jazbeci", "Lažnivi jazbeci",

description = """<p>V nekem gozdu živijo bobri in jazbeci. Bobri vedno govorijo resnico, jazbeci
pa vedno lažejo.</p>

<p>Na neki jasi živi <span id="n-total"></span> živalic - nekatere so bobri,
druge jazbeci. Mimo pride krt - in krti, kot vemo, zelo slabo vidijo - in jih
vpraša: koliko bobrov je med vami? Dobi naslednje odgovore:</p>

<p id="answers" class="center-block"></p>

<p>Koliko je bobrov?</p>

<div style="float: left">
        <input id="answer" type="text" class="form-control"
               style="width: 200px; margin-right: 20px;"
               placeholder="Vnesi število" />
</div>
<div style="float: left">
        <button id="submit" type="submit" class="btn btn-default"><b>Odgovori</b></button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    function puzzleLoad() {
        var poss_answ = _.shuffle(_.range(2, 7));
        var answers = [];
        var x, el, i;
        _.times(_.random(3, 5), function(el) {
            el = poss_answ.pop();
            for(i = el + (answers.length ? _.sample([-1, 1, 2, 3]) : 0); i; i--) {
                answers.push(el);
            }
        });
        $('#n-total').html(answers.length);
        $('#answers').html(_.shuffle(answers).join(", "));

        $('#submit').click(checkSolution);
        var number = _.random(0, 255);
        var answ = $('#answer');
        answ.bind("enterKey", checkSolution);
        answ.keyup(function(e){
            if(e.keyCode == 13) {
                $(this).trigger("enterKey");
            }
        });

    }

	function checkSolution() {
        var s = $('#answer').val() + ': ' + $('#answers').html();
        bQ.checkSolution(s);
	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{ solution ->
    try {
        val (answerStr, question) = solution.split(":")
        val answer = answerStr.toIntOrNull()
        val counts = mutableMapOf<Int, Int>()
        question
            .split(',')
            .map {
                try {
                    it.trim().toInt()
                } catch (e: Exception) {
                    return@chk false
                }
            }
            .forEach { counts[it] = counts.getOrElse(it, { 0 }) + 1 }
        counts.get(answer) == answer && counts.entries.all { (n, c) -> n == answer || n != c }
    } catch(e: Exception) {
        false
    }
},

explanation="""
    <p>Računalnik ti bo sicer vsakič zastavil nekoliko drugačno nalogo, vedno pa jo
    boš rešil na isti način. Za primer vzemimo, da ti je pokazal odgovore
    </p>
    
    <div style="width: 100%; text-align: center">
        <p>4, 2, 3, 5, 2, 5, 3, 4, 2, 4, 5, 4, 5, 3, 4, 3, 5</p>
    </div>
    
    <p>Bi bilo možno, da sta bobra samo dva? V tem primeru morali biti na travniku
    natančno dve živalci, ki bi odgovorili 2 - namreč oba bobra, medtem ko bi vsi
    jazbeci, ker vedno lažejo, odgovorili s kako drugo številko. Hitro preštejemo
    dvojke, vidimo, da so tri, torej bobra ne moreta biti dva.</p>
    
    <p>Imamo tri bobre? Tudi ne: potem bi imeli med odgovori tri trojke, vendar
    imamo štiri.</p>
    
    <p>Prav tako ne moremo imeti štirih bobrov, saj je pet živali odgovorilo, da so
    bobri štirje. Torej tako trdijo jazbeci.</p>
    
    <p>Pač pa pet živali pravi, da je bobrov pet. To so torej bobri: na travniku je
    pet bobrov, ostalo pa so lažnivi jazbeci.</p>
    
    <p>Ko računalnikarji popravljajo svoje programe, morajo z opazovanjem njihovega
    delovanja odkriti, kje tiči napaka. Takrat so deli programa kot bobri in jazbeci
    iz te naloge: nekateri delujejo pravilno, drugi ne, računalnikar pa mora
    odkriti, kdo je kdo.</p>"""
)
