val T10_NL_07_pleskar = Task("T10_NL_07_pleskar", "Barvanje vrat",

description = """<p>Stanovalci dolgočasnega bloka so redkobesedni: v roke ti porinejo listek,
na katerem piše</p>

<div id="instructions" class="center-block"
     style="float: none; padding: 10px; background-color: white; width: 320px;
     font-size: 30px; font-weight: bold; color: blue;  text-align: center;">
</div>

<p>Zdaj pa vedi, kaj hočejo od tebe, če moreš!</p>

<div class="center-block" style="position:relative;width:255px;height:305px">
	<div id="svgload" style="position:relative;width:255px;height:305px">
	</div>
</div>

<div style="margin-top: 15px; text-align: center">
    <button id="done_button" class="btn btn-default">
        <b>Končano</b>
    </button>
</div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var instructions = [];
    var instructions5 = [];
    var clicks = "";

    function puzzleLoad() {
        $("#svgload").svg({
            loadURL: 'tasks/T10_NL_07_pleskar/pleskar.svg',
            settings: {},
            onLoad: puzzleInit
        });
    }

    function puzzleInit() {
        instructions = [];
        instructions5 = [];
        for(var r = 1; r < 6; r++) {
            for(var c = 1; c < 5; c++) {
                var id = r.toString() + c.toString();
				$("#d" + id).css("cursor", "pointer")
                            .attr("data-id", id)
                            .click(function() {
                                puzzleClick($(this).attr("data-id"));
                            });
                if (r < 5) {
                    instructions.push(id);
                }
                else {
                    instructions5.push(id);
                }
            }
        }
        instructions = _.shuffle(instructions).slice(1, _.random(4, 8));
        instructions.push(_.sample(instructions5));
        $("#instructions").text(instructions.join(" "));

        clicks = "";
        $("#done_button").click(function(){
            bQ.checkSolution(instructions + ":" + clicks);
        });
    }

    function puzzleClick(id) {
        clicks += " " + id;
        $("#d" + id).attr("fill", "#FFFF00");
    }

    bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    val (instructed, clicked) = solution.trim().split(':')
    val instnums = instructed.trim().split(',').map(String::toInt).sorted().toString()
    val clicknums = clicked.trim().split(' ').map(String::toInt).sorted().toString()
    instnums == clicknums
},
    explanation="""<img style="float: left; margin: 20px; width: 215px;"
                    src="tasks/T10_NL_07_pleskar/pleskar.svg"/>

<div style="padding: 10px; background-color: white; width: 320px;
            margin-left: 250px; margin-top: 100px;
            font-size: 30px; font-weight: bold; color: blue;
            text-align: center;">
    34 14 21 52
</div>

<p style="clear: both">
    Številke na listku predstavljajo vrata, ki jih bo potrebno pobarvati. Vse
    imajo po dve števki, torej je ena nadstropje in druga zaporedna številka vrat
v tem nadstropju. Katera je katera, odkrijemo, če opazimo, da se med števkami
pojavi tudi petica.</p>


<p>Očitno prva števka ne more biti zaporedna številka vrat v nadstropju, temveč
številka nadstropja. Gornji seznam torej predstavlja tretje nadstropje, četrta
vrata, prvo nadstropje - četrta vrata, drugo nadstropje - prva vrata in peto
nadstropje - druga vrata.</p>

<p>Na podoben način so označena stanovanja v resničnih blokih in sobe v hotelih.
A ne le to. Na enak način označimo položaje reči, ko na računalniku izrisujemo
slike, določamo mesta, na katerih se prikažejo besedila na strani, ki jo pravkar
bereš....</p>
"""
)
