val T10_LT_06_pot_do_jabolka = Task("T10_LT_06_pot_do_jabolka", "Pot do jabolka",

description = """ 

<p>Slepi črv poskuša priti do jabolka. Skavti so prijazni do živali. Pomagaj mu,
če bi rad še naprej hodil z njimi.</p>

<div id="puzzle" class="center-block"
     style="width:540px; height:371px; border-bottom:5px solid green;
            margin-bottom: 20px;">
    <div style="position: absolute; width:540px;height:371px;">
        <img src="tasks/T10_LT_06_pot_do_jabolka/drevo.svg"/>
        <div id="worm"
             style="width:30px; height:17px; position:absolute;
                     background-image:url('tasks/T10_LT_06_pot_do_jabolka/worm.png');"></div>
    </div>
</div>
 <div class="center-block" style="text-align: center">
 <button id="btn-left" class="btn btn-default">Levo</button>
	<button id="btn-right" class="btn btn-default">Desno</button>
 <button id="btn-back" class="btn btn-default">Nazaj</button><br/>
     <span style="font-size: 14px">(Lahko uporabljaš tudi tipkovnico.)</span>
 </div>


""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var tree =
        [102, 122,
            [87, 91,
                [62, 75,
                    [45, 99,
                        [55, 121,
                            [65, 124],
                            [47, 132,
                                [47, 137],
                                [40, 132]]],
                        [26, 109]],
                    [52, 51,
                        [26, 43,
                            [10, 51,
                                [10, 57],
                                [6, 50]],
                            [17, 26,
                                [6, 18],
                                [21, 18]]],
                        [60, 21,
                            [50, 13,
                                [43, 11],
                                [50, 7]],
                            [68, 15]]]],
                [108, 65,
                    [87, 38,
                        [71, 39],
                        [87, 17,
                            [82, 8],
                            [92, 11]]],
                    [125, 47,
                        [116, 39],
                        [126, 38]]]],
            [135, 95,
                [145, 66,
                    [141, 41,
                        [136, 29],
                        [153, 29]],
                    [172, 48,
                        [172, 23,
                            [160, 13,
                                [154, 9],
                                [161, 4]],
                            [181, 14]],
                        [191, 59,
                            [210, 58,
                                [221, 41,
                                    [217, 34],
                                    [226, 39]],
                                [223, 78,
                                    [246, 90,
                                        [253, 90],
                                        [251, 104]],
                                    [210, 104,
                                        [229, 124,
                                            [243, 137,
                                                [250, 132],
                                                [248, 142]],
                                            [218, 146,
                                                [221, 153],
                                                [207, 149]]],
                                        [198, 116]]]]]]],
                [157, 107,
                    [175, 102,
                        [175, 93],
                        [183, 103]],
                    [158, 121,
                        [166, 131],
                        [151, 133]]]]];


    var worm_stack = [];
    var cur_x = 2 * tree[0] - 15;
    var cur_y = 2 * tree[1] - 7;
    var moving = false;
    var back_offset = 0;
    var sequence = '';

	function puzzleLoad() {
        worm_stack.push(tree);
        $('#worm').css('left', cur_x).css('top', cur_y);
        document.onkeyup = get_key;
        $('#btn-left').click(function() { branch(2); });
        $('#btn-right').click(function() { branch(3); });
        $('#btn-back').click(function() { back(); });
	}

    function get_key(e) {
        if (moving) {
            return;
        }
        e = e || window.event;
        switch (e.keyCode) {
            case 37: branch(2); break;
            case 39: branch(3); break;
            case 40: back(); break;
        }
    }

    function twist() {
        back_offset = 30 - back_offset;
        $('#worm').css('background-position', back_offset + 'px 0px');
        if (moving) {
            setTimeout(twist, 100);
        }
    }

    function worm_stop() {
        moving = false;
        var top = _.last(worm_stack);
        if ((top[0] == 207) && (top[1] == 149)) {
            bQ.checkSolution(sequence);
        }
    }

    function move_worm() {
        var worm = $('#worm');
        var top = _.last(worm_stack);
        var new_x = 2 * top[0] - 15;
        var new_y = 2 * top[1] - 7;
        var dx = new_x - cur_x;
        var dy = new_y - cur_y;
        var dist = Math.sqrt(dx * dx + dy * dy);
        var angle = Math.atan2(dy, dx);
        _.each(['-webkit-', '-moz-', '-ms-', '-o-', ''],
            function(e) { worm.css(e + 'transform', 'rotate(' + angle + 'rad)');});
        moving = true;
        worm.animate({left: new_x, top: new_y}, dist * 20, 'linear', worm_stop);
        twist();
        cur_x = new_x;
        cur_y = new_y;
    }

    function branch(i) {
        var top = _.last(worm_stack);
        if (top.length > i) {
            worm_stack.push(top[i]);
            sequence += i;
            move_worm();
        }
    }

    function back() {
        if (worm_stack.length > 1) {
            worm_stack.pop();
            sequence = sequence.slice(0, -1);
            move_worm();
        }
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it == "3233233233" },

explanation="""
    <p>Kaj je bilo pri tej nalogi najtežje? Doslej si moral rešiti že več podobnih
    nalog, pri vseh pa je gor pomenilo gor, desno desno... Tule si moral
    razmišljati s perspektive črva: ko si mu ukazal iti levo, je to
    pomenilo levo glede na to, kako je trenutno obrnjen črv in ne, kako si
    obrnjen ti.</p>

    <p>V nekaterih računalniških jezikih, posebej takšnih, ki so namenjeni otrokom,
    lahko rišeš s pomočjo želve, ki jo premikaš in vrtiš po zaslonu. Na podoben
    način delamo tudi z liki v
        <a target="_blank" href="http://scratch.mit.edu">Scratchu</a>, ki si ga
    le oglej, če ga še ne poznaš!</p>"""
)
