val T09_XX_04_kvadratne_cokolade = Task("T09_XX_04_kvadratne_cokolade", "Kvadratne čokolade",

description = """<style>
    .piece {
        float: left;
        height: 100px;
    }

    .piece svg {
        margin: 30px;
    }

</style>
<p>Predte postavijo koščke štirih čokolad - modre, zelene, oranžne in rumene.
Izbrati moraš barvo, iz katere bo možno sestaviti celo čokolado velikosti
3 x 3 kvadratke. Katero barvo boš izbral?</p>

<div id="answers" class="block-center bQ-shuffle">
       <button data-answer="0" class="btn btn-default"
          style="margin-left: 30px; background-color: #F7941E"><b>Oranžno</b></button>
       <button data-answer="1" class="btn btn-default"
          style="margin-left: 30px; background-color: #00A651"><b>Zeleno</b></button>
       <button data-answer="2" class="btn btn-default"
          style="margin-left: 30px; background-color: #00AEEF"><b>Modro</b></button>
       <button data-answer="3" class="btn btn-default"
          style="margin-left: 30px; background-color: #FFF200"><b>Rumeno</b></button>
</div>

<div id="pieces"
     style="width: 100%; height: 360px; background-color: lightgray; visibility: hidden;
            border: solid 2px black; border-radius: 10px; padding: 20px; margin-top: 20px; "></div>


""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var loaded = 0;
    var colors = _.shuffle(_.range(4));

	function puzzleLoad() {
        var url = 'tasks/T09_XX_04_kvadratne_cokolade/pieces/piece-';
        _.each(_.shuffle(_.range(1, 13)),
            function(i) {
                var div = $(document.createElement('div'))
                          .attr('class', 'piece').attr('id', 'div' + i);
                div.svg({loadURL: url + i + '.svg',
                        changeSize: true,
                        onLoad: scramble});
                $('#pieces').append(div);
            });
        $('#answers button').click(submitSolution);
	}

    function scramble(e) {
        if (++loaded < 12)
            return;
        $('#pieces').css('visibility', 'visible');
        var i;
        for(i = 1; i <= 12; i++) {
            var degrees = 90 * _.random(3);
            $('#div' + i).css({'-webkit-transform' : 'rotate('+ degrees +'deg)',
                               '-moz-transform' : 'rotate('+ degrees +'deg)',
                               '-ms-transform' : 'rotate('+ degrees +'deg)',
                               'transform' : 'rotate('+ degrees +'deg)'});
        }
        var sts = _.map(_.range(4), function (i) { return $('.st' + i); });
        _.each(sts, function(e, i) { e.attr('class', 'st' + colors[i]); });
    }

  	function submitSolution() {
        bQ.checkSolution(colors.toString() + ',' + $(this).attr('data-answer'));
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { it.split(',').let { it[1] == it.last() } },

    explanation="""
    <p>Koščki so vsakič razmetani in pobarvani nekoliko drugače. Recimo, da dobiš
    takšno nalogo.</p>

    <div style="width: 100%; text-align: center">
        <img src="tasks/T09_XX_04_kvadratne_cokolade/explanation.png"/>
    </div>
    
    <p>Sestaviš lahko modro čokolado. Če se želiš prepričati, da je
        iz ostalih barv ni mogoče, začni z najbolj nerodnim, največjim kosom
        vsake barve. Pri rumenih je to kos sestavljen iz štirih koščkov. Onega iz
        treh bi lahko obrnili pokonci in postavili levo ali desno od kosa iz
        štirih koščkov, vendar preostala dva kvadratka nista skupaj, zato čokolade
        ne moremo dokončati. Prav tako se hitro prepričamo, da je ne moremo iz
        zelenih in oranžnih kosov, če razmislimo, kako h kosu iz štirih koščkov
        pametno prisloniti onega iz treh.</p>
    
    <p>Računalnikarji morajo pogosto pokazati podobno zvitost, ko morajo zložiti
    dele programa, računalnika, računalniške mreže ali česa podobnega tako, da gre
    dobro skupaj.</p>"""
)
