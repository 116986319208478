val T13_SI_12_labirint = Task("T13_SI_12_labirint", "Robot v labirintu",

description = """

<p>Policistom moraš za kazen pomagati upravljati policijskega robota. Robot
    stoji, kjer kaže slika. Pobrati mora zaklad in ga prinesti ven na drugi
    strani labirinta.</p>
	
<div id="puzzleCont" class="center-block" style="width:510px;height:330px;border:5px solid white;border-radius: 10px;background-color:white;color:black;margin-bottom:5px;">
	<div id="svgload" style="position:absolute;width:500px;height:320px;"></div>
	<div id="puzzleLayer02" style="position:absolute;width:500px;height:320px;">
		<div id="puzzleRobot" style="position:absolute;width:39px;height:39px;background-image:url('tasks/T13_SI_12_labirint/r.png');"></div>
	</div>
</div>

<div style="padding:5px;text-align:center;">
	<button id="puzzleBtn01" class="btn btn-default"><img src="tasks/T13_SI_12_labirint/arr_W.png" /></button>
	<button id="puzzleBtn02" class="btn btn-default"><img src="tasks/T13_SI_12_labirint/arr_N.png" /></button>
	<button id="puzzleBtn03" class="btn btn-default"><img src="tasks/T13_SI_12_labirint/arr_E.png" /></button>
	<button id="puzzleBtn04" class="btn btn-default"><img src="tasks/T13_SI_12_labirint/arr_S.png" /></button>
	&nbsp;&nbsp;&nbsp;
	<button id="puzzleBtn05" disabled="disabled" class="btn btn-default"><img src="tasks/T13_SI_12_labirint/backsp.png" /></button>
	<button id="puzzleBtn06" class="btn btn-default"><b>Pojdi!</b></button>
</div>

<div class="center-block" style="padding:5px;padding-bottom:0px;background-image:url('tasks/T13_SI_12_labirint/cont.png');width:500px;min-height:32px;">
	<div id="puzzleMyDirections" style="position:relative;" >
		<div id="puzzleMyDirectionsCursor" style="position:absolute;width:1px;height:25px;background-color:red;"></div>
	</div>
	<div style="clear:both;"></div>
</div>
""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	// Possible symbols: ║═╗╝╚╔╣╩╦╠╬<>AV
	var puzzle_map = [
		['╠','═','╗','╔','═','╗','╔','═','═','╗'],
		['║','<','╝','╚','╗','╚','╣','A','╔','╝'],
		['╚','╗','╔','>','╠','╗','╚','╝','║','A'],
		['╔','╩','╝','╔','╣','╚','>','╔','╩','╝'],
		['╚','═','═','╝','╚','>','<','╩','═','>']
	];
	var puzzle_treasue = [1,7];
	var puzzle_out = [-1,0];
	var puzzle_in  = [4,9];
	var puzzle_state = {
		y: 0,
		x: 0,
		hasTreasue: false
	}
	var spriteAnimationEnabled = false;
	var spriteAnimation_robot_offset = 0;

	var puzzle_dirs = new Array;
	var puzzle_cursor_state = 0;
	var puzzle_cursor_pos = 0;

	function puzzleLoad() {

		// Load svg
		$("#svgload").svg({
			loadURL: 'tasks/T13_SI_12_labirint/labirint.svg',
			settings: {},
			onLoad: puzzleInit
		});

	}

	function puzzleInit(){

		// Register buttons
		$("#puzzleBtn01").click(function(){ puzzleAddDir("W") });
		$("#puzzleBtn02").click(function(){ puzzleAddDir("N") });
		$("#puzzleBtn03").click(function(){ puzzleAddDir("E") });
		$("#puzzleBtn04").click(function(){ puzzleAddDir("S") });
		$("#puzzleBtn05").click(function(){ puzzleRemoveDir() });
		$("#puzzleBtn06").click(function(){ puzzleStartAnimation() });

		puzzleAnimateCursor();

		// Move robot to starting position
		puzzle_state.y = puzzle_in[0];
		puzzle_state.x = puzzle_in[1];
		$("#puzzleRobot").css("left",(27+puzzle_state.x*45) + "px");
		$("#puzzleRobot").css("top",(44+puzzle_state.y*45) + "px");

	}

	function puzzleAddDir(direction) {
		var newId = "puzzlrMyDir_" + String(new Date().getTime());
		var newHtml = '<div id="' + newId + '" data-seq="0" class="puzzlrMyDirs" style="float:left;width:25px;height:25px;border:1px solid white;text-align:center;margin-right:5px;margin-bottom:5px;"><div style="width:15px;height:15px;margin:4px;background-image:url(\'tasks/T13_SI_12_labirint/arr_' + direction + '.png\')"></div></div>';
		if($(".puzzlrMyDirs").length != 0) $(".puzzlrMyDirs").eq(puzzle_cursor_pos-1).after(newHtml);
		else $("#puzzleMyDirections").append(newHtml);
		$("#" + newId).click(function(){
			if(!spriteAnimationEnabled) puzzleSetCursor(parseInt($("#" + newId).attr("data-seq"))+1);
		});

		puzzle_dirs.splice(puzzle_cursor_pos,0,direction);
		puzzleSetCursor(puzzle_cursor_pos+1);
		puzzleResetSeqNums();

	}

	function puzzleRemoveDir() {

		if(puzzle_dirs.length > 0) {
			$(".puzzlrMyDirs").eq(puzzle_cursor_pos-1).remove();
			puzzle_dirs.splice(puzzle_cursor_pos-1,1);
			puzzleSetCursor(puzzle_cursor_pos-1);
			puzzleResetSeqNums();
		}

	}

	function puzzleResetSeqNums() {

		for(var i = 0; i < puzzle_dirs.length; i++) {
			$(".puzzlrMyDirs").eq(i).attr("data-seq",i);
		}

	}

	function puzzleAnimateCursor() {
		if(puzzle_cursor_state == 0 && !spriteAnimationEnabled) {
			puzzle_cursor_state = 1;
			$("#puzzleMyDirectionsCursor").css("background-color","red");
		} else {
			puzzle_cursor_state = 0;
			$("#puzzleMyDirectionsCursor").css("background-color","none");
		}
		setTimeout(puzzleAnimateCursor,500);
	}

	function puzzleSetCursor(pos) {

		if(pos == 0) $("#puzzleBtn05").attr("disabled","disabled");
		else $("#puzzleBtn05").removeAttr("disabled");
		puzzle_cursor_pos = pos;
		var left = ((puzzle_cursor_pos%16)*30-3);
		var top =  (Math.floor((puzzle_cursor_pos)/16)*30);
		if(puzzle_cursor_pos%16 == 0 && puzzle_cursor_pos > 0) {
			left = 16*30-3;
			top  = (Math.floor((puzzle_cursor_pos-1)/16)*30)
		}
		$("#puzzleMyDirectionsCursor").css("left", left + "px").css("top", top + "px");

	}

	function puzzleStartAnimation() {

		// Disable buttons
		$(".btn").attr("disabled","disabled");
		$(".puzzlrMyDirs").css("background-color","inherit").css("color","inherit");

		spriteAnimationEnabled = true;
		puzzleAnimateSprite_robot();
		puzzleAnimate(puzzle_dirs,0);

	}

	function puzzleAnimate(dirs,i) {

		$(".puzzlrMyDirs[data-seq="+i+"]").css("background-color","white").css("color","#108a93");

		var dir = dirs[i];
		var movePossible = puzzleMovePossible(dir);

		if(movePossible) {

			var newY = puzzle_state.y;
			var newX = puzzle_state.x;

			if(dir == "S") newY++;
			else if(dir == "W") newX--;
			else if(dir == "N") newY--;
			else if(dir == "E") newX++;

			$("#puzzleRobot").animate({
				top: (44+newY*45),
				left: (27+newX*45)
			},{
				duration:750,
				easing: 'linear',
				done: function(){

					puzzle_state.y = newY;
					puzzle_state.x = newX;

					if(puzzle_state.y == puzzle_treasue[0] && puzzle_state.x == puzzle_treasue[1]) {
						puzzle_state.hasTreasue = true;
						$("#layer1").fadeOut();
					}

					if(puzzle_state.y == puzzle_out[0] && puzzle_state.x == puzzle_out[1]) {
						spriteAnimationEnabled = false;
						puzzleCheckSolution();
					} else {
						puzzleAnimate(dirs,i+1);
					}

				}
			});

		} else {

			spriteAnimationEnabled = false;
			$("#puzzleRobot").fadeTo(500,0,function(){
				puzzle_state.y = puzzle_in[0];
				puzzle_state.x = puzzle_in[1];
				$("#puzzleRobot").css("left",(27+puzzle_state.x*45) + "px");
				$("#puzzleRobot").css("top",(44+puzzle_state.y*45) + "px");
				$("#puzzleRobot").fadeTo(500,1,function(){
					$(".btn").removeAttr("disabled");
					$(".puzzlrMyDirs").css("background-color","none").css("color","white");
				});
			});
            $("#layer1").fadeIn();
		}
	}

	function puzzleAnimateSprite_robot() {

		spriteAnimation_robot_offset = (spriteAnimation_robot_offset + 39) % 156;
		$("#puzzleRobot").css("background-position",spriteAnimation_robot_offset + "px 0px");

		if(spriteAnimationEnabled) {
			setTimeout(puzzleAnimateSprite_robot,40);
		}

	}

	function puzzleMovePossible(dir) {

		var tile = puzzle_map[puzzle_state.y][puzzle_state.x];

		if(dir == "S") {
			if(tileInGroup(tile,['║','╗','╔','╣','╦','╠','╬','A'])) {
				return true;
			}
		} else if(dir == "W") {
			if(tileInGroup(tile,['═','╗','╝','╣','╩','╦','╬','>'])) {
				return true;
			}
		} else if(dir == "N") {
			if(tileInGroup(tile,['║','╝','╚','╣','╩','╠','╬','V'])) {
				return true;
			}
		} else if(dir == "E") {
			if(tileInGroup(tile,['═','╚','╔','╩','╦','╠','╬','<'])) {
				return true;
			}
		} else return false;

	}

	function tileInGroup(tile,group) {
		for(var i = 0; i < group.length; i++)
			if(group[i] == tile)
				return true;
		return false;
	}

	function puzzleCheckSolution() {

		var solution = "";
		for(var i = 0; i < puzzle_dirs.length; i++) solution = solution + puzzle_dirs[i];
		bQ.checkSolution(solution);

	}

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{ solution ->
    val wallsVer = listOf("10010010001", "11010101101", "10101010111", "10010101001", "10001010001")
    val wallsHor = listOf("000101", "111011", "101011", "101101", "110001", "101011",
                          "100111", "110101", "110011", "101011")
    var x = 9
    var y = 4
    var hasTreasure = false
    solution.forEach { c ->
        if (x == 0 && y == -1) return@chk false
        var w = '0'
        when (c) {
            'N' -> { w = wallsHor[x][y];  y-- }
            'S' -> { w = wallsHor[x][y + 1]; y++ }
            'E' -> { w = wallsVer[y][x + 1]; x++ }
            'W' -> { w = wallsVer[y][x]; x-- }
        }
        if (w == '1') return@chk false
        if (x == 7 && y == 1) hasTreasure = true
    }
    hasTreasure && x == 0 && y == -1
},
    explanation="""Kar si moral napisati, je preprost program v programskem jeziku, ki ima le
štiri ukaze. Če si ga napisal pravilno, je robot storil, kar so hoteli
policisti. Če si se zmotil ... pač ni."""
)
