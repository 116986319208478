val T09_XX_07_cestna_razsvetljava = Task("T09_XX_07_cestna_razsvetljava", "Cestna razsvetljava",

description = """

<p>Bobri bodo razsvetlili mestno četrt. Na zemljevidu so s
    temno barvo označene hiše, ulice so sive, ostale povezave pa so podzemne.
    Razsvetliti želijo le nadzemne ulice.

<p>Za razsvetljevanje lahko porabijo največ 31 evrov. Na voljo so svetilke, ki
    svetijo eno, dve, tri ali štiri smeri in stanejo 5, 6, 7 ali 8 bevrov.
    Kako naj jih razpostavijo?</p>

<style>
    #lights div { margin-right: 15px; }
    #lights div { border-bottom: 2px solid black; margin-bottom: 20px;}
    #lights div span { margin-right: 10px; }
    #lights img { margin: 5px; }
</style>
<div id="task-r" class="center-block" style="position:relative;">
    <div style="float: left; ; margin-right: 30px">
        <div id="svg-zemljevid" style="width:382px; height:280px">
        </div>
        <p style="text-align: center; font-size:24px; width:auto; margin-top:12px">Skupna cena: <span id="price">0</span>
    </div>


    <div id="lights" style="float: right">
        <div>
            <span>5 &euro;</span>
            <img id="l-l" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA/pJREFUeNq8llts03UUx8//3uvarWN1g7XEURcDmeBqjMT7SHCGkCWI8YkYLw9C9IkH4EGnDyjGkMUY0gcTn3ziAaMGH0RpgheGIxhECSmXjU5oWVv7b0tv/5vn/JPqLv33tmTnof1ff5/fufzP+TKGYUAzeyrUt1WwOV4Fw9gBDPQq5ZKfrrMsV+FEaZZh2IvVUuHkudi9r5qtxTQCImgCQR9pSnWTtz/AuXv8INpd4PKZPCjl/gG8B/lMErJ356rVYsHADUSUcnES4dmWgQjy8qIUZVhu88BDj/A9G4agFSukk3A3dhmK2VRF17VX6nm8Akjhw1Cd9w4EpQ0Ph4ETRGjX5GQcZn//WWMY5pPo1b8PWQLNXAlwye3zQfDRcViNkbex6e+Bl2xfnL1y+7XadXZxGEXROL/jhTJkEmlIXvupYxjldf7qDHj8g6Cr6r7ntwT3rwB6vHp0bGdFOvJ+Ho5M5uHOjdmOoAQjz+xd3fDg6DMwFH6O01RlCh3a+B8QT54VRdj8zsGC+dL47nJH0MWw4Mh28xpVtG9wkyA6XBE65+lnnV+PvPnWfd7l/j+fBCU7Ojlr/vuHn2wbVrP+0Aik49fHyEvuhy9PbMW6OfTBsRy7fJHQsAr9/TqcPlUCVs+BqzfQNswMI8dBpZhjlUrJw3s8+vj2p6uc1c5rnh5/bw7EZAZsXX1L7huGDnmsSHufvy6sZr71QyAn4hO83WlMbBtVGoaLoBdOOeDtaQYgtbDi/mc2A1INYLVcYvF0s6rCBB8Y0JoWxPpB62ewnbVUVJLTXWBTC6x/W1iBtTBBcrhYWGNj3V2GHLvGrwkMC6zM2uxGopBnmj6sKNbPaNVKS0AcZzzPccbFc1FpuFEeaUMzFwRYsJWxQBxL7umqAmm1CvEr0zC45XHLNXBAk4san7jDnYyekV7GtsZbwQ687gVZtIPzxT11F8vM34DU5V/NYytoNjEPosP5C0tDUs4y2qUZwRqWd0NgdI/l7mlAB0eegNTtmOlpPbt36y+1XMh9alapp9uIfH7CqXYCawVKEcCPXibnTOBCkp28dZO//93Xto5gjaDUZ+N//qZhrt9YMvFpRNkdxpljUzI39bGrbdhyj+Ywp72BEGAYqXF/8+Mfc7tXSIxd4d7Dsswe7VknQfCxvav65mpQjhdSGM5QTcUt6TTfzqQ+RI15NpvRNNIkq5EY6fmbgMqPYOHFkrGuTCQNgi8d9wVCEg3PdpQbeUY54wThNArmfcv1qaUQpulMskCtlMdQBPNeFEQkiiw6iDkTqfSpGrFAXkJQtG3lXQOj1HsXD3cifIBGDHV9s8voaqGYTbtI8vM2exSVd6SZ3P9XgAEAXlIXereRbAoAAAAASUVORK5CYII=">
            <img id="l-u" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA9tJREFUeNq8lltMU3cYwL/zP/+e9tAChYqMQYcRcAswwMiDRpy4y1OT6RNeHnwx8cHL3vbg07aEOLMHYzQYkvmwly3Zk8YXE4Mb8Tp1C6LzHiSIiqUUK21PS89t39e1pIWe3kz8kpNe/v/z/fLdP8E0TSgkW9pWr8GP/oZGfRdeb+Ui8PSZoggRkcNIwM/OXXk6O1pIl5APiKD+VXXG4FyAbe7rX4T1vSq0rdOy7kTCDMb+scHoiF2Lx4WoYcBwNCIcQ3ioaCCC3B98qA+F3rCB3XtjfGCPAq7Kwp4Y+9sGZ047tclnPBpeEHbktJiAmU9fa12Pr9fz/NBOtznzSDTNBSj5+X1YNr/qWqX5NniOLNefZSHFinOY7OxW4dTPIXgXIWu/2e8GgYnjlx/P9KT/Z5lu5JL9smivgjsYkwvnHWXDImEBTvzkAofMQBCEzs87mw+sAEqya8jhcnvbt34NHm8rHP2+siwowQ7uc8OrlxK0bNwOLb3bRF1TT6Qy/X8gZaOWiA80d29KvvTRpxvLgi6HSbITXJ560mVD751bAtoc8uDqte0crVx6uVRoLlhaGtq6wND1DjSsR7z062m3oWlDazdsBSaKWUqq65tAjStw8XwUGhoMaPtYKxmWtAr1ogeZGo82UsfYUV3vBdEm5VRGlpIc/24Cbp2tgEavnnWuqgJcvWGDWZXlhKXF09QC8y+ffcalCtcuV219XncRdC4YgMM38cfNled+KQaVX/gsYSRyVQ2YhuFkgL2xAn8UEqe7zvLMZpfzwtJSUVULDOuEw7uKwIq8J3AG71neP1DXEqFELFLwomkalmeGphYFUxdjMY7NdVRZeNNdm+eiriYgGHgFJyUjmSCZMSPYPV0B6cUE1GLq59OxGF2QORb2L6HXzw82tfdyq4tP/7oICTDgtUXqE2zq7o3kdyvoW/802J2VYwyH5B1D16KRoN8aFlPgky3WdUaQ5q5NSeg8wnNJcHpCw452hqUCNDzzZFzLCYvnhxUDJWOUt0EdY/hbEojj41gsEorOTj5aCevzFVXUVlDSNTV+zWCc/0B7ztLEpxHFRD5C8+vFg9slwzJlPhVTgofn/RAOzNz/49+pzhVL1LYO7xEtsXiUGnm5sOVQxm1BzOTW9BaXVfh/3p/+URDYdWyyekKJlA0jN5JlKdiXmStjzjWRdhBaC2hS0/C0Gl05ZyMmCMUM9T7EJOlbvp9aLsLJDQ7XAprUCOY0z2SLqUIWUZ1R6lM2UoKQt0revFPgHlw9vsUW6EPF1c6auji6fWnnwO4RR0scVNRUZ5T6Vls3yX8CDACEvkPIWh5wiAAAAABJRU5ErkJggg==">
            <img id="l-r" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAZJREFUeNq8lk1sG0UUx9/MjnfXrpM4bpSQ4NghJaCkSSBgRBupNO2BEiFArYQE4oIQp/bIpeLAx4kiRMkBKosjEicOVFEFB0prBaEStUDahjYkQBMn4A82iZ1de9f2fjCz4ODE3jhxpLzTema8v30f894fWZYFtexIT+ujhBdGEcKvAIKWoqa2sXWMuTzHC/N0/ceCqnzx3VzqYq13oa2AFDTiEj0Ro1h40Nce5Br8bcC7veDdb/OAQqCQy4K8koRMImbks7JOPyBS1HLvUHh620AK8lGPoqZpDnT2hbE/cAC2Y8pyEuJztyCXlvKmabxUzeMKIAsfDdUPvo6QEOgNA+fiYaeWSS7C/NT3Bibks6vTsdccgTaMkJ989wVRaHAYdmNrqT8hMXMFeN6aGp+UhkrruDyMzLPmjgdQOh6DlaXf64YV1CxIv12FoXABNA0NvHCo5XQF0CW6L7EwBvufhODgYVj85XpdUAZb+vlLGD6iwXvn1+D9sQyXy6Ix6lDXOpBVo6Hrh1nOmDW3h+qClsPefFe214bCRXjulOZq8pkX13N4fKBrpuOhRx7eXI2r8QWI3boGnQefgFqVWg22Xr0ygpPP7Nc1FfVw335+oQss6+3gwCGaQm7DQXeDD4R9DbA4PQm86AZ3o3/HMGa8AJBexXhlGbcSet9ebmy9nziVPwsvs9TUNeBifwDvadiwbxo6vXcxGH66Omy9KEfy8NW4OEoQxs+yDrKVMSien4OP4ir9pVXsf9qI4ewWsFIuswpqJhiTAGtXtezfM2rVvSafta2i6u3XFZzPySHesw/2wgTB8uL/v35vDNMLn2RNdy/MNEDHYIFk6IWahy3LdNxTVbQt4OyvBAggdFleTh5saut0PEjnIdAz8LFoAccu1aZrsUwL9OtxEUaf1xzfkfiLYx4ahA7LaDoROxPoCxMn2NzkN+BubQPJYYKwjvThuQn72Qk6EeXB32LetFvbSF8g3f3Y0abSJK+ANTZDrXHFoPG7E/DGWaUq9BRtbX+n8Iv4vwRF4rM39XphpebQ3vsU9dRrh7fc2G86prJMAdhAQy+eU5V0NnVvpi7YVlDWuMc+8JryGnp1w8RnIwpz5PKB8DFu6e6NHcOcwstyd3vKFb10XTpWITGO94dOF/PqJ6xiux8/uqs7V4KKgiVlMrinpOJw+aEr0wsXXIL7jCzFjd00A5YSaWEWdENIUFi4XDJWlYnMU/qn80xytPcM7qj1MYWweOeGiTGe0Av5k5v1qaMQZhqE93gjhZxywh/oBh8Ns1NzUNdW7caQundHpwWYMfXi604qHNWS+gxMBPEt+nhCz2sdVAEoLsFju2yauqLJGZFeK4OI7ij9uEgtuf+PAAMA1m8TpYdNU8MAAAAASUVORK5CYII=">
            <img id="l-d" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABABJREFUeNq8lk1sG0UUx9/OzO7GZp3a+XCaNo5DwGpU0gQEoh8QEVUKCCEqTki9QREXekCquMABCXGBCyBQQkWFhIRaQBwKlRAfUj8gQKsCqlrqSqmTkg9jJ03j2FnHu17vrnljYauxvbbjSn0Xj2dm5/fezJt5fyGfz0M9Gwn5+5gkHxQIeQb/3p/TtS7eTwjNMtkVBQGmjUz6S+z6ZjJyM1lrLaEWEEGjYov7qJUz+r3dvaKnrQsklwJKe4EHhpYGI7MOamIJUovzVjajWoSJx82sfsQJXBWIIC9GdNK2rBF//07q7xsAKkp1d4I7EI9cgWRsLovzj5y5OjdRF4iwBxF26h6fPxAc2tsQqNzSK0sw89c5mzL23Zm/5w44AjkMz+WC29shh/aMwZ2Ypq5C5PxPIBAaPhueHyz2k7JtPOXp3CZrawlIRGeahhnaOkxfPA2SWwFRbhnYv6vvgwogJsN4i+IN9D/8BIT2PAnRa382BeWwqd+/R5ALQrvHIDj8GMWkO8wTsATkaW8a+vPB4b2Fj1ytvqag5TB+/nwtTDzGs70ExNCP8k6e8kXbLLQarGjdoSH+cx+Pkp4+PuHFkI/x6Mozkn/c2rkdZi9NFtqu1rZNw4pmmTmC83YybD+n+PwmRseqLVaKFDOOzt/A3fBsGLctE24tR0FUPI4wbu09/XBzJjzMt3FM6djKam0Xhz4QGIAD12/AoK1XjH8oU1iuAfs/KYFKkkDytjXsxgXrmdTidhwTJVdDD4TsbpXwnjOFMumOLjkIQkPT+BtM4C4bs21Lv1swbW0VCNa42Qw26pmVyzqO2bnGfM6urxnMNnM/a2riqXqXOh27Aj/KBK526BuOzMwBzKG/6tSv0LXj8RoOG6CnUxIzjewXyfj828GhfdQJFr10EjoDJrx+LAmKp7J+RqYYHH7JLLSdoKmlBcDisECwMs8SSmPVnq8SzG/CRw4wbqEdJox/moTV+D+whJFWs5WFGVMQyDuFLMUo34xdv2w2A2sEygtyJrWCaaCdKAAxys/wAYhzedAMrBaUn1302h+oddhbXOeUKn6h2lN2sXfXbnF19rdNwyrP1Au+7ntBzxJQl+Nh1DeDFRJj/2DwFUnMjHdvs5qG3Q49dNAHAqVq3rJ6iypuw0vDVRYR4JN/o9SaPCs3DUurAnx9wgVbttiLCHv5dsnoJBNHJSn/w6P7DPnV19ZhK0bcqHFH339XMQ0DwqkkGS3Xp45CmIuqtnb7vcQKefHpZ3UYGTXgoUeMqtu8GKPwyzkJvvrcbWuaoIpi/o1vL9ya2LTyLuqd7T3WoXRaeAE9DqATek/AYoQCw1dGn44wZltgtXXYlxH8Mc/4Wuv9J8AA3X4QLK5uGkIAAAAASUVORK5CYII=">
        </div>

        <div>
            <span>6 &euro;</span>
            <img id="l-lu" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hJREFUeNq8ll9oW1Ucx3/n3HPvzU3aJKZpO7Nqy1zq6ia0JMMJ3boNsW6IFIZOZHtRnzoEBR+qE0FBRn3QPej+gEjf1Cd9EEGoW0cZbHPdug021mI3Yf2zNU2T3DT35v47nhPXkrT506TiF05Ccs/9fe7vd39/DqKUQiXtDje1sa++Jzfbr7DtW4kAJJnEfp/PSWQyKC0QGJ5/iIdGJx+NV7KFygEZaG+gwfkmvoC3d+/NQlfUhHC7VbAnrWK4PibCyLBs6TpawogO/no1dqIqIAP5fX7nZ8NAu988qglvvJWBuvrKkbh+VYTvTnmse1NkSU2hPubxyJpNHJi/urc2dvZ2BuMD73qpOs2eJwVVr5/OKPSl54Pmay809K+2vxp2eN9zQfrFB/U1gfLXtXMiZfZoz7bQ1/kMnBfGVo+H/hDeZsHIHzJM3iVQq9IqgpNf1kGgUeaQ9/bvaO1fvrYC9Afoza6dBjo7lICXD+hw7B1/TVAO4/cm1Xpo3fk6PBPdJ9im8dXjTP8XyH68n9XBe/wzNXfTh8fTNUHzYU9HDuX+q2toBn+oVZbcdWdWgA1BOsiysSATq4UWgy2rpSMKRibdy73E7KN7KQ0ST/3VWi+0HIxLECUItGwBIrs+JW4PfNKzP1uyzjiUq/9tPxyI2uD1OQXXTRPB5YsiJD1KUdhKjjQ/Ban5mV4iyfTFrohZNlwcGpvHEB0OwA5nracO0eHunkNlbfgY0Bq7EMKKQt2bQnbFd3T4iAYblezxanh2GpPwsxb8HxJlRcll6Xr65H8lzGB0I12lKlFqYVkGk6d1JWka2jAvk4oDcw3NjI7IbXzWVeqNIcmA87gQTG0LpsACObUIiveJkjYMLc09tMlCDH7885I0UKmoNd0FUx2R4t4/vA/Gpd+hfVdvSWhi7gErfOUGZkPyI5apueFZChZfVAA1RUo+vdLcBpI3CBMMqjFPi2n+3m2HeXk6l6WKG41/f9ZTE2w90PiDv8C2LZU5N5QDJhah7/YtAqPn5Zpg5aBsNMHMxA1LIOKxgjMNa+InFDcdGDyZzCVItbA17zQVy73T2cmbkEnGLp679Xf3mkPUwUjThJqCsC9AADdHN1QCHKrH54CI8pxlZjtYOBMFE5/rt7FH7QgL46kEtW19qfb6ZqVim1lgtmIMtmsZVvKY2NOxud+xzG9dwRZwBTYBEtbfiUw1Dpm5+7w5X3Ys49V8WNmDMJ/ODPQLUGe75G8isq8RBJen6F6HeWNlUqAvzNoMkkYIfXzhzvSpqk/ej8GdmEifU+rsYaHyEaVeB4RcKzBD01k0XFiUrzDwaZ765ez9I8AA3fNnGsau5bcAAAAASUVORK5CYII=">
            <img id="l-ur" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABBdJREFUeNq8lmtoHFUUx8+9d2Zn193sI5tNstmULE22MSSkrRZ8dNMGxRfi84MWoZ8UwXzygViDH9qCmnzRgJAG+kVBUUGwiI8KTZUmlNpW0kJCDKXWNDXr5tXsc2Zn5s71zuqG3XZf2UIOXIaZuXN/c87933MOYoxBJesLNYb55a2GRhbGCFy6DiSZAOz1MV1OIwqAZtdvwmcTV5ZGKq2FygE56HW3hw2t30RSuD8Du/doENqhZ9/5WwyILGJIJjBM/S7Cr6ckkGXEdB39pshwgMPnqwZyUJujjl2iFNwHDsrwwktp4PcVIzF1UYTjo3a4dlVgyQR6s6jHJjB/hDt8Lz62s8E49IqTJf7m/xOHTY+vx2zskd4G9uhO38lb1y/wkHu2SxRhqrtXg0+Or8Od2NkzFjj6nhM0Df4Yn17uyj3HeTA3wuS0aJPgEt+Tn76z1gyLRjAMH63je64CIRB65v6GgduAiAjfCnanRwrsBmeTFz44XFcT1IS9etAD9+1V4cOP4jA8EiPpFBrhDgU3gPymnwe4z+5vz35E6kM1QfNhg0cS2Wemsp96XhH9ATq2odL9XYFpi7up2+ZrLViArl2BeHQVBg8n4ImnlU3DcsYVC8897tUVGYXI+BejQWYY7ztadwDCuGAitnnBQmQ49QMFv9+AUKe+aZhpFgngxnWC11axi7T5XK+JDtfDkrux6GL5UDWDTBHAP4tkY8xOi/DuGy54oK84LGfmOf75R2sQ7bs7MGn1+vdK9f6yIbvrz8swkMBF3407MnDkXKRyirzHBwJg3Eqs9oqTkeSEnljxfZxxK1WJqqtHT2Kmqc1YlGArTJKYAzNmSFsFzGoCC5aono5vCcygoHMVsBVG9apmlzJelqoC3lgguoCwMKml491iXX1plpYBLRWDL4XiKp2PQTYjlUsO5hHi59AqUFU+yeL0ZWgKCqVgqeuzQALbYK73weIHf26Sp8H/olQKatbK5hZ6HvMieYILJ1VsH3MwZ2MLtJWAmdbUGYaW9mDZ3PvV5zbKvTyG/6/CY/Lygl4LrBroxC8SLC+RJHfu0yyQGXSIZuSUllirCVYOaibuj4cduiiywYKehpeoZ3kB/sYe6CBKdH7TsFv3dPHqX9kqYzZYZycsl7+/sLLrtiZqX6d/CGP8jsPbDNvv3X9HZy4HdbmMlVgMh3g41wsqvmln5iKH+OViYiVCk6vRmmFUU0HJYCCCaML25GAl28SHetoGqK6NeLd1iP5QLxDRUjUsFl2AhZkLOtfFjK5m+vNhZRthswcRLNIJg9JuDha8re1gc3qKzlXlJJgRWbo2S1UlncSYDJ6enh/ddOedax0tNsfbVFef5KFy2T0+BSG8oftMKq5oGdnK55zn4GOm9Mut968AAwAAYDu/4Idt8AAAAABJRU5ErkJggg==">
            <img id="l-rd" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA95JREFUeNq8ll1oXEUUx8/Mzv3IfsZtsjEkmphabYJIS/Ng1GrEItTaNigofRVBsK+CQVHig1qhfjzZRUFEEAJB/Ar6IjbEl9oaQh5E07TQmNpmk+xuuvfe7N29c+94ZuuGfOzd3Wyl/4e9w8zd+d1z5pw5hwghoJYO7kl0kwA7QQg9ApR2ekW7S87j3A1C6SKOpjynMPbr3NK3tfYi1YAIGqSKmhQu71EiuxQlFAWqaBDQghIGfC0HuAYOPp1chuPYJYwlPac4gvDVuoEIaqZMHReeO6DF26kev7MEqCX5AfnlBe4W8hbChxA6UROIsH3ounMsHNNC7bvrAm2VY2TAunbZxeHpydnrw77AEozSadYUhvDdfXArcswsiOxfeORk7seppfvK83SjGwOKOhFt7QDHQtekrjQMwwACmpuF/f1F4FzsOXwgMboNqOhN4+F4W6znwOPQ9eAA2JnFhqASJpan4ZHHbHjvwxy8//EN4EXxAhr06DpQRqPL+YAEScU7dzcE3Qh7/W2jNLe/34Gjz9rQ0ip+WAcqejCZuKeXokvX/7xTaCVYWS++bIFlQrO0ksqkdp1iT6J777ZN6oVWg0mFIwIGDxUgEoXPGVO1E9FEh7LRuq1QqezMOdANAwIYwZtpLhScNPQ/VRlW1uGjNkz+ot3LMA2OROJtVd0loa1zs/BmFlPLsLetf9BOqsLKZ2lZmOGUsk5161dXENN037WubreuoNrbx4EW1owuNRiC2yG9SdyM0nos/L9EMeFTZjp1W2CuW8pDsuryYs2Xhef5ri3M07qAl2YZMIybKSOduj/Wdpfvi8U8Jq61DJ9GCcSaN1eXfJ7AfJrC6XfC8Oobpu8ei9cCIL+ZFfPm2Ori38939vUzP9jV6W/g4adtGPYJ/Z++1+HdkUhp7AednFAhEgOLyrbAc7lV6RzXYQdrJPUxXB8x4Luvm0qWVtLol0FYWSKjN50vRPL6xRneCKweqPQAul6gcS+VgC53TuXNVStz9XJDsGpQ0yDw2SchCDB6pnSByB/Z8OAlPrTwx4WfmaoHVi6d3TFsI1SqfKZpDCiHk9z4+dTJbS3GYG/HKV0vviYrtSyet6JyIAWDwltbIz1o1Pymii818ec/w5zD2anzqjv9u9IwTLpRRmU05uUQtq8M820Tjz/U8oplko+OPWersnjKerYTy/DMOG7728oyfWZrf+rbCMvC3N7hJrMZ+uQThwpM1jNZYvySWlqEoc9tm1ihkHdybDL91Y477zI4vst7q1AgQ2j1Hb0PcFPTRPi/2ssv4nWFTzfe4s0g+Axa9EW1/f4VYAC4/xa4NNZJzQAAAABJRU5ErkJggg==">
            <img id="l-dl" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA/BJREFUeNq81k1sG0UUAOA3s7P22rW7jmvHTSA4hZpWiVoSFFAqJaFwQEIqopdK5ABC6QGplTjBoXCBCxLlUkAp4cCPWoEiQFCgEj8BpT9UCpS0UYVAqUFJ6lDHiR3bWXt37f1jxsLFabyx40p5N3vt/XbmvX3zkGVZUCv6I83txOEcxBx/0LKMsKYqIfY9xlyBczhnEcKTRSX32cXo4pla90LrgRTazwvuEUMr3udruYd4/SFwuDzg2VbygCJQlPMgLScgu3DDKOQlnT7AiKbKr1I8UzdIIR8vuM4aur6vraMHi6E24HhHzZ3IpRIQj14DOZMsEKdw+KdrMx/XBCnWRbdqwhtsdYb37qsLuj2yiRjMTl0yMCGnxn+/MWQLMozn4coWnw/teOgA3Emw1UZ/GQOEuakL0/Hu8ve4chu9W61zBw8pqJBPQ2L654YxmnOY/+My8G4v+7h3YHfrsTVgIGiefeDBovjCizkYfj8D6fhMQyjDohM/gG4Y4Al3gjfcgS1Df51V+i2QVWM+j3pfeU0q/SmyS28IvR1jwQlbQAjcDYgjZ26BwZA58vQzCufx/p/PjaLVsHII/u0AltnJagT17Qy2C4J1/cvvU3wlWI7oNIGjh33Q1LIDQrv6NoyVQ07Mgray/A0RRXNwT7dWFatc6UvPzgGOLdFCEFf/gFZ5TEqBLjhtMRZOMQjFbHKAuD3WI9092rrbxdCBXg2eGyMAK/k11992IJDXwcq5pMUjYvqAOyP36zVztFU07fsj4esqKuLyqljXkAc2KxAScKGAhJZWc9NMTIsmE7+JNw+UZZTLSbVBTUP2F029LswsKirhCPx4dZLv7H+0YN+IJQSTVwlExRzs7lh9c0VGMHsdg7EcBc4fsce0An0uTWAvflew2bz8xXcpYoexFx/TTfhwNF31ZuXmwHsDtmgxuwTK0vyvmJ7MU6qK8hfHnQ1hlc1Bk5KllVYLZSlm0lW+W0oez1svj5526Y1g9aBsdZZpSnRxH5XAryaSJ//+i0iffuJqCFsPpd0FlMWYTg/io6tOfHZECS5r7PhbWXLiuGfDmF1OFdkEQ8lfOv/nfN+aEeOp3sCR5RQebr9Xh9Ofp+/ofWPo0GATGzGSlmlEylPcqheQba3ggvHEAgfViqjueYam5IP33LT/WgbFeipHxqpj4oGHQ8OqbB557PECDD0vw/ZWo27s268FOPFmKSUZCndRbK6uQZjmNBxotn5LLqLAE0+q0L+/CHbNYeEmBxfOOWD0lBsUBVkUeoNCxzY8ef8H9/ma4B1ab3syacTd1WZY/m0m4jgAVUEwN8OBSXu/PwAr8X/QSTuoHP8KMAAEGhfDYWpd5wAAAABJRU5ErkJggg==">
        </div>

        <div>
            <span>7 &euro;</span>
            <img id="l-udl" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAFJREFUeNq8lmtoXEUUx8/Mfe02d1/Zm101Sgp2E5fY0tjQhpr0YdUa1Bg/WKtQabX4If1U9EOxIIpIa6FFUGoKUvKhSqvgi2BF27o0CpImVlQEW/DRWrPZJGv2lb17X+PMNVt3m+wz0D8MCztzz2/OzJlzDiKEQCX1hALL6U//rc3mQ3T5CkNHcjKBvIGgFZ2bQ2mOhzNTk3ho5HLsh0q2UDkgBW1q9Ftvx2dwe/emHHR06hBqNew52UUgnUJ0YLg4LkDkjGSoKspgRN4YHps+UBOQgrwer/WxpqGe7Tuy3Lan52xAJV0cE+Ddow3G77/xmVQS9VOPIwsWMWDh6F7RtHrraiW+b7ebpK7R/SSh5nFq0EnuX6nofev8AzfaXwDbHFas1/e66gIVju/PCYTaIz2h4PuFDFx4jA0yORcKGyhyVoLLv/JQr9jdvnlIBk8jtYHQto3h5oH83HWg0mQNd3RqvmNDs/Bgrwp7nvPWBWUw9m38HyfgYCfId7RxxDSPzEf6f0AWjZkM6tr/asr+6MX96bqghTAUWGP/xy9zg+D2S1iQBq8Dm4LWIIvGwkisFboYLK9lwRYgpr6Fecmdfe/ocsuEl187lOREqdjI+g0aTE5geOuwDF33auBXrJph9tvDGExNxRTq4T0e6ylqWCj1zpinTHt3+uC+tQa4PcVQXUcwNipAHAuLwvISXT7QU/F+XhDh4Y41etnjYtBrFxyw8yv/ovMxRxamwveUtSG4GoFYl3zY4SC333KbWfGO2leV2RQWqwoq3imn8V9XuZZQmwE3RQjLOJ+Ib5awy00SS8kqtYkY2OEkURbWlZTNllljWVXhTDUDPMeR8ZGI1MZqXSmxt3h+RICMOwMeL1mwkSspAlpyBkS3v/Se9Bx1EExEM3p/U8D68KMvZvhSsOd3+GAdffgvzae+G3X6MwccPigD728tCc3FJ0CNR0cxLZKfJGaRyYpnPTCm3j4VXtiXBmPmku3pYlLjExb18h07Sj0+Mnj8WENdsGqgWmKKPnorRZ0bsoG0AXrll5/43MjXUl2wclBiGpCNXTUQ5vYUtRjsLh9YpRjfDoukb62fLKXqf37CQbbcrZCtG9eTzR2tZMNdzd/kOUVN1GNdyvFcDu2ihRgOHEku6cXlA0nT+CixzDA9ztmiis/06XfTz/I8JMZHxSW3GOcjIkgSmBTWlYctADINX5jycjw6NbDLCyyQqkkKhWJx8MwTjfDzj2KSNst3UtifVTXCtDp307T3paGD85HHVeh9VIVSST76N2f3pCdPOCE2ydGLQh+cHo9tr7nzngc/6VfgYC5HWqi3qH2lAYL4/zdX/uCAduagBEhmOoZOUo92l7P3rwADAMp0ouSA8KsTAAAAAElFTkSuQmCC">
            <img id="l-ulr" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA/ZJREFUeNq8ll1oXEUUx8/Mnbv3bnazu+m9u4lVSR5MQikNKYltxbQGba0h2lYQ05YKEX2qL9YXSyhFLQW1flSwH6JVwa+oD2ooaK2ipiqYtDQgVZoitsWY3c1HN9lN9uPeO+PMmg3ZJPdmdws5cF5m7j2/+Z85M3MQYwyWso21oRq/n+6SXdCuquy2aARXZTJI0YM0gjDEJIldCP8rfX7uSvTLpWIhJyAHta7Q6JvjY3h1S2sa1jYbUFtnwi0rKVSttODieRkScQwXL8jw43eKORFDlr+CnRyJ4Oc4PFYwkIMC/gD9gqvYuPOxpPTo7mnwli+dCbGAd9/ywB+/kzT/d+eiigVwrrfcEWzc2qiP73/Sx+JDfD2TULT3fqWwLQ26+fDd2tH58fMUcmUdsot1V9dY8F73DbgZ+7XXBS8c8IHLxQZ6fhtdmxvHc2DVgQr20eataRj6R4JXDntLhkWGMbx0qJzveQbSadSwfYO+d0FK2+8MhkUaRUoG+4lICTvyrLfodIYvY7ZtvcYO7yufjcW3SXhNNpsz+/b0ljV63p6VAp0Py/mpIx7W3qwNzAIfXKdPiMH5AYqB2sGECyGb1+iGKEihrkVItqvIQqBOsJy/cdDLdtyl9RC+jZ3iUNuds9p6E46disG+zgoY6ldhdYORN59MIuj9WYb196eg6/m4bSG1PZSCb06rm4geYtvFDeJkAnrvOhM6z2oAgwvnp3xTsN8BlothGODBGIFfXFdLmc9Pbef8AVbQcanjUCwRhmCZDEtAMCyzLTuQJOLI4G8Zr1bnwjEM+8yLSi3Erl+VUkSS0OUrg6SxzeFDvig43ydDVE3ynLjyJymF63EGX/eo0LYt5RiDv6uqFCrzxEYi0iMde5K2Hz71RABG0woM1zTBsB7K92AlxBQVfjkbh2CQZst/Mfv+jApcWB/mj+SnU1OInftBsYWN33ADCjXZrt7l04BodfDqi96s0sWs+0O3xbfuxEzRoG8/+cBdEqwQqOgERqJSgot7Pws8MxB94O+/CPvsY3dJMCeoiHXogM+UZdaVrdI5ip5555jndXHrHH3ZWzRsLpTfKRz6/x0oGqxMBi6d7h89vqCJamsKdScmocO/ggCubL6p85aZHANzbBBUhY1OTODaXBe3oGvbVF/1Nh9+3Ht7vUTKfCXBmGXCdOQaGPHxMKPWBg675tgm3rPq1r3Msl6TfZpSVlkNSCIFwzgEpsNXTcboJQ5und+f2jbCotvGsnKSWcZ9crlGlEAQ7BRTIw3m9CSkxoYtamYSCKGun/4cOl505z0LJvJBRukOnp4K4vYmAOGZlo6ZNJMyqWmofHF9HHxClL5TvP8EGAB+mvgWnLJS9QAAAABJRU5ErkJggg==">
            <img id="l-urd" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABBNJREFUeNq8ll1oHFUUx8+989Wd2d3Z7EfsZiOJtWvVGjGYh0hjtdhKQ7+iAVsFX4yoWBRRH9paan2wtSI1T7Xog6IWAgqlIrSFli6xKgktsYKQKir2I7XZ3WS/d2Z3Zq73Lt2y2+xsdhPpgWHYubPnd8+Zc8/5I0IIzGePhlv7ZAV2ixJ5xOEg8rWrmGfPvT5iIQQGAJqKx2Dkhz+md87nC9UDUtAbPj85kM2AuGadDt0PF2FpmwnBNqt0nzgnQCaNYeK8AJFTEiQTCGQF/ZKYhQEK/6dhIAV1eLzkV10D97bn8/DMczlwuubPBNvAZ4cU+PtPnmTS6E0KHZ7zEgNWXn3LA1vXd/utHS+6Sfoq3U8Kmr5Gj0lk3YN+srYr8POt/qsipJE9xAsw0btKh/0HU7AYY9G+/pIHZJlcOnkh2lF+jitgHsTxEcIpcDYiwfHvliwYRtMJwx86oXOZATSc0JZe/6tzgJgXv+dll+q+qwskTyvs2+taEJTBtg95AFPPX307CweGk1wui4ZpQJ03gfTH48Qye5Xg3aU/ycFlC4JWwj4fmS096+4pwqanNcEfsL6+WaWP3d8+KaqtKxyB9ioHuWt/gZ6Yhl1709C/WWsaVrn21HqfoeVRmDt95BANlexxhsIcwrjqRcHVAsQoQOSEAcGgBeEVRtMwZqIEcOUSh2fiWOVpoTwrOFsEeq/pjKWX2cF3EYwflSF0p1m1XiwiODcuAA4YNWFl69+kwegZaYBHCG8QFHfddDHoEtpuXhsTAMbmrscdOdh9OlrXB/uW2QxqwTQP7ViQ5i0IzuGyXfMFrIaK6r4HjAwmxcLSRoD/h0kScWJCLOl2AUvnkB7460YudVtglgkGLWQSI6bR0Nt2lkqihoBXLnMGjzB/tphLrRRcXntWUaf1PwOfujGonuoxlc8jmEoCfPS+E95+J2Pr498pjp1XxJuF/AmSMofgjk7eDkaiE9D1pAY73kvXdMbaH2uDzOygoxERvH7rQqm1rb63LeFsv0flZXdN2KrVGuyygd0K3TKYrwkd7PdZ09fxEH9jCh/ORy+/5epYyS8EVuokN3ptrUjZZmjq01QBfFEC0EnxgannXykko6qoBpqG1YOyPktlh6Eo1vYqTcNGFMLcKSW0nMOpi03D7NIbj2OY/E348ehPsb45Iop+y52yo7ivu6ewaIlRhsoKidEBHKbpTFRN/FIlTU7tNww4c35cNJkmWYzEYFWpqhaD9ZRhtjKRaRDa2T/ePKiJL7ycbUgiVkbGvhl1OxaL4o2VsLpCmGmQYMg8PDuDn1izVufZPGMjxu5Qs4hGvpQNTUNZViDfjMaPNK28y2Cvz9qj62iAzTM2YljXL/fG3y/y7G6yQ03Bn7DSr+fvPwEGAFvfNmhqNnRbAAAAAElFTkSuQmCC">
            <img id="l-ldr" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA9lJREFUeNq8ln9oU1cUx8997928pEnTJE1rO/dHqXYiKrRWhnTtaFdx6XRbBZlVmBb8T2FM8A/tYLi/xCFOxG5lP9ig+6NiYb9kGbWrc/6jYi37YzJXxCkq/ZnU5Jnk5f24OzdbamzymjRCDyQveffmfHLO/Z53DmGMQT5rqausIaK0mxBhGwjCi0xLVjFmynjvMRGECQAyamrq+SvjU9/n80UWAyKoVaC2PmbotZKzjNISN4h2JwhUTr30WARwDTS8apGQjp8NIkl9ppY8hvC5goEI8giS7QIzjc2yr1q0+6oAo8mbCf4H4tMPwIgrKmagK1fEWUCE1RNBHJGcbq+zelVBoIWmRUPw5NEdg4ji2cu3HrxvCeQwSuEmdchEXtkAz2OaEgYW/gskSiYvXJ+qSt8XMtPodLGRLYEEYXoCjNB40TAUEAiR29CwKQlJla3oaKwcyAKWeczvcIO356Mo9H41h2mZKQrKYWx6DF55NQHHT0VSvvQk24UBNc8DuRqTSdLyAcK41a3Ri4JmwnoyfO3eF4dSNxuaB1a9YJzoejcuukqfnudSoblgaXtnTwx0DRwY2C7SvLqiBu/dDV6egUxg2sZvS3BwvwdoqR9EX92SYWk7c9IFQ0HHJNd8Z3OrmhOWGemhbgIlczEgsnsBzUBhzEJjwBrGrePNBAR/sldK1SuNQMMmbdF0cehrL+vQfdGG3xJZ65+7RTiyCCztQ4kSImEZrq57Sc97Ru4y03KtzMMKEtW6DToIkggSLJNRG3tah8tlyw+MxYiiRPNzNY1YrsXjpCDY/X/w/LAZDI+N0nUtbarlRlQX3LhOYdYRg/KKZ8WTSBD4M8Ig+KMdOt5KWPqYeCRCaBYDw8Kv39FUrjHspble0YeE7Q14WfcbXma1Z3hQZu3r/eznb+2We/haZ5NfAd6eOjb6526O0KJghUK5HwzuyxRwW2P50QM7PVqxsHxQHkygwW+mei9/S0d5rs9RNMwKyn3hkbHAxooBzpkv+miEdH7R67yITx3p9McuHM4Avh4IL1n27Vv/E9/xY6WpKwqSCyseHJ3qyhox3t7sP4BK6q2p1aF/MPxc9fbrkJyCyjKYkcekFgeqe1mF/8PVmU9lmQ1OTojG2A1aNIyX0fAvMlCJzSKsPg2zHBN5pPijU+2vq/J7hxXL1pXLrlyS4ZMTLh3dXpuZFrYvnE8tB2E+bWPr6guHhPa2LarE+5lVG+NF/ftvNjjXX2LiUydKKevh2Vry5J0G+8rND1WVdD5RiHftel3BtLv+77363zgR4NXw+c0/EPwZRvTNYv7+FWAAZ7TVZczZ/wsAAAAASUVORK5CYII=">
        </div>

        <div>
            <span>8 &euro;</span>
            <img id="l-uldr" src="data:image/.png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABJ0AAASdAHeZh94AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA/JJREFUeNq8Vmtok2cUPt81SXNrLpVZnZZprYqIwULFapc5rXbeRcWq1Cv+UPyhG0y6KtvUH+rGxB/qhnMiCjomWlARsRWsxGpbW9ko1f7xVk1tvaRNmjT5vrw774cpSZMvlwo98PKRvO/3Pt95zjnPOQwhBFLZ7PwRefhwjhwlr5EkJk8jEm1kLxBgXjIsNHZ1smfq2t+0pLqLSQaIQE57TvhAdxdbPMvZD/kFMjimB2POuF9x0NwkQEO9GPT7GZ9GQ6qq67uPZwSIQNkIdNbrZcrKK/z86rV9YDCmZqLutgZOnciSO91cj8/LzEnoMQWMXrPG50xbUmTr2LPVRHo78Ht6ION18aSO4D0E79k++P4YD9Gzabos8nDi5BBz7A8PfIq57ojwc5UJOBaOXmvq2hX5n42mUW8gtQWTJKb1PwFqbmqGDNb5moVD+43gKAxCSIKdq0ps6+IoXVhou01ppJRcP6clX0+xk1v/aDKm0/2YpVSSg7uMyu871RoyZ7I9gBRnK2x+jJtz3lS7FB2zoYAOBoss+nvVl9YbA4DLi21tfx7Rx12QCagaGF2v2zgliXDlcTXnj+eFZdi3/3APJw4KW36BBDk5YTh8wAijPpfhi3Gyasy2VVigaGYQKn/qjdunJUXr9d1b1sybzeHymSVBQa3OypYElOeve03g+luGkbmxoFjsUO8SoWiuPyHYQFI6g3DfJc7nBREWOqaHkmYdBX1wOQt2uKwJ930mH+xJAqYAftUPld+aclmtloz+LFdOmeqUUjUzZ5O0ymX0GNnLvnzBjaWxGg5DuTSwkaAOl7FGE/G0P+aHBQyrQWK1OuL29jIpD4dC6mekUHqAT9AxnuNIU3OTWOAoVH+LftDdewK81fWBDesy2no8DLS/B6AsJcsFWofooUxlbdn6Umu/moJQuatYYCEbv7GoqsyR7w0EpZE8aeCTtqyVJdb7irRRcX1YKwwJLF3QFcU2GZ3bqGSp2UJOnv5dH0fjji3ZwOKJvy68Txmf737wQmlZQHlncBLSSQAVqRcngDMKIA5AP7b+y/fTjaGAJQOld/12yCAJAqmM6YcrZ1vX0RZFKUmXxnTopd0De21LwhFj6Qz7aVT0TeMnSBl5lsh+OWiA6ks6wObg9njYSUjnh5gRgxqOd5v1etLyqoMLf4oYUBqDQQay9KQbwWZEwOIAqd1o6XIIPKnaXG4BmkjpiEK0NTcKsGG1NeyqEx/1+Zh8BHuW1iBMJzirLXwOs2viomUBrmxxQLWw6UfRhLt6RSu1tfIyavNutWGYSTXqf5y+d6OilLIccBMQFJ8DfGPM/c+fcjpsPc3o0alkUze1/wUYAPAgWJCfBIReAAAAAElFTkSuQmCC">
        </div>
       <button id="submit" class="btn btn-default" style="margin-left: 30px; disabled"><b>Končano</b></button></p>
    </div>

    <div class="center-block" style="clear: both;">
    </div>

    <p>Klikni svetilko na desni in nato mesto na zemljevidu, kamor jo želiš
        odložiti. Če izbereš napačno, je pač ne odloži, temveč izberi drugo.
        Svetilko, ki je že na zemljevidu, pobrišeš tako, da jo klikneš.</p>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    var carrying;
    var map;
    var lights = [];
    var road_pieces = [];
    var alight, price;

	function puzzleLoad() {
        $("#submit").click(submitSolution);
        $('#submit').toggleClass('disabled', true);
		$("#svg-zemljevid").svg({
			loadURL: 'tasks/T09_XX_07_cestna_razsvetljava/zemljevid.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
        var x, y;
        var obj;
        _.each(['l', 'u', 'd', 'r', 'uldr', 'udl', 'ldr', 'urd', 'ulr',
                'ur', 'rd', 'dl', 'lu'], function(dirs) {
            obj = $('#l-' + dirs);
            obj.attr('data-id', dirs);
            obj.click(light_clicked);
        });
        for(x = 1; x <= 12; x++) {
            for(y = 1; y <= 7; y++) {
                obj = $('#r-' + x + '-' + y);
                if (obj.length > 0) {
                    obj.click(road_clicked);
                    obj.data('coords', [x, y]);
                    road_pieces.push(obj[0]);
                }
            }
        }
        $("#done_button").click(function(){
            submitSolution();
        });
	}

    function light_clicked() {
        carrying = $(this).attr('data-id');
        var cursor = 'tasks/T09_XX_07_cestna_razsvetljava/l_l-' + carrying + '.png';
        $("#task-r").css('cursor', "url('" + cursor + "') 14 14, auto");
    }

    function recompute() {
        var bright = {};
        var difs = {'l': [-1, 0], 'r': [1, 0], 'u': [0, -1], 'd': [0, 1]};
        price = 0;
        _.each(lights, function(data) {
            price += 4 + data[2].length;
            _.each(data[2], function (dir) {
                var dx = difs[dir][0], dy = difs[dir][1];
                var x = data[0], y = data[1];
                while(true) {
                    var obj = $('#r-' + x + '-' + y);
                    if (obj.length === 0)
                        break;
                    bright[obj.data('coords')] = true;
                    x += dx;
                    y += dy;
                }
            })
        });
        alight = 0;
        _.each(road_pieces, function(obj) {
            if ($(obj).data('coords') in bright) {
                alight += 1;
                $(obj).css('fill', '#FFF200');
            }
            else {
                $(obj).css('fill',  '#E6E7E9');
            }
        });
        $('#price').html(price);
        $('#submit').toggleClass('disabled',
            (alight != road_pieces.length) && (price <= 31));
    }

    function road_clicked() {
        if (carrying != null) {
            put_light($(this).data('coords'));
        }
    }

    function put_light(coords) {
        var obj = $('#r-' + coords[0] + '-' + coords[1]);
        var url = 'tasks/T09_XX_07_cestna_razsvetljava/l_l-' + carrying + '.png';
        var svg = $("#svg-zemljevid").svg('get');
        var rx = obj.attr('x'), ry = obj.attr('y');
        var img = svg.image(null, rx, ry, 28, 28, url);
        $(img).data('coords', coords);
        $(img).click(remove_light);
        lights.push([coords[0], coords[1], carrying]);
        $("#task-r").css('cursor', "");
        carrying = null;
        recompute();
    }

    function remove_light() {
        var coords = $(this).data('coords');
        var x = coords[0], y = coords[1];
        lights = _.reject(lights, function(data) {
            return (data[0] == x) && (data[1] == y);
        });
        var svg = $("#svg-zemljevid").svg('get');
        svg.remove($(this));
        if (carrying != null) {
            put_light(coords);
        }
        else {
            recompute();
        }
    }

	function submitSolution() {
        bQ.checkSolution(lights.toString());
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution: String ->
    val pieces = "1-1 2-1 3-1 4-1 5-1 6-1 7-1 7-2 7-3 7-4 8-4 9-4 10-4 11-4 11-5 11-6 10-6 9-6 9-7 6-4 5-4 4-4 3-4 3-5 3-6 2-6 3-7 4-7 5-7 6-7 7-7 7-6 7-5 11-3 11-2 11-1"
        .split(' ')
        .map { it.split("-").map { it.toInt() } }
        .map { it[0]!! to it[1]!! }
        .toSet()

    var price = 0
    val bright = mutableSetOf<Pair<Int, Int>>()
    solution
        .split(",")
        .chunked(3)
        .forEach {
            val x0 = it[0].toInt()
            val y0 = it[1].toInt()
            val dirs = it[2]

            price += 4 + dirs.length
            dirs.forEach { dir ->
                var x = x0
                var y = y0
                while(x to y in pieces) {
                    bright.add(x to y)
                    when(dir) {
                        'l' -> x--
                        'r' -> x++
                        'u' -> y--
                        'd' -> y++
                    }
                }
            }
        }
    bright.size == pieces.size && price <= 31
},
    explanation="""<img style="float: left; margin-right: 10px"
                    src="tasks/T09_XX_07_cestna_razsvetljava/solution-example.png"/>

<p>Ob nalogi si se najbrž pošteno namučil. Tak je bil njen namen: tudi
    računalnik bi se. Ker lahko računalnik kar hitro preišče vse možne
    kolikor toliko smiselne postavitve luči, bi nalogo sicer rešil veliko
    hitreje od tebe. V nekoliko večjem mestu pa bi se oba hitro izgubila.</p>

<p>Slika na levi kaže eno od možnih rešitev.</p>

<p>Nalogam, v katerih iščemo najboljšo - ali vsaj najcenejšo - rešitev nekega
    problema, pravimo <em>optimizacijski problemi</em>. Ti so pogosti težki
    tudi za računalnik, saj dostikrat ne vemo, kako se jih lotiti, da ne bo
    trajalo predolgo, obenem pa ne bomo spregledali kakšne dobre postavitve.</p>

<p>Programi, ki rešujejo takšne probleme, pogosto uporabljajo <em>požrešni
    pristop</em>. V tem primeru bi to pomenilo, da postavimo luč v križišče
    štirih ulic. Nato preverimo, ali je še kako križišče s štirimi
    nerazsvetljenimi ulicami in postavimo luč tja. Ko zmanjka takšnih križišč,
    najdemo takšna s tremi nerazsvetljenimi ulicami (če je katera od njih že
    razsvetljena, se z njim še ne ukvarjamo) in razsvetlimo to...</p>

<p>Takšno reševanje bo včasih uspešno, v tem primeru pa te bo, kot se lahko
    hitro prepričaš, zapeljalo v rešitev, ki bo stala 32 ali celo 36 cekinov.
</p>"""
)
