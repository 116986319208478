val TXX_XX_03_izberi_vodica = Task("TXX_XX_03_izberi_vodica", "Izberi vodiča",

description = """<p>Koga boš izbral za vodiča? Če si nabral dovolj informacij v
vasi, ne bi smelo biti težko. Če jih nisi, se raje vrni v vas in pozanimaj.</p>

<div id="answers" class="block-center bQ-actions">
    <button data-answer="Albert" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Albert</b></button>
    <button data-answer="Albin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Albin</b></button>
    <button data-answer="Aleks" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aleks</b></button>
    <button data-answer="Aleksandar" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aleksandar</b></button>
    <button data-answer="Aleksander" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aleksander</b></button>
    <button data-answer="Alen" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Alen</b></button>
    <button data-answer="Aleš" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aleš</b></button>
    <button data-answer="Aljaž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aljaž</b></button>
    <button data-answer="Aljoša" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Aljoša</b></button>
    <button data-answer="Alojz" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Alojz</b></button>
    <button data-answer="Alojzij" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Alojzij</b></button>
    <button data-answer="Andraž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Andraž</b></button>
    <button data-answer="Andrej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Andrej</b></button>
    <button data-answer="Anej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Anej</b></button>
    <button data-answer="Anton" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Anton</b></button>
    <button data-answer="Anže" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Anže</b></button>
    <button data-answer="Avgust" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Avgust</b></button>
    <button data-answer="Benjamin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Benjamin</b></button>
    <button data-answer="Bernard" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Bernard</b></button>
    <button data-answer="Blaž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Blaž</b></button>
    <button data-answer="Bogdan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Bogdan</b></button>
    <button data-answer="Bogomir" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Bogomir</b></button>
    <button data-answer="Bojan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Bojan</b></button>
    <button data-answer="Boris" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Boris</b></button>
    <button data-answer="Borut" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Borut</b></button>
    <button data-answer="Boštjan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Boštjan</b></button>
    <button data-answer="Božidar" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Božidar</b></button>
    <button data-answer="Božo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Božo</b></button>
    <button data-answer="Branko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Branko</b></button>
    <button data-answer="Bruno" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Bruno</b></button>
    <button data-answer="Ciril" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ciril</b></button>
    <button data-answer="Cvetko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Cvetko</b></button>
    <button data-answer="Damijan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Damijan</b></button>
    <button data-answer="Damir" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Damir</b></button>
    <button data-answer="Damjan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Damjan</b></button>
    <button data-answer="Daniel" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Daniel</b></button>
    <button data-answer="Danijel" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Danijel</b></button>
    <button data-answer="Danilo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Danilo</b></button>
    <button data-answer="Darko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Darko</b></button>
    <button data-answer="David" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>David</b></button>
    <button data-answer="Davor" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Davor</b></button>
    <button data-answer="Davorin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Davorin</b></button>
    <button data-answer="Dejan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Dejan</b></button>
    <button data-answer="Denis" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Denis</b></button>
    <button data-answer="Domen" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Domen</b></button>
    <button data-answer="Dominik" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Dominik</b></button>
    <button data-answer="Dragan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Dragan</b></button>
    <button data-answer="Drago" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Drago</b></button>
    <button data-answer="Dragutin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Dragutin</b></button>
    <button data-answer="Dušan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Dušan</b></button>
    <button data-answer="Edin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Edin</b></button>
    <button data-answer="Edvard" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Edvard</b></button>
    <button data-answer="Elvis" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Elvis</b></button>
    <button data-answer="Emil" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Emil</b></button>
    <button data-answer="Erik" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Erik</b></button>
    <button data-answer="Ernest" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ernest</b></button>
    <button data-answer="Ervin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ervin</b></button>
    <button data-answer="Feliks" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Feliks</b></button>
    <button data-answer="Ferdinand" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ferdinand</b></button>
    <button data-answer="Filip" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Filip</b></button>
    <button data-answer="Franc" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Franc</b></button>
    <button data-answer="Franci" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Franci</b></button>
    <button data-answer="Franjo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Franjo</b></button>
    <button data-answer="Frančišek" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Frančišek</b></button>
    <button data-answer="Gal" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Gal</b></button>
    <button data-answer="Gašper" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Gašper</b></button>
    <button data-answer="Goran" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Goran</b></button>
    <button data-answer="Gorazd" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Gorazd</b></button>
    <button data-answer="Grega" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Grega</b></button>
    <button data-answer="Gregor" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Gregor</b></button>
    <button data-answer="Hasan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Hasan</b></button>
    <button data-answer="Ignac" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ignac</b></button>
    <button data-answer="Igor" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Igor</b></button>
    <button data-answer="Ivan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ivan</b></button>
    <button data-answer="Ivo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ivo</b></button>
    <button data-answer="Izidor" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Izidor</b></button>
    <button data-answer="Iztok" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Iztok</b></button>
    <button data-answer="Jaka" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jaka</b></button>
    <button data-answer="Jakob" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jakob</b></button>
    <button data-answer="Jan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jan</b></button>
    <button data-answer="Janez" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Janez</b></button>
    <button data-answer="Jani" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jani</b></button>
    <button data-answer="Janko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Janko</b></button>
    <button data-answer="Jasmin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jasmin</b></button>
    <button data-answer="Jernej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jernej</b></button>
    <button data-answer="Josip" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Josip</b></button>
    <button data-answer="Joško" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Joško</b></button>
    <button data-answer="Jože" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jože</b></button>
    <button data-answer="Jožef" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jožef</b></button>
    <button data-answer="Jure" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jure</b></button>
    <button data-answer="Jurij" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Jurij</b></button>
    <button data-answer="Karel" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Karel</b></button>
    <button data-answer="Karl" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Karl</b></button>
    <button data-answer="Karol" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Karol</b></button>
    <button data-answer="Klemen" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Klemen</b></button>
    <button data-answer="Kristijan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Kristijan</b></button>
    <button data-answer="Kristjan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Kristjan</b></button>
    <button data-answer="Ladislav" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ladislav</b></button>
    <button data-answer="Leon" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Leon</b></button>
    <button data-answer="Leopold" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Leopold</b></button>
    <button data-answer="Lovro" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Lovro</b></button>
    <button data-answer="Ludvik" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Ludvik</b></button>
    <button data-answer="Luka" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Luka</b></button>
    <button data-answer="Maj" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Maj</b></button>
    <button data-answer="Maks" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Maks</b></button>
    <button data-answer="Maksimiljan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Maksimiljan</b></button>
    <button data-answer="Marcel" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Marcel</b></button>
    <button data-answer="Marijan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Marijan</b></button>
    <button data-answer="Mario" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mario</b></button>
    <button data-answer="Marjan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Marjan</b></button>
    <button data-answer="Mark" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mark</b></button>
    <button data-answer="Marko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Marko</b></button>
    <button data-answer="Martin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Martin</b></button>
    <button data-answer="Matej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Matej</b></button>
    <button data-answer="Matevž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Matevž</b></button>
    <button data-answer="Matic" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Matic</b></button>
    <button data-answer="Matija" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Matija</b></button>
    <button data-answer="Matjaž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Matjaž</b></button>
    <button data-answer="Metod" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Metod</b></button>
    <button data-answer="Miha" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Miha</b></button>
    <button data-answer="Mihael" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mihael</b></button>
    <button data-answer="Milan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Milan</b></button>
    <button data-answer="Milorad" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Milorad</b></button>
    <button data-answer="Miloš" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Miloš</b></button>
    <button data-answer="Miran" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Miran</b></button>
    <button data-answer="Mirko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mirko</b></button>
    <button data-answer="Miro" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Miro</b></button>
    <button data-answer="Miroslav" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Miroslav</b></button>
    <button data-answer="Mirsad" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mirsad</b></button>
    <button data-answer="Mitja" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mitja</b></button>
    <button data-answer="Mladen" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Mladen</b></button>
    <button data-answer="Nejc" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nejc</b></button>
    <button data-answer="Nenad" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nenad</b></button>
    <button data-answer="Nik" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nik</b></button>
    <button data-answer="Niko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Niko</b></button>
    <button data-answer="Nikola" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nikola</b></button>
    <button data-answer="Nikolaj" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nikolaj</b></button>
    <button data-answer="Nino" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Nino</b></button>
    <button data-answer="Patrik" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Patrik</b></button>
    <button data-answer="Pavel" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Pavel</b></button>
    <button data-answer="Petar" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Petar</b></button>
    <button data-answer="Peter" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Peter</b></button>
    <button data-answer="Primož" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Primož</b></button>
    <button data-answer="Rado" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rado</b></button>
    <button data-answer="Radovan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Radovan</b></button>
    <button data-answer="Rafael" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rafael</b></button>
    <button data-answer="Rajko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rajko</b></button>
    <button data-answer="Renato" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Renato</b></button>
    <button data-answer="Robert" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Robert</b></button>
    <button data-answer="Rok" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rok</b></button>
    <button data-answer="Roman" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Roman</b></button>
    <button data-answer="Rudi" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rudi</b></button>
    <button data-answer="Rudolf" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Rudolf</b></button>
    <button data-answer="Samo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Samo</b></button>
    <button data-answer="Sandi" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Sandi</b></button>
    <button data-answer="Saša" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Saša</b></button>
    <button data-answer="Sašo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Sašo</b></button>
    <button data-answer="Sebastijan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Sebastijan</b></button>
    <button data-answer="Sebastjan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Sebastjan</b></button>
    <button data-answer="Senad" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Senad</b></button>
    <button data-answer="Sergej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Sergej</b></button>
    <button data-answer="Silvester" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Silvester</b></button>
    <button data-answer="Silvo" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Silvo</b></button>
    <button data-answer="Simon" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Simon</b></button>
    <button data-answer="Slavko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Slavko</b></button>
    <button data-answer="Slobodan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Slobodan</b></button>
    <button data-answer="Srečko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Srečko</b></button>
    <button data-answer="Stanislav" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Stanislav</b></button>
    <button data-answer="Stanko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Stanko</b></button>
    <button data-answer="Stjepan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Stjepan</b></button>
    <button data-answer="Stojan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Stojan</b></button>
    <button data-answer="Tadej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tadej</b></button>
    <button data-answer="Tilen" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tilen</b></button>
    <button data-answer="Tim" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tim</b></button>
    <button data-answer="Timotej" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Timotej</b></button>
    <button data-answer="Tine" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tine</b></button>
    <button data-answer="Tomaž" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tomaž</b></button>
    <button data-answer="Tomislav" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Tomislav</b></button>
    <button data-answer="Urban" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Urban</b></button>
    <button data-answer="Uroš" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Uroš</b></button>
    <button data-answer="Valentin" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Valentin</b></button>
    <button data-answer="Valter" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Valter</b></button>
    <button data-answer="Vid" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vid</b></button>
    <button data-answer="Viktor" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Viktor</b></button>
    <button data-answer="Viljem" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Viljem</b></button>
    <button data-answer="Vincenc" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vincenc</b></button>
    <button data-answer="Vinko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vinko</b></button>
    <button data-answer="Vladimir" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vladimir</b></button>
    <button data-answer="Vlado" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vlado</b></button>
    <button data-answer="Vojko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Vojko</b></button>
    <button data-answer="Zdenko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Zdenko</b></button>
    <button data-answer="Zdravko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Zdravko</b></button>
    <button data-answer="Zlatko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Zlatko</b></button>
    <button data-answer="Zoran" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Zoran</b></button>
    <button data-answer="Zvonko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Zvonko</b></button>
    <button data-answer="Štefan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Štefan</b></button>
    <button data-answer="Žan" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Žan</b></button>
    <button data-answer="Željko" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Željko</b></button>
    <button data-answer="Žiga" class="btn btn-default" style="float: left; margin-right: 10px; margin-bottom: 5px;"><b>Žiga</b></button>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    function puzzleLoad() {
        $('.bQ-actions').children().click(function (evt) {
				var answer = $(this).data('answer');
				if (answer !== undefined) {
					bQ.checkSolution(`${'$'}{answer}`);
				}
        })
    }
        
    bQ.register(puzzleLoad);
}}
"""),

checkSolution = { it == "Miran" },

explanation="""
    <p>Da si rešil to nalogo, si moral prej rešiti dve drugi, ne? Od ženice si
    izvedel, da te lahko iz vasi popelje stric neke osebe, ki je trenutno ni v vasi.
    Da si izvedel, koga ni v vasi, si moral preučiti Alenkina opažanja o
    širjenju govoric. Nato si moral poiskati strica te, manjkajoče osebe, pri čemer
    ti je pomagal stari bober, ki ti je za vsakega vedel povedati, kdo so njegovi
    otroci.</p>
    
    <img style="float: left; margin-right: 15px;"
         src="tasks/TXX_XX_03_izberi_vodica/opravljivke-explanation.png"/>
    
    <p>Začnimo z govoricami. Novica izvira od Cilke, do Petre in Helene je prispela,
    do Štefke in Jane pa ne. Kako je z ostalimi ne vemo. Manjkati mora nekdo na poti
    od Cilke do Štefke in Jane. To ne more biti Alenka: če bi manjkala Alenka, bi
    brez novice ostala tudi Petra. Gre torej za Ano. Mimogrede ugotovimo tudi, da je
    brez novice gotovo tudi Nataša.</p>
    
    <p>Naloga spominja na računalniško omrežje. Sporočila po internetu potujejo prek
    velikega števila usmerjevalnikov in podobnih naprav, ki so povezane med
    sabo. Če določen del omrežja – na celini, v državi, mestu, podjetju ali
    hiši – neha delovati, je potrebno odkriti, kje je prišlo do prekinitve. V
    resnici tega ne počnemo s tovrstnim izločanjem, temveč z naprednejšimi
    programi za nadzor omrežja. Kadar se kaj pokvari v hiši, sploh, če imamo
    nekoliko bolj zapleteno omrežje, pa prav zares razmišljamo tako, kot si
    razmišljal pri reševanju te naloge.</p>

    <p>Omrežje opravljivk je podobno računalniškim omrežjam še po nečem: sporočilo
    med dvema opravljivkama lahko potuje po različnih poteh. Tudi pravi
    internet je razpeljan na podoben način. S tem so njegovi načrtovalci
    poskrbeli, da ob prekinitvi kake povezave obstajajo obvozi.</p>

    <p>Nato si moral odkriti, kdo je Anin stric. To si počel tako, da si starega
    bobra spraševal po sinovih Adama, po sinovih njihovih sinov in tako naprej. Na
    ta način si sestavil rodbinsko drevo.</p>
    
    <p>Da ne bi izpustil katerega od potomcev, si si najbrž izbral določen vrstni
    red spraševanja. Računalnikarji uporabljajo predvsem dva. Enemu pravijo preiskovanje
    v širino. Najprej izveš za Adamove otroke. Nato za vsakega od otrok vprašaš, kdo
    so njegovi otroci in tako dobiš Adamove vnuke. Nato za vsakega od Adamovih
    vnukov vprašaš, kdo so njegovi otroci in tako dobiš vse Adamove pravnuke...</p>
    
    <div style="text-align: center; margin-bottom: 20px;">
        <img src="tasks/TXX_XX_03_izberi_vodica/explanation-tree-breadth.png"/>
    </div>
    
    <p>Drugi način je preiskovanje v globino: ko izveš za Adamove otroke, vprašaš
    po potomcih prvega otroka. Nato ne vprašaš po potomcih drugega Adamovega otroka,
    kot si storil pri iskanju v širino, temveč vprašaš po potomcih prvega Adamovega
    vnuka (torej prvega otroka prvega Adamovega otroka). Tako dobiš nekaj Adamovih
    pravnukov in vprašaš po potomcih prvega. Šele, ko končaš s celotno rodbino
    prvega Adamovega sina, se lotiš drugega.</p>
    
    <div style="text-align: center; margin-bottom: 20px;">
        <img src="tasks/TXX_XX_03_izberi_vodica/explanation-tree-depth.png"/>
    </div>"""
)
