val T13_SI_04_ogrlice = Task("T13_SI_04_ogrlice", "Ogrlice",

description = """

<p>Ko prodajalka vidi, kako zaljubljeno Alenka gleda ogrlice, ti pa si brez
denarja, se odloči, da ti bo dala eno zastonj. Izberi jo.</p>

<p>Popaziti moraš le na tole: Alenka je bobrovka in ima kar širok vrat. Vzemi
ogrlico, ki bo najdaljša. Ko jo primeš za zaponki in raztegneš, se mora
raztegniti na vsaj 32 centimetrov. Dolžine vrvic so napisane ob vrvicah.</p>

<div id="T13_SI_04_solution" class="center-block bQ-actions bQ-shuffle" style="width: 520px">
    <img data-answer="1" src="tasks/T13_SI_04_ogrlice/ogrlica-1.svg" style="margin:20px">
    <img data-answer="2" src="tasks/T13_SI_04_ogrlice/ogrlica-2.svg" style="margin:20px">
    <img data-answer="3" src="tasks/T13_SI_04_ogrlice/ogrlica-3.svg" style="margin:20px">
    <img data-answer="4" src="tasks/T13_SI_04_ogrlice/ogrlica-4.svg" style="margin:20px">
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {
    function puzzleLoad() {
        $('.bQ-actions').children().click(function (evt) {
				var answer = $(this).data('answer');
				if (answer !== undefined) {
					bQ.checkSolution(`${'$'}{answer}`);
				}
        })
    }
        
    bQ.register(puzzleLoad);
}}
"""),

checkSolution = { it == "1" },

    explanation="""
    <p>Verjetno si že velikokrat videl naloge, v katerih bober išče najkrajšo
    pot med dvema krajema, najkrajšo pot domov ali kaj podobnega. Tudi na
    tekmovanju Bober so pogoste. Tule pa te je ista naloga pričakala v drugi
    preobleki. Tudi dolžina ogrlice od zaponke do zaponke je namreč enaka
    najkrajši poti med njima. Daljša pač ne more biti: ker je od ene do druge
    zaponke <em>možno</em> priti po poti z določeno dolžino, zaponk ne moremo
    razmakniti toliko, da bi bila "zračna razdalja" med njima večja. Pač pa bi
    se lahko zgodilo, da bi se vrvice "zavozljale" in ogrlice ne bi bilo možna
    raztegniti na tolikšno dolžino. Vendar smo slike narisali tako, da se to ne
    zgodi.</p>

    <p>Naloga kaže, kako lahko za navidez povsem različnimi nalogami v
    resnici tiči en in isti problem. V tem primeru smo naleteli na problem
    iskanja najkrajše poti v nalogi, ki po tem ni niti najmanj dišala.</p>"""
)
