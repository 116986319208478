val regionVidre = Region(
    "7_vidre",

    Location("0700_jez", null, "Jez", """
        Tu smo! Po vseh nezgodah, ki so te čakale na poti, te je
        vodič pripeljal točno na kraj, kjer stoji tisto, zaradi česar ostaja vas brez
        vode: nekdo je postavil ogromen jez, ki je preusmeril Bobrščico iz njene
        običajne struge, ki vodi proti jugu. Skozi jez teče le nekaj drobnih curkov.
        Za njim pa je nastalo ogromno jezero in voda se preliva v novo strugo nekam
        proti severovzhodu.""",

        "J" to "0701_soteska",
        "SZ" to "0702_ustje_jezera"),

    Location("0701_soteska", null, "Struga", """
        Pot po presušeni strugi Bobrščice se hitro konča v
        neprehodnih strminah. Raje se vrnita nazaj gor.""",

        "S" to "0700_jez"),

    Location("0702_ustje_jezera", null, "Ustje jezera", """
        Z Alenko sta prišla do mesta, kjer so voda iz jezera preliva po strmem
        bregu v dolino. V dolini se voda nabira v drugem, še večjem jezeru.""",

        "JV" to "0700_jez",
        "JZ" to "0703_jezero"),

    Location("0703_jezero", null, "Jezero", { state ->
        """<p>Prispela sta v kraljestvo vider. Česa takega nisi videl še
        nikoli. Stotine vider živijo tu. Videti so srečne, brezskrbne ... in prijazne.
        Ko vaju zagledajo, utrujena popotnika, vaju prijazno pozdravijo, vama postrežejo
        z jedačo in pijačo. Res jim ne manjka ničesar. Predvsem pa ne tistega, česar
        zmanjkuje v tvoji vasi: vode.</p>""" +
        if (FlagNotSet("to_king").verify(state))
        """<p>Vendar veš tudi, odkod jim vsa ta voda: iz Bobrščice, ki je včasih tekla k
        vam. Ko sta sita in spočita, vidram vendarle previdno omeniš, čemu si prišel.
        Prijazni gostitelji te razumejo ter predlagajo, da greš h kralju vider in še
        njemu poveš, kaj te tare."""
        else if (And(FlagIsSet("told_king"), FlagNotSet("what_wrong")).verify(state))
        "<p>S kraljevskega dvora se sliši tarnanje. Bogve, kaj se dogaja!</p>"
        else if (And(FlagIsSet("what_wrong"), Not(HasSolved("TXX_XX_04_zmesaj_zdravilo"))).verify(state))
        "<p>Alenka pravi, da je vrač na zahodu, in že hiti v temni gozd.</p>"
        else ""},

        "J" to "0701_soteska",
        "SV" to "0702_ustje_jezera",

        commands = Commands(
            Command("Na dvor",
                showOn=FlagNotSet("told_king"),
                actions=Actions(
                    SetFlag("to_king1"),
                    MoveTo("0707_dvor")
                )
            ),
            Command("Na dvor",
                showOn=FlagIsSet("told_king"),
                actions=Actions(
                    SetFlag("to_king2"),
                    MoveTo("0707_dvor")
                )
            ),
            Command("Sledi Alenki",
                showOn=And(FlagIsSet("what_wrong"),
                           Not(HasSolved("TXX_XX_04_zmesaj_zdravilo"))),
                action=MoveTo("0704_pri_vracu")
            )
        )
    ),

    Location("0704_pri_vracu", null, "Pri vraču", """
        <p>Alenka te je pripeljala do votline. Na vratih visi listek
        <em>Na srečanju vračev na Irskem. Pridem prihodnji mesec. Vstopi, če si upaš.
        </em></p>
        <p>Lepa reč. Si upaš? Alenka pravi, da je bil vrač videti čisto prijazno in
        najbrž ni nastavil nič smrtonosnega za nepovabljene goste.</p>""",

        "Vstopi" to "0705_vraceva_votlina",
        "K jezeru" to "0703_jezero"
    ),

    Location("0705_vraceva_votlina", null, "V vračevi votlini", """
        <p>Plaho vstopiš, saj se bojiš, kaj vaju bo zadelo. Vendar se
        ni zgodilo nič posebnega. Nasprotno, votlina je nenavadno svetla. Na mizi je
        debela stara knjiga, police pa so polne stekleničk z raznimi tekočinami.
        </p>""",

        "Ven" to "0704_pri_vracu",

        commands = Commands(
            Command("Beri knjigo",
                action=Message("""
                    <p>Ah, nesreča! Vračeva knjiga je pisana v šifrah. Takole pravi:</p>
                    <p style="text-align: center"><em>EB EPCJT ŽESBZJMP, LJ PŽESBZJ ZŠBLP
                    CPMFŽFO, NPSBT EPCSP RSFNFTBUJ FMPLJNPOJP, RSJUBOEJKP JO DJŠUP
                    NBMP SBCPZPUFOB</em></p>
                    <p>Zazreš se v naslovnico knjige, na kateri piše "DBSPCOJ OBRPKJ".
                    Najbrž to pomeni "ČAROBNI NAPOJI".</p>""")
            ),

            StartTask("Namešaj zdravilo", "TXX_XX_04_zmesaj_zdravilo",
                onSuccessActions = Actions(
                    Message("Z Alenko povohata, kar sta zvarila."),
                    Message("""Diši po zdravilih, ali bo res kaj pomagalo, pa ne vesta. 
                               Si upata dati piti tole reč kraljevi hčeri?"""),
                    GetItem("zdravilo")
                ),
                onFailureActions = Actions(
                    Message("Z Alenko povohata, kar sta zvarila."),
                    Message("Alenka se pred tvojimi oči seseda na tla. Hočeš ji pomagati..."),
                    Message("... a tudi pred tvojimi očmi se svet nenadoma zavrti, nato pa se vse stemni."),
                    Message("Ko se zaveš, zagledaš pred votlino Alenko, ki vsa zelena v obraz bruha kot vidra."),
                    Message("Še dobro, da sta preživela tale zvarek.")
                )
            )
        )
    ),

    Location("0707_dvor", null, "Kralj vider", { state ->
        "<p>Kraljevi dvor je ves v draguljih in zlatu, kot v najlepši pravljici.</p>" +
        if (FlagNotSet("told_king").verify(state)) "<p>Prijazni kralj te vpraša, kaj te tare.</p>"
        else if (FlagNotSet("what_wrong").verify(state)) "<p>A kljub blesku je vse narobe.</p>"
        else "" },

        "Ven" to "0703_jezero",

        commands = Commands(
            Command("Povej o Bobrščici",
                showOn=FlagNotSet("told_king"),
                actions=Actions(
                    SetFlag("told_king"),
                    Message("""
                        <p>Kralju poveš, da je ostala vaša vas brez vode, ker so jo vidre s svojim
                        velikim jezom preusmerile v svoje jezero. Prijazni kralj te razumevajoče
                        posluša. Na koncu ti s težkim srcem pove, da so morali storiti tako. Ko ga
                        vprašaš, zakaj, ti noče povedati: pravi, da je ta zgodba preveč žalostna.""")
                    )
            ),
            Command("Vprašaj, kaj je narobe",
                showOn=And(FlagIsSet("to_king2"), FlagNotSet("what_wrong")),
                actions=Actions(
                    SetFlag("what_wrong"),
                    Message("""
                        <p>Pozanimaš se med dvorjani in izveš, da je kraljeva hči, prelepa Filomena,
                        na smrt zbolela.</p>
                        <p>Alenka, ki boljše pozna te kraje, in je s starši nekoč že obiskala to dolino
                         (vendar tedaj v njej še ni bilo ne jezera ne vider), ti prišepne, da živi nekje
                         tod blizu vrač, ki zna ozdraviti vse. Pravi, da bi morda celo našla pot do
                         njega.</p>
                         <p>Kako pametna punca!</p>"""
                    )
                )
            ),
            Command("Daj zdravilo",
                showOn=HasItem("zdravilo"),
                actions=Actions(
                    Message("Kraljična popije zdravilo in se zruši nazaj na posteljo."),
                    Message("""<p>Nato pa odpre oči, kot prerojena skoči pokonci in vaju
                               oba z Alenko objame.</p>
                               <p>Presrečni kralj ti ponudi, kar si zaželiš. Ti pa 
                               dobro veš, čemu si šel na to dolgo pot."""),
                    DropItem("zdravilo"),
                    SetFlag("daughter_healed")
                )
            ),
            Command("Prosi kralja",
                showOn=FlagIsSet("daughter_healed"),
                actions=Actions(
                    Message("""
                        <p>&mdash; <em>Vaše veličanstvo, saj veste, po kakšnem opravku sem šel na to
                        dolgo in nevarno pot. Naša vas je ostala brez reke, brez vode. Tudi mi bomo
                        morali iti s trebuhom za kruhom. Videl sem vaše imenitno kraljestvo in vem, da
                        ne morete brez reke. A vendar, ko bi se dalo&nbsp;... Ko bi lahko
                        razmislili&nbsp;... Ko bi našli kak način&nbsp;...</em></p>
                        <p>Kralj se je mahoma zresnil. Za trenutek ti je postalo žal, da si skalil
                        njegovo veselje. Nato pa si se spomnil na svoje starše, na svojo sestrico, na
                        prijatelje. Kralj je na srečo hitro prekinil mučno tišino.</p>
                        <p>&mdash; <em>Sin moj, veš da ti ne morem odreči. Vendar moram misliti tudi
                        na svoje podanike. Vodo potrebujemo. Vendar je imamo zdaj dovolj. Deževno
                        obdobje je in voda teče v jezero iz vseh potočkov. Jutri navsezgodaj pošljem
                        delavce, da odprejo jez in spustijo vodo nazaj v staro strugo.</em></p>
                        <p>Malo se je ustavil, kot da bi mu bilo nerodno.</p>
                        <p>&mdash; <em>Vendar pride sušno obdobje in takrat bomo vodo spet potrebovali.
                        Takrat bomo morali zapreti jez. Hudo mi bo za vas. Če želite, se priselite k
                        nam, dobri sosedi bomo.</em></p>
                        <p>&mdash; <em>Veličanstvo, hvala za vodo, pa tudi za gostoljubje. Vendar:
                        v vasi so živeli naši pradedje in prapradedje. Ko bi bilo mogoče urediti&nbsp;...
                        </em></p>
                        <p>&mdash; <em>Da, da, ko bi bilo mogoče&nbsp;...</em>&nbsp;se je zamislil kralj.
                        <em>Pridi jutri. Povem ti skrivnost, ki sem ti jo bil zamolčal, ko sva govorila
                        prvič. Izvedel boš, zakaj smo se priselili sem. Potem se bova pogovorila.</p>
                        """),
                    MoveTo("0708_konec")
                )
            )
        )
    ),

    Location("0708_konec", null, "Konec", """
        <p>Kralj je držal obljubo in naslednje jutro te je zbudil hrup delavcev, ki
        so odpirali jez, in mogočno bučanje vode, ki se je izlila iz jezera. Vidram pa,
        kot je predvidel kralj, res ni bilo hudega, saj so bili vsi okoliški potoki
        polni vode.</p>
        <p>Alenka je morala oditi domov, da staršev ne bi skrbelo, kod hodi. Vendar sta
        si obljubila, da se kmalu spet vidita. Pospremil si jo na vlak. Tako rad bi se
        odpeljal z njo. Pa tudi domov bi šel rad.</p>
        <p>Vendar sta s kraljem po zajtrku odšla na sprehod po dvornem parku in povedal
        ti je, kaj je pregnalo vidre z njihovih domovanj, da so morale priti v to
        dolino, si pripraviti bivališča v njej in zato zgraditi tudi jez, ki so ga zdaj
        - vendar le za nekaj časa - odprli.</p>
        <p>Tvoja naloga je opravljena. Od doma si šel kot mlad, neizkušen bobrček,
        na svoji poti pa si videl več sveta kot kdorkoli v tvoji vasi. Ko boš prišel
        domov boš lahko vsem pripovedoval, kaj vse si videl in storil.</p>
        <p>A kralj ti je povedal, kaj bi bilo potrebno storiti, da bi bil jez za vedno
        odprt. Iti na sever proti...</p>
        <p>Ampak to je že naslednja zgodba.</p>"""
    ),

    hasMap=false
)
