val T13_SI_14_natakar = Task("T13_SI_14_natakar", "Zmedeni natakar",

description = """<p>Natakar Bob je dobil naročilo za jagodni, limonin in pomarančni sok
    ter vodo. Ko je napolnil kozarce, je opazil, da so označeni (slika sadja).</p>
<ul>
<li>V kozarcu 1 je namesto jagodnega soka pomarančni sok.</li>
<li>V kozarcu 2 je namesto limoninega jagodni sok.</li>
<li>V kozarcu 3 je namesto pomarančnega limonin sok.</li>
<li>V kozarcu 4 je voda.</li>
</ul>

<p>Sklenil je popraviti napako. Na voljo ima le te štiri kozarce. Sokov ne
    sme mešati ali jih zliti v umivalnik. V umivalnik sme, če hoče, izliti
    le vodo. Sestavi si pameten načrt za prelivanje.</p>

<div id="glasses" class="center-block" style="width:503px;height:150px"></div>

<style>
    #steps { width: 400px; border-top: 1px solid black; margin-top: 20px; }
    #steps tr { border-bottom: 1px solid black; font-size: 20px; height: 40px; }
    #steps td p { margin: 3px; padding: 3px; padding-left: 10px; border-radius: 8px; }
</style>

<div class="center-block">
<!--<div id="steps" style="padding-left: 30px; margin-top: 10px;"></div>-->

<table id="steps">
    <tbody>
    </tbody>
</table>

<div style="margin-top: 15px; text-align: center;">
    <button id="delete_button" class="btn btn-default">
        <b>Pobriši zadnji korak</b>
    </button>
    <button id="done_button" class="btn btn-default">
        <b>Preveri</b>
    </button>
</div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var steps = [];
    var steps_html = "";

    function puzzleLoad() {
		$("#glasses").svg({
			loadURL: 'tasks/T13_SI_14_natakar/glasses.svg',
			onLoad: buttonsInit
		});
	}

	function buttonsInit() {
        for(var i = 1; i <= 4; i++) {
            var obj = $("#juice" + i);
            obj.attr("data-id", i);
            obj.click(function() { puzzleAdd($(this).attr("data-id")); });
        };
        $("#sink").click(function() { puzzleAdd(5); });
        $("#delete_button").click(function() { deleteLast(); });
        $("#delete_button").attr("disabled", "disabled");
        $("#done_button").click(function() { checkSolution(0); });
	}

	function puzzleAdd(num) {
        if ((steps.length == 0) || (steps[steps.length - 1].length == 2)) {
            steps.push([num]);
            if (num == 5) {
                num = "pipa";
            }
            $("#steps > tbody:last").append(
                '<tr><td id="step' + steps.length + '"><p>' + num + '</p></td></tr>');
        }
        else if (steps[steps.length - 1].length == 1) {
            steps[steps.length - 1].push([num]);
            if (num == 5) {
                num = "umivalnik";
            }
            var obj = $("#step" + steps.length + " p");
            obj.append(" &gt; " + num);
            if (steps.length == 8) {
                checkSolution(0);
            }
        }
        $("#delete_button").removeAttr("disabled");
	}

    function inGlass(i) {
        return $("#juice" + i).attr("class")
    }
    
    function checkSolution(st) {
        if (st > 0) {
            $("#step" + st + " p").css("background-color", "").css("border", "none");;
        }
        if (st == steps.length) {
            submit(1000);
        }
        $("#step" + (st + 1) + " p").css("background-color", "#dddddd").css("border", "2px solid #dddddd");

        var src = steps[st][0];
        var dst = steps[st][1];
        if (dst == 5) {
            if (src < 5) {
                animateJugs(src, undefined);
            }
        }
        else if (inGlass(dst) != "empty") {
            $("#step" + (st + 1) + " p").css("background-color", "red");
            submit(5000);
            return;
        }
        else if (src == 5) {
            $("#juice" + dst).attr("class", "water");
            animateJugs(undefined, dst)
        }
        else {
            $("#juice" + dst).attr("class", inGlass(src));
            animateJugs(src, dst)
        }
        
        setTimeout(function(){ checkSolution(st + 1); }, 2000);
    }

    function animateJugs(src, dst) {
        if (src) {
            $("#clip" + src).animate(
                {svgY: 71 + 116, svgHeight: 0},
                { duration: 600,
                  done: function() { $("#juice" + src).attr("class", "empty") },
                  queue: false }
            );
        }
        if (dst) {
            $("#clip" + dst).animate(
                {svgY: 71, svgHeight: 116},
                { duration: 600,
                  queue: false });
        }
    }

    function submit(pause) {
        setTimeout(function(){
            bQ.checkSolution(steps.toString());
        }, pause);
    }


    function deleteLast() {
        if (steps.length > 0) {
            steps.pop();
            $("#step" + (steps.length + 1)).parent().remove();
            if (steps.length == 0) {
                $("#delete_button").attr("disabled", "disabled");
            }
        }
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = chk@{ solution ->
    solution
        .split(',')
        .map { it.toInt() - 1}
        .chunked(2)
        .fold(mutableListOf<String?>("orange", "strawberry", "lemon", "water")) { state, next ->
            val (src, dst) = next
            if (dst == 4) {
                if (src < 4) {
                    state[src] = null
                }
            }
            else if (state[dst] != null)
                return@chk false
            else if (src == 4) {
                state[dst] = "water"
            }
            else {
                state[dst] = state[src]
                state[src] = null
            }
            state
        } == listOf("strawberry", "lemon", "orange", "water")
    },
    explanation="""<p>Kozarci predstavljajo "spremenljivke" v programu – nekakšne škatlice, v
    katere shranjujemo vrednosti, recimo številke.</p>

<p>Tako kot pretaka natakar - v tem primeru ti - sokove med kozarci, premikajo
računalnikarji podatke po računalniku. Vsak podatek mora biti nekje shranjen
(sicer je izgubljen). Kadar jih je potrebno prestavljati, morajo ravnati
podobno kot si pri reševanju naloge ravnal ti: nekje pripravijo prostor, tja
začasno odložijo nek podatek, premešajo ostale in postavijo prvega tja, kamor
sodi.</p>

<p>Ko si določil vrstni red pretakanj, si pravzaprav napisal preprost program.
</p>"""
)
