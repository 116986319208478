import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.RBuilder
import react.dom.*

typealias LocId = String

data class Coordinates(val x: Double, val y: Double) {
    constructor (x: Int, y: Int): this(x.toDouble(), y.toDouble())
}

class Commands(vararg commands: Command): ArrayList<Command>(listOf(*commands))

typealias ExitDef = Pair<String, String>

class ExitList(vararg exits: ExitDef): ArrayList<ExitDef>(listOf(*exits))

inline fun <reified T>castExits(exits: Any?) = exits as? T

class Location (
    val id: LocId,
    val coordinates: Coordinates?,
    val title: String,
    val description: (GameState) -> String,
    val commands: Commands = Commands(),
    val autoCommands: Commands = Commands(),
    var region: Region? = null
): Checkable {
/*    constructor(id: LocId, coordinates: Coordinates?, title: String,
                description: String,
                exits: Any?,
                commands: Commands = Commands(), autoCommands: Commands = Commands()):
            this(id, coordinates, title,
                { description.trimIndent().trim().split("\n\n").joinToString("\n") { "<p>$it</p>" } },
                castExits<ExitDef>(exits) ?.let { ExitList(it) } ?: castExits<ExitList>(exits),
                commands, autoCommands
                )*/
    constructor(id: LocId, coordinates: Coordinates?, title: String,
                description: String,
                vararg exits: ExitDef,
                commands: Commands = Commands(), autoCommands: Commands = Commands()):
            this(id, coordinates, title,
                 { description.trimIndent().trim().split("\n\n").joinToString("\n") { "<p>$it</p>" } },
                 commands.let {
                     exits.forEach { (dir, locId) -> it.add(Exit(dir, locId)) }
                     it
                 },
                 autoCommands
                )
    constructor(id: LocId, coordinates: Coordinates?, title: String,
                description: ((GameState) -> String),
                vararg exits: ExitDef,
                commands: Commands = Commands(), autoCommands: Commands = Commands()):
            this(id, coordinates, title, description,
                 commands.let {
                     exits.forEach { (dir, locId) -> it.add(Exit(dir, locId)) }
                     it
                 },
                 autoCommands
                )

    override fun checkConsistency() =
        listOf(mergeConsistency(commands), mergeConsistency(autoCommands))
            .filter { it != ""}
            .joinToString("\n")
}


class MoveTo(val locId: LocId, val direction: String?=null, val pathVisible: Boolean = true): Action() {
    override operator fun invoke(state: GameState) {
        if (pathVisible)
            state.pathsTaken
                .getOrPut(state.location to locId, { mutableSetOf() })
                .add(direction)
        state.visited.add(locId)
        state.location = locId
    }

    override fun checkConsistency() = checkLocationExists(locId)
}


fun checkLocationExists(locId: LocId?) =
    checkExists(locId, repository.locations, "Location $locId")


class Region(val name: String, vararg locations: Location, val hasMap: Boolean = true) {
    val locations: List<Location> = listOf(*locations)

    init {
        this.locations
            .onEach { it.region = this }
            .onEach { repository.locations[it.id] = it }
    }
}

class Exit(description: String, leadsTo: LocId):
    Command(description, MoveTo(leadsTo, description))
{}


fun RBuilder.location(location: Location, commands: List<Command>,
                      state: GameState,
                      enqueueCommand: ((Command) -> Unit),
                      explainTask: (String) -> Unit) {
    child(renderHtml(location.description(state)))
    val shownCommands = commands.filter { it.description !in directions
                                          && (it.showOn?.verify(state) ?: true) }
    val solvedTasks = commands
        .filter { it is StartTask
                  && (it.actions[0] as? ShowTask)?.let { it.task.id in state.solvedTasks
                                                         && repository.tasks[it.task.id]!!.explanation != null }
                                                 ?: false }

    if (shownCommands.isEmpty() && solvedTasks.isEmpty())
        return

    div(classes="bQ-control-line") {
        if (shownCommands.isNotEmpty()) {
            ul(classes = "bQ-controls") {
                key = "bQLocationActions"
                shownCommands.forEach { command ->
                    li {
                        button(type = ButtonType.button, classes = "btn") {
                            attrs {
                                onClickFunction = { enqueueCommand(command) }
                            }
                            +command.description
                        }
                    }
                }
            }
        }
        if (solvedTasks.isNotEmpty()) {
            div(classes = "solved-tasks") {
                +"Razlaga: "
                solvedTasks.withIndex().forEach {  (i, command) ->
                    val task = (command.actions[0] as ShowTask).task
                    if (i > 0) +", "
                    a {
                        attrs {
                            onClickFunction = { explainTask(repository.tasks[task.id]!!.explanation!!) }
                        }
                        +task.title
                    }
                }
            }
        }
    }
}
