val T12_FR_06_trgovanje = Task("T12_FR_06_trgovanje", "Trgovanje",

description = """ <style>
    #buttons img {
        max-width: 64px;
        max-height: 64px;
    }
    #buttons a {
        margin: 10px;
        border-radius: 15px;
    }

    #buttons a:hover {
        background-color: white;
    }
</style>
<p>Viliju boš lahko pomagal tako, da boš toliko časa zamenjeval stvari, da
prideš do hiše.</p>

<div style="text-align: center; padding: 16px; margin-bottom: 25px">
    <div class="center-block"
         style="text-align: center; border: solid thin black; padding: 10px;
                background-color: white; border-radius: 15px; width: 220px">
<p>Trenutno imaš</p>
    <img id="current_item" style="max-height: 64px; max-width: 64px"/></div>
</div>

<div id="buttons" class="center-block" style="width:100%;text-align:center;">
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

    var exchanges = [
        ["pin", "ballon"],
        ["pin", "basket"],
        ["ballon", "boat"],
        ["boat", "bike"],
        ["ballon", "bycicle"],
        ["basket", "boat"],
        ["basket", "dog"],
        ["dog", "ballon"],
        ["bycicle", "ballon"],
        ["dog", "rug"],
        ["rug", "bike"],
        ["picture", "rug"],
        ["bycicle", "bike"],
        ["rug", "house"]
    ];
    var current_item = "pin";
    var answer = 'pin';
    var items_url = 'tasks/T12_FR_06_trgovanje/items' + '/';

	function puzzleLoad() {
        var btns = $('#buttons');
        var i;
        for(i = 0; i < exchanges.length; i++) {
            var src = exchanges[i][0];
            var dst = exchanges[i][1];
            var elm = $(document.createElement('button'))
                .attr('class', 'btn btn-default')
                //.css('position', 'absolute')
                .data('src', src)
                .data('dst', dst)
                .append('<img src="' + items_url + src + '.png"/> &rarr; ' +
                        '<img src="' + items_url + dst + '.png"/>')
                .click(make_exchange);
            btns.append(elm);
            exchanges[i].push(elm);
        }
        set_buttons();
	}

    function set_buttons() {
        _.each(exchanges,
            function(e) {
                if (e[0] == current_item) {
                    e[2].removeAttr('disabled');
                    e[2].css('opacity', 1);
                }
                else {
                    e[2].attr('disabled', 'disabled').css('opacity', 0.4);
                }
            });
        $('#current_item').attr('src', items_url + current_item + '.png');
    }

    function make_exchange() {
        if ($(this).data('src') == current_item) {
            current_item = $(this).data('dst');
            set_buttons();
            answer += ' ' + current_item;
            if (current_item == 'house') {
                bQ.checkSolution(answer);
            }
        }
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolution = { solution ->
    val exchanges = mapOf(
        "pin" to setOf("ballon", "basket"),
        "ballon" to setOf("boat", "bycicle"),
        "boat" to setOf("bike"),
        "basket" to setOf("boat", "dog"),
        "dog" to setOf("ballon", "rug"),
        "bycicle" to setOf("ballon", "bike"),
        "rug" to setOf("bike", "house"),
        "picture" to setOf("rug")
    )
    val sequence = solution.trim().split(' ')
    sequence.last() == "house" && sequence.zipWithNext().all { (src, dst) -> dst in exchanges[src] ?: setOf<String>() }
},

explanation="""
    <p>Preden si prišel do te naloge, si zelo podobno reč reševal že enkrat ali
    dvakrat. Se spomniš, kdaj? Morda ti bo odgovorila slika?</p>

    <div style="text-align: center; margin-bottom: 20px;">
        <img src="tasks/T12_FR_06_trgovanje/graf.png"/>
    </div>
    
    <p>Ko si reševal Ujemi barvo, si moral "prehoditi pot", od začetnega položaja
    do želenega končnega. Če si se zmotil pri izbiranju ogrlice in si moral reševati
    Alenko, si moral "prehoditi pot" od praznih vrčev do enega od stanj, v katerem
    je v enem od vrčev natančno štiri litre vode. Tule moraš prehoditi pot od
    zaponke do hiše.</p>
    """
)