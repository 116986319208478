val T09_XX_33_zvonci = Task("T09_XX_33_zvonci", "Hišni zvonci",

description = """<p>V bloku živijo sami računalnikarji. Temu primerna je plošča z imeni
    stanovalcev in tipkami z zvonci. Kje boš pozvonil?</p>

<div class="center-block" style="position:relative;width:686px;height:453px">
	<div id="svgload" style="position:relative;width:686px;height:453px">
	</div>
</div>

""",

runTask = eval("""{ run: (bQ, _=underscore) => {

	var puzzle_solution = "";

	function puzzleLoad() {
		$("#svgload").svg({
			loadURL: 'tasks/T09_XX_33_zvonci/seznam_stanovalcev.svg',
			settings: {},
			onLoad: puzzleInit
		});
	}

	function puzzleInit() {
		for(var i = 0; i <= 15; i++) {
            var id = "#b" + i;
			$(id).css("cursor","pointer");
            $(id).attr("data-id", i.toString());
			$(id).click(function(){
				puzzleClick($(this).attr("data-id"));
			});
		}
	}

	function puzzleClick(id) {
   		bQ.checkSolution(id);
    }

	bQ.register(puzzleLoad);

}};
"""),

checkSolutionStateful = { solution, state -> solution == state.flags["komu-zvoni"].toString()},

    explanation="""<p>Računalniki pogosto shranjujejo podatke v takšnih drevesih, saj so navadno
    uporabnejša od "običajnih" abecednih seznamov. Kot si opazil, je v njih
    kar preprosto poiskati ime človeka in z njim povezan podatek (v tem primeru
    številka zvonca). Vanje je tudi preprosto dodajati, iz njega je preprosto
    brisati...</p>

<div id="buttons" class="center-block" style="width:530px;height:341px">
    <img src="tasks/T09_XX_33_zvonci/seznam_stanovalcev.svg"/>
</div>

<p>Poskusi sestaviti podobno drevo, v katerega vpišeš imena svojih sošolcev!</p>

<p>Poleg tega pa nujno preveri, kaj se zgodi, če pozvoniš na napačni zvonec
(ne tu, kjer poskušaš naloge kar tako, temveč v pravi igri)!</p>
"""
)
