import react.*
import react.dom.svg
import kotlin.math.*

private fun loc(id: LocId)
    = repository.locations[id]!!

val R = 8.0

private val dir_phi = "V SV S SZ Z JZ J JV"
    .split(' ')
    .withIndex()
    .map { (i, dir) -> dir to i * 3.14159265 / 4 }
    .toMap()

private fun vect(c: Coordinates, oc: Coordinates, dir: String?, reverse: Boolean=false): List<Double> {
    val (x, y) = c
    val (ox, oy) = oc
    val dy = y - oy
    val dx = ox - x
    val phi = dir_phi[dir] ?: atan2(dy, dx)
    val x0 = x + R * cos(phi)
    val y0 = y - R * sin(phi)
    return if (dir in dir_phi) {
        val d = sqrt(dx * dx + dy * dy) / 2
        val x1 = x + d * cos(phi)
        val y1 = y - d * sin(phi)
        if (reverse)
            listOf(x1, y1, x0, y0)
        else
            listOf(x0, y0, x1, y1)
    }
    else
        listOf(x0, y0, x0, y0)
}


fun RBuilder.mapOverlay(visited: Set<LocId>,
                        pathsTaken: TakenPaths,
                        currentLocation: LocId) =
    svg(classes="map-overlay") {
        val currLoc = loc(currentLocation)
        val currReg = currLoc.region
        val plottable = visited
            .map(::loc)
            .filter { it.region == currReg && it.coordinates != null }

        val paths = mutableListOf<Pair<String, String>>()
        pathsTaken.entries.forEach { (pair, directions) ->
            directions.forEach { direction ->
                val (srcId, dstId) = pair
                val src = loc(srcId)
                val dst = loc(dstId)
                val nThere = directions.size
                val nBack = pathsTaken[dstId to srcId]?.size ?: 0
                if (src.region == currReg && dst.region == currReg
                        && (src.coordinates != null && dst.coordinates != null)
                        && (nBack != 1 || nThere != 1 || (nThere == 1 && nBack == 1 && srcId < dstId))) {
                    val dirback = pathsTaken[dstId to srcId]?.first()
                    val path = (vect(src.coordinates, dst.coordinates, direction, false) +
                            vect(dst.coordinates, src.coordinates, dirback, true))
                        .map { it.toString() }
                    paths.add(
                        "M${path[0]} ${path[1]} C${path.drop(2).joinToString(" ")}"
                        to if (dirback in dir_phi || direction in dir_phi) "edge" else "edge-unknown"
                    )
                }
            }
        }

        val hints =
        plottable.map { location ->
            location.commands
                .filter { it is Exit && it.description in dir_phi }
                .map { it.description to (location to (it.actions.first() as MoveTo).locId) }
                .filter { (dir, locs) -> pathsTaken[locs.first.id to locs.second]
                                         ?.let { dir !in it }
                                         ?: true}
                .map { (dir, locs) ->
                    val (loc0, _) = locs
                    val (x0, y0) = loc0.coordinates!!  // it's plottable
                    val phi = dir_phi[dir]!!  // checked above
                    listOf(x0 + R * cos(phi), y0 - R * sin(phi),
                           x0 + 2 * R * cos(phi), y0 - 2 * R * sin(phi))
            }
        }.flatten()

        for(edge in listOf("edge-shadow", null)) {
            paths.forEach { (path, eclass) ->
                path(points = path, classes = edge ?: eclass)
            }
            hints.forEach { (x0, y0, x1, y1) ->
                line(x0, y0, x1, y1, classes = edge ?: "edge-hint")
            }
        }

        plottable.forEach {
            val (x, y) = it.coordinates!!
            circle(x, y, R, classes = "node")
        }

        if (currLoc.coordinates != null) {
            val (x, y) = currLoc.coordinates
            circle(x, y, R - 3, classes ="current-node")

        }
    }
